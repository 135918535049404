import axios from 'axios';
import {
    SELECTED_PROJECT_SUCCESS,
    SELECTED_PROJECT_FAIL
} from '../utils/types';

export const addProject = (user, project_name) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    const body = JSON.stringify({ user, project_name });
    try {
        const res = await axios.post(`${process.env.REACT_APP_API_URL}/api/project/add/`, body, config);
        return res
    } catch (err) {
        return err
    }
}


export const check_staff = (user) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'JWT ' + localStorage.getItem('access')
        }
    }
    try {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/auth/user/check-staff/${user}/`, config);
        return res
    } catch (err) {
        return err
    }
}

export const sum_clients = () => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'JWT ' + localStorage.getItem('access')
        }
    }
    try {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/auth/user/sum/`, config);
        return res
    } catch (err) {
        return err
    }
}

export const sum_area = () => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'JWT ' + localStorage.getItem('access')
        }
    }
    try {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/task/surface/sum/`, config);
        return res
    } catch (err) {
        return err
    }
}

export const sum_tasks = () => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'JWT ' + localStorage.getItem('access')
        }
    }
    try {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/task/sum/`, config);
        return res
    } catch (err) {
        return err
    }
}

export const getSensorList = () => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'JWT ' + localStorage.getItem('access')
        }
    }
    try {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/sensor/list/`, config);
        return res
    } catch (err) {
        return err
    }
}



export const sum_sensors = () => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'JWT ' + localStorage.getItem('access')
        }
    }
    try {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/sensor/sum/`, config);
        return res
    } catch (err) {
        return err
    }
}




export const listProject = (user, page = 1) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'JWT ' + localStorage.getItem('access')
        }
    }
    try {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/project/list/${user}/?page=${page}`, config);
        return res
    } catch (err) {
        return err
    }
}

export const listSensorData = () => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'JWT ' + localStorage.getItem('access')
        }
    }
    try {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/sensor-data/list`, config);
        return res
    } catch (err) {
        return err
    }
}

export const listSensorDataByTask = (taskId) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'JWT ' + localStorage.getItem('access')
        }
    }
    try {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/sensor-data/list/${taskId}`, config);
        return res
    } catch (err) {
        return err
    }
}


export const getType = () => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    try {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/type/list/`, config);
        return res.data
    } catch (err) {
        return err
    }
}


export const getCulture = () => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    try {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/culture/list/`, config);
        return res.data
    } catch (err) {
        return err
    }
}
export const getTexture = () => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    try {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/texture/list/`, config);
        return res.data
    } catch (err) {
        return err
    }
}
export const getIrrigation = () => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    try {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/irrigation/list/`, config);
        return res.data
    } catch (err) {
        return err
    }
}
export const getVariety = (id) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    try {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/variety/list/${id}/`, config);
        return res.data
    } catch (err) {
        return err
    }
}

export const getVarietyAll = () => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    try {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/variety/list/`, config);
        return res.data
    } catch (err) {
        return err
    }
}

export const getDensity = (id) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    try {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/density/list/${id}/`, config);
        return res.data
    } catch (err) {
        return err
    }
}

export const getDensityAll = () => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    try {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/density/list/`, config);
        return res.data
    } catch (err) {
        return err
    }
}

