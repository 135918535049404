import { useSelector } from 'react-redux';
import { Outlet, Navigate } from 'react-router-dom';

const ProtectedRoutes = () => {
    const isAuthenticated = useSelector(state => state.auth.isAuthenticated);
    const token = localStorage.getItem('access');
    return (
        isAuthenticated && token ? <Outlet /> : <Navigate to="/" />
    )
};
export default ProtectedRoutes;