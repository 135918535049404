import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { ResetPasswordFail, check_exist_email, reset_password } from "../actions/auth";
import Container from 'react-bootstrap/Container';
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row'
import logo from '../assets/images/logo_login.png'
import Alert from 'react-bootstrap/Alert';
import { useTranslation } from 'react-i18next';
import { connect, useDispatch } from 'react-redux';
import LoadingButton from "@mui/lab/LoadingButton";
import { showSnackbar } from "../actions/snackbar";

const ResetPassword = ({ reset_password, isResetPassword }) => {
    const [requestSent, setRequestSent] = useState(false);
    const [formData, setFormData] = useState({
        email: '',
    });
    const dispatch = useDispatch();
    const [loadingbtn, setLoadingbtn] = useState(false);

    const { email } = formData;
    const { t } = useTranslation();
    const btnSX = {
        backgroundColor: '#6f9d2f',
        paddingTop: "2px",
        paddingBottom: "2px",
        paddingRight: "40px",
        paddingLeft: "40px",
        borderRadius: "5px",
        "&:hover": {
            backgroundColor: '#618a27'
        },
        '& span': {
            textTransform: "capitalize",
            fontSize: "16px"
        },
        '& span.MuiLoadingButton-loadingIndicator': {
            left: '30px',
        },
    };
    const onChange = e => setFormData({ ...formData, [e.target.name]: e.target.value });
    const onSubmit = e => {
        setLoadingbtn(true)
        e.preventDefault();
        dispatch(check_exist_email(email)).then((res) => {
            if (res.data.exists) {
                reset_password(email)
                setRequestSent(true);
            } else {
                dispatch(showSnackbar("error", undefined, t("email does not exist !"), "top", "right"))
            }
            setLoadingbtn(false)
        })

    }
    useEffect(() => {
        document.body.classList.add('background-body');
        return () => {
            document.body.classList.remove('background-body');
            dispatch(ResetPasswordFail());

        }
    }, [dispatch]);

    return (
        <Container style={{
            height: '100vh',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            alignContent: 'center',
        }}>

            {isResetPassword ? (
                <div className="container">
                    <Alert variant='success'>
                        {t("An email has been sent to your inbox with instructions to reset your password.Please check your email and follow the instructions to complete the process.If you don't receive an email from us within the next few minutes, please check your spam folder or contact our support team for assistance.")}
                    </Alert>

                    <div className="mt-2 signup-p d-flex justify-content-center">
                        <Link to="/" className="btn btn-login btn btn-primary m-2">{t("Return Home")}</Link>
                    </div>

                </div>
            ) : (
                <Row >
                    <div className="d-flex justify-content-center col-12">
                        <div className="col-md-4 text-center mb-3"><img src={logo} alt="logo" style={{ width: "85%" }} /></div>
                    </div>
                    <div className="col-12 d-flex justify-content-center">
                        <Card className="col-sm-8 col-md-6 card-login">

                            <div className="col-md-12 right-block-login">
                                <Form onSubmit={e => onSubmit(e)} className="container mb-3">
                                    <h5>{t("Forgot your password?")}</h5>
                                    <p>{t("Type in your email below and we'll send you instructions on how to reset it.")}</p>
                                    <div className="form-group mb-3">
                                        <input className="form-control"
                                            type="email"
                                            placeholder="E-mail"
                                            name="email"
                                            value={email}
                                            onChange={e => onChange(e)}
                                            required />
                                    </div>
                                    <div className="col-md-12 d-flex justify-content-center">
                                        {/* <Button className="btn btn-login" type="submit">{t("Reset Password")}</Button> */}
                                        <LoadingButton
                                            sx={btnSX}
                                            size="small"
                                            type="submit"
                                            loading={loadingbtn}
                                            loadingPosition="start"
                                            variant="contained"
                                        >
                                            <span>{t("Reset Password")}</span>
                                        </LoadingButton>
                                    </div>
                                </Form>
                            </div>

                        </Card>
                    </div>
                </Row >
            )}

        </Container >
    );
}

const mapStateToProps = state => ({
    isResetPassword: state.auth.isResetPassword
});
export default connect(mapStateToProps, { reset_password })(ResetPassword);