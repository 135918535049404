import React, { useEffect } from "react";
import $ from "jquery";
import { useSelector } from "react-redux";

const Loading = () => {

    const loadingState = useSelector(state => state.mapReducer.LoadingState)

    useEffect(() => {
        if (loadingState == true) {
            $(".loading").addClass("active")
        } else {
            $(".loading").removeClass("active")
        }
    }, [loadingState])
    return (
        <div className='loading'></div>
    )
}

export default Loading;