import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { connect } from 'react-redux';
import { verify } from "../actions/auth";
import Container from 'react-bootstrap/Container';
import Button from "react-bootstrap/Button";
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row'
import { useParams } from "react-router-dom";
import logo from '../assets/images/logo_login.png'

const Activate = ({ verify, isAuthenticated }) => {
    const [verified, setverified] = useState(false)
    const navigate = useNavigate();

    let { uid } = useParams();
    let { token } = useParams();
    const verify_account = e => {
        console.log('setverified', setverified)
        verify(uid, token);
        setverified(true)
        console.log('setverified', setverified)
    }
    useEffect(() => {
        document.body.classList.add('background-body');
        if (isAuthenticated) {
            return navigate("/dashboard")
        }
        if (verified) {
            return navigate("/")
        }
        return () => {
            document.body.classList.remove('background-body');
        }
    });
    return (
        <Container style={{
            height: '100vh',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            alignContent: 'center',
        }}>
            <Row >
                <Row className="justify-content-center">
                    <div className="col-md-4 text-center mb-3"><img src={logo} alt="logo" style={{ width: "85%" }} /></div>
                </Row>
                <Row>
                    <Card className="col-md-10 offset-1 p-3">
                        <Card.Title>Account Activation Page</Card.Title>
                        <Card.Text>
                            To activate your account, simply click on the "Activate Account" button below. This will verify your email address and confirm your registration with our service.
                        </Card.Text>
                        <Row>
                            <div className="col-md-12">
                                <Button className="btn btn-login" onClick={verify_account}>Activate Account</Button>
                            </div>
                        </Row>
                    </Card>
                </Row >
            </Row >
        </Container >
    );
}

const mapStateToProps = state => ({
    isAuthenticated: state.auth.isAuthenticated
});
export default connect(mapStateToProps, { verify })(Activate);