import axios from 'axios';
import {
    TASKS_GRAPH_DATA_COMPARE_SUCCESS,
    TASKS_GRAPH_DATA_COMPARE_FAIL
} from "../utils/types.js";

export const getFiltredTasks = (culture, variety, density, age) => async dispatch => {

    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    const body = JSON.stringify({ culture, variety, density, age });
    try {
        const res = await axios.post(`${process.env.REACT_APP_API_URL}/api/user/tasks/filter/`, body, config);
        return res.data.data
    } catch (err) {

        return err
    }
}

export const getFiltredTasksGraphData = (tasks, index) => async dispatch => {

    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    const body = JSON.stringify({ tasks, index });
    try {
        const res = await axios.post(`${process.env.REACT_APP_API_URL}/api/user/tasks/graphdata/compare/`, body, config);
        console.log('res', res)
        if (res.status === 200) {
            dispatch({
                type: TASKS_GRAPH_DATA_COMPARE_SUCCESS,
                payload: res.data.data
            })
        } else {
            dispatch({
                type: TASKS_GRAPH_DATA_COMPARE_FAIL
            })
        }
        return res
    } catch (err) {
        dispatch({
            type: TASKS_GRAPH_DATA_COMPARE_FAIL
        })
        console.error('err', err); // Log the error details for debugging
        return err.response
    }

}

export const clearTaskDataGraph = () => async dispatch => {
    dispatch({
        type: TASKS_GRAPH_DATA_COMPARE_FAIL
    })

}