import * as React from "react";
import { styled, createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import MuiDrawer from "@mui/material/Drawer";
import Box from "@mui/material/Box";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import { check_staff } from "../actions/project";
import { NavLink } from 'react-router-dom'

import Link from "@mui/material/Link";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";

import MainListItems from "../components/MainListItem";
import { blue, grey } from "@mui/material/colors";
import AccountCircle from "@mui/icons-material/AccountCircle";
import MenuItem from "@mui/material/MenuItem";
import { Container } from "@mui/material";
import Menu from "@mui/material/Menu";
import logo from "../assets/images/logo.png";
import Row from "react-bootstrap/esm/Row";
import { useDispatch, useSelector } from "react-redux";
import { Route, Routes } from "react-router-dom";
import Home from "../components/Home";
import { logout } from "../actions/auth";
import Loading from "../utils/loading";
import { useEffect, useState } from "react";
import { red } from "@mui/material/colors";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import Cards from "../components/project/Cards";
import Profile from "../components/Profile";
import UserHistory from "../components/UserHistory";
import Users from "../components/Users";
import "../assets/css/style1.css";
import "../assets/css/dashboard.css";
import classNames from "classnames";
import { HiOutlineLogout } from "react-icons/hi";
import { FaUser } from "react-icons/fa";
import TaskCalendar from "../components/task/calendar/Calendar";
import CalendarDashboard from "../components/task/calendar/CalendarDashboard.js";
import Compare from "../components/indexCompare/Compare.js";
import "../assets/scss/dashboard.scss";
import TaskPreview from "../components/task/preview.js";
function Copyright(props) {
    return (
        <Typography
            variant="body2"
            color="text.secondary"
            align="center"
            {...props}
        >
            {"Copyright © "}
            <Link color="inherit" href="https://robocare.tn" target="_blank">
                Robocare
            </Link>{" "}
            {new Date().getFullYear()}
            {"."}
        </Typography>
    );
}

const languages = [
    {
        code: "fr",
        name: "Français",
        country_code: "fr",
    },
    {
        code: "en",
        name: "English",
        country_code: "en",
    },
    // {
    //     code: 'ar',
    //     name: 'العربية',
    //     dir: 'rtl',
    //     country_code: 'tn',
    // },
];
/*
const FlagIcon = ({ countryCode }) => (
    <span
        className={`flag-icon flag-icon-${countryCode} mx-2`}
        style={{ opacity: 1 }}
    ></span>
);
const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(["width", "margin"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const Drawer = styled(MuiDrawer, {
    shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
    "& .MuiDrawer-paper": {
        position: "relative",
        whiteSpace: "nowrap",
        width: drawerWidth,
        transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
        boxSizing: "border-box",
        ...(!open && {
            overflowX: "hidden",
            transition: theme.transitions.create("width", {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
            width: theme.spacing(7),
            [theme.breakpoints.up("sm")]: {
                width: theme.spacing(9),
            },
        }),
    },
}));
*/
export default function Dashboard() {
    const navigate = useNavigate();
    const [open, setOpen] = useState(true);
    const [auth, setAuth] = useState(true);
    const [anchorEl, setAnchorEl] = useState(null);
    const dispatch = useDispatch();
    const [isStaff, setisStaff] = useState(0)


    const user = useSelector((state) => state.auth.user);
    const [Username, setUsername] = useState(null);

    useEffect(() => {
        if (!user) return;
        const username = user.firstname + " " + user.lastname;
        setUsername(username);

        dispatch(check_staff(user.id)).then((response) => {
            if (response.status == 200) {
                setisStaff(response.data["staff"]);
            }
        });

    }, [user]);
    const currentLanguageCode = cookies.get("i18next") || "en";
    const currentLanguage = languages.find((l) => l.code === currentLanguageCode);
    const { t } = useTranslation();

    useEffect(() => {
        document.body.dir = currentLanguage.dir || "ltr";
    }, [currentLanguage, t]);

    const handleChangeLanguage = (code) => {
        i18next.changeLanguage(code);
    };
    const mediaQuery = window.matchMedia("(max-width: 600px)");

    useEffect(() => {
        setOpen(!mediaQuery.matches);
    }, []);

    const handleChange = (event) => {
        setAuth(event.target.checked);
    };

    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    const toggleDrawer = () => {
        setOpen(!open);
    };
    const handleLogout = () => {
        dispatch(logout());
        handleClose();
    };
    const theme = createTheme({
        palette: {
            primary: {
                main: blue[700],
            },
            secondary: {
                light: "#ff7961",
                main: "#f44336",
                dark: "#ba000d",
                contrastText: "#000",
            },
            grey: {
                main: grey[700],
                contrastText: "#fff",
                dark: grey[800],
            },
            AppBarBackground: {
                main: "#ffffff",
            },
            IconColor: {
                main: "#6f9d2f",
                contrastText: "#fff",
            },
            ViewTask: {
                main: "#03a9f4",
                contrastText: "#fff",
            },
            DeleteTask: {
                main: red[500],
                contrastText: "#fff",
            },
            DownloadReport: {
                main: "#ff9800",
                contrastText: "#fff",
            },
        },
    });

    const handleNavigateToProfile = () => {
        setAnchorEl(null);
        navigate("/dashboard/profile");
    };

    return (
        <ThemeProvider theme={theme}>
            <Loading />



            {/* <!-- Dashboard Section Begin --> */}

            <header className="dashboard-navbar navbar sticky-top bg-white flex-md-nowrap p-0 shadow-sm"
                data-bs-theme="light">
                <a className="navbar-brand col-md-3 col-lg-2 me-0 px-3 fs-6 text-dark"
                    href="/">
                    <img src={logo} className="dashboard-logo" alt="ROBOCARE logo" title="ROBOCARE title" />
                </a>

                <ul className="m-languages-nav navbar-nav flex-row px-3 d-none d-md-flex">
                    <li className="dropdown m-dropdown">
                        <button className="btn btn-white dropdown-toggle m-languages-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">

                            <img src={require('../assets/images/flags/' + currentLanguage.code + '.webp')} title="" className="m-flag" />
                            {currentLanguage.name}
                        </button>
                        <ul className="dropdown-menu position-absolute m-absolute-dropdown">

                            {languages
                                .filter(({ code }) => code !== currentLanguageCode) // Filters out the current language
                                .map(({ code, name, country_code }) => (
                                    <li key={country_code}>
                                        <a
                                            className={classNames("dropdown-item m-dropdown-item")}
                                            onClick={() => handleChangeLanguage(code)}
                                        >
                                            <img src={require('../assets/images/flags/' + country_code + '.webp')} title="" className="m-flag" />
                                            {name}
                                        </a>
                                    </li>
                                ))}
                        </ul>
                    </li>
                    {auth && (
                        <li className="dropdown m-dropdown">
                            <button className="btn btn-white dropdown-toggle m-languages-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                {Username}
                            </button>
                            <ul className="dropdown-menu position-absolute m-absolute-dropdown">
                                <li className="dropdown-item" onClick={handleNavigateToProfile}> {t("Profile")}</li>
                                <li className="dropdown-item" onClick={handleLogout}>{t("Logout")}</li>
                            </ul>
                        </li>
                    )}

                </ul>

                <ul className="navbar-nav flex-row d-md-none">

                    <li className="nav-item text-nowrap">
                        <button className="nav-link px-3 text-dark"
                            type="button"
                            data-bs-toggle="offcanvas"
                            data-bs-target="#sidebarMenu"
                            aria-controls="sidebarMenu"
                            aria-expanded="false"
                            aria-label="Toggle navigation">
                            <i className="bi bi-list"></i>
                        </button>
                    </li>
                </ul>

            </header>

            <div className="m-dashboard container-fluid">
                <div className="row">
                    {/* <!-- Sidebar Section Begin --> */}
                    <div className="sidebar border border-right col-md-3 col-lg-2 p-0 bg-green m-sidebar">
                        <div className="offcanvas-md offcanvas-end"
                            tabIndex="-1"
                            id="sidebarMenu"
                            aria-labelledby="sidebarMenuLabel">
                            <div className="offcanvas-header">

                                {auth && (
                                    <h6 className="offcanvas-title text-dark"
                                        id="sidebarMenuLabel">
                                        {Username}
                                    </h6>
                                )}

                                <button type="button"
                                    className="btn-close"
                                    data-bs-dismiss="offcanvas"
                                    data-bs-target="#sidebarMenu"
                                    aria-label="Close"></button>
                            </div>
                            <div className="offcanvas-body d-md-flex flex-column p-0 pt-lg-3 overflow-y-auto bg-green">
                                <ul className="nav flex-column">
                                    <li className="nav-item">
                                        <NavLink
                                            className="nav-link d-flex align-items-center gap-2"
                                            to="/dashboard"
                                            activeclassname="active"
                                        >
                                            <i className="bi bi-speedometer"></i>
                                            {t("Dashboard")}
                                        </NavLink>
                                    </li>

                                    <li className="nav-item">
                                        <NavLink
                                            className="nav-link d-flex align-items-center gap-2"
                                            to="/dashboard/projects/list"
                                            activeclassname="active"
                                        >
                                            <i className="bi bi-cart"></i>
                                            {t("Projects")}
                                        </NavLink>
                                    </li>

                                    {isStaff ?
                                        <li className="nav-item">

                                            <NavLink
                                                className="nav-link d-flex align-items-center gap-2"
                                                to="/dashboard/compare/index"
                                                activeclassname="active"
                                            >
                                                <i className="bi bi-exclude"></i>
                                                {t("Compare Tasks")}
                                            </NavLink>
                                        </li>
                                        : ""}

                                    {isStaff ?
                                        <li className="nav-item">
                                            <NavLink
                                                className="nav-link d-flex align-items-center gap-2"
                                                to="/dashboard/users"
                                                activeclassname="active"
                                            >
                                                <i className="bi bi-people-fill"></i>
                                                {t("Users")}
                                            </NavLink>

                                        </li>
                                        : ""}

                                </ul>

                                <hr className="my-3" />

                                {/* <!-- Settings and Logout Section Begin --> */}
                                <ul className="nav flex-column mb-auto">
                                    <li className="nav-item">
                                        <NavLink
                                            className="nav-link d-flex align-items-center gap-2"
                                            to="/dashboard/profile"
                                            activeclassname="active"
                                        >
                                            <i className="bi bi-person-fill"></i>

                                            {t("Profile")}</NavLink>

                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link d-flex align-items-center gap-2"
                                            onClick={handleLogout}>
                                            <i className="bi bi-door-closed"></i>
                                            {t("Logout")}
                                        </a>
                                    </li>
                                </ul>

                                {/* <!-- Settings and Logout Section End --> */}

                            </div>
                        </div>
                    </div>
                    {/* <!-- Sidebar Section End --> */}

                    {/* <!-- Main Section Begin --> */}
                    <main className="col-md-9 ms-sm-auto col-lg-10 p-md-4">
                        <Routes>
                            <Route path="/" element={<Home />} />
                            <Route path="/task/events/:id_task" element={<TaskCalendar />} />
                            <Route path="/projects/list" element={<Cards />} />
                            <Route path="/profile" element={<Profile />} />
                            <Route path="/compare/index" element={<Compare />} />
                            <Route path="/user/:id_user" element={<UserHistory />} />
                            <Route path="/task/preview/:id_task" element={<TaskPreview />} />
                            <Route path="/users" element={<Users />} />
                        </Routes>
                    </main>
                    {/* <!-- Main Section Begin --> */}

                </div>
            </div>
            {/* <!-- Dashboard Section End --> */}

            <Copyright sx={{ pt: 4 }} className="footer" />


        </ThemeProvider >
    );
}