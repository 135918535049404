import { configureStore } from '@reduxjs/toolkit';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import storage from 'redux-persist/lib/storage';
import { persistReducer } from 'redux-persist'
import auth from "./reducers/auth.js"
import mapReducer from "./reducers/mapReducer.js";
import snackbarReducer from "./reducers/snackbarReducer.js";
import requestHighResolution from "./reducers/requestHighResolution.js";
import highResolutionServiceState from './reducers/highResolutionServiceState.js';
import noteReducer from "./reducers/noteReducer.js";
import graphReducer from "./reducers/graphReducer.js";
import selectedProjectReducer from "./reducers/selectedProjectReducer.js";
import notificationReducer from './reducers/notificationReducer.js';

const initialState = {};
const authPersistConfig = {
  key: "auth",
  version: 1,
  storage
}
const selectedProjectPersistConfig = {
  key: "selectedProjectReducer",
  version: 1,
  storage
}
const middleware = [thunk];
const persistAuthReducer = persistReducer(authPersistConfig, auth)
const persistSelectedProjectReducer = persistReducer(selectedProjectPersistConfig, selectedProjectReducer)
const store = configureStore({
  reducer: {
    auth: persistAuthReducer,
    selectedProjectReducer: persistSelectedProjectReducer,
    mapReducer: mapReducer,
    snackbarReducer: snackbarReducer,
    requestReducer: requestHighResolution,
    highResServiceReducer: highResolutionServiceState,
    noteReducer: noteReducer,
    graphReducer: graphReducer,
    notificationReducer: notificationReducer,
  },
  preloadedState: initialState,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(middleware),
  devTools: process.env.NODE_ENV !== 'production'
    ? composeWithDevTools()
    : undefined,
});

export default store;