import axios from 'axios';
import {
    SNACKBAR_SHOW,
    SNACKBAR_HIDE
} from '../utils/types';

export const showSnackbar = (alert = "error", open = true, message, vertical = "top", horizental = "right", autohide = 3000) => async dispatch => {
    const payloadData = {
        "alert": alert,
        "open": open,
        "message": message,
        "vertical": vertical,
        "horizental": horizental,
        "autohide": autohide,
    }
    dispatch({
        type: SNACKBAR_SHOW,
        payload: payloadData
    })
}
export const hideSnackbar = () => async dispatch => {
    dispatch({
        type: SNACKBAR_HIDE,
    })
}