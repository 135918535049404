import React, { useEffect, useState } from "react";
import { CardContent, Container } from "@mui/material";
import ImgMediaCard from "./ImgMediaCard";
import Row from "react-bootstrap/esm/Row";
import "../../assets/scss/cards.scss";
import CustomButton from "../CustomButton";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import LoadingData from "../../utils/loadingdata";
import {
    check_staff,
    listProject,
    sum_area,
    sum_clients,
    sum_sensors,
    sum_tasks,
    listSensorData,
    getSensorList,
    listSensorDataByTask,
} from "../../actions/project";
import { IoIosAdd } from "react-icons/io";
import AddProject from "./add";
import AddTaskPopup from "../task/addPopup";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { showSnackbar } from "../../actions/snackbar";
import "../../assets/css/card.css";
import { FaUsers } from "react-icons/fa";
import { PiPolygonDuotone } from "react-icons/pi";
import { MdSensors } from "react-icons/md";
import { VscGraph } from "react-icons/vsc";
import CountUp from "react-countup";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import Placeholder from "react-bootstrap/Placeholder";
import { Card } from "react-bootstrap";
import { CardMedia } from "@mui/material";
import CardActions from "@mui/material/CardActions";
import { SELECTED_PROJECT_SUCCESS } from "../../utils/types";

const Cards = ({ }) => {
    const [projects, setProjects] = useState([]);
    const [sensorData, setSensorData] = useState([]);

    const [index, setIndex] = useState(-1);
    const [indexSensorSelect, setindexSensorSelect] = useState(0);
    const [loadedProjects, setLoadedProjects] = useState(0);
    const [totalProjects, setTotalProjects] = useState(0);
    const [projectsPage, setProjectPage] = useState(1);


    const [tasks, setTasks] = useState([]);
    const [allTasks, setAllTasks] = useState([]);
    const [pending, setPending] = useState(true);
    const [pendingSensorData, setpendingSensorData] = useState(true);
    const [loading, setLoading] = useState(true);

    const dispatch = useDispatch();
    const { t } = useTranslation();
    const userId = useSelector((state) => state.auth.user.id);

    const [isStaff, setisStaff] = useState(0);

    const [sumClients, setsumClients] = useState(null);
    const [sumClientsPercent, setsumClientsPercent] = useState(null);

    const [sumArea, setsumArea] = useState(null);
    const [sumAreaPercent, setsumAreaPercent] = useState(null);

    const [sumSernsor, setsumSernsor] = useState(null);
    const [sumSernsorPercent, setsumSernsorPercent] = useState(null);

    const [sumTask, setsumTask] = useState(null);
    const [sumTaskPercent, setsumTaskPercent] = useState(null);

    const [addModalOpen, setAddModalOpen] = useState(false);
    const [deleteModalOpen, setdeleteModalOpen] = useState(false);
    const [modalOpenAddTask, setmodalOpenAddTask] = useState(false);
    const [listSensor, setlistSensor] = useState(null);
    const selectedProject = useSelector((state) => state.selectedProjectReducer.projectIndex)
    const columns = [
        {
            name: t("Time"),
            selector: (row) => row.time,
            sortable: true,
            minWidth: "13%",
            cellExport: (row) => row.time,
        },
        {
            name: t("Charge"),
            selector: (row) => row.charge,
            cellExport: (row) => row.charge,
        },
        {
            name: t("Temperature"),
            selector: (row) => row.temperature,
            cellExport: (row) => row.temperature,
        },
        {
            name: t("Humidity"),
            selector: (row) => row.humidity,
            cellExport: (row) => row.humidity,
        },
        {
            name: t("Pressure"),
            selector: (row) => row.pressure,
            cellExport: (row) => row.pressure,
        },
        { name: "adc", selector: (row) => row.adc, cellExport: (row) => row.adc },
        { name: "ts", selector: (row) => row.ts, cellExport: (row) => row.ts },
        { name: "mv1", selector: (row) => row.mv1, cellExport: (row) => row.mv1 },
        { name: "mv2", selector: (row) => row.mv2, cellExport: (row) => row.mv2 },
        { name: "mv3", selector: (row) => row.mv3, cellExport: (row) => row.mv3 },
        { name: "et0", selector: (row) => row.et0, cellExport: (row) => row.et0 },
        {
            name: t("Rain"),
            selector: (row) => row.rain,
            cellExport: (row) => row.rain,
        },
        {
            name: t("Radiation"),
            selector: (row) => row.radiation,
            cellExport: (row) => row.radiation,
        },
    ];
    const customStyleDatatable = {
        rows: {
            style: {},
        },
        headCells: {
            style: {
                fontSize: "14px",
                fontWeight: "600",
            },
        },
        cells: {
            style: {
                fontSize: "13px",
            },
        },
    };

    const loadMoreProjects = () => {


        dispatch(listProject(userId, projectsPage + 1)).then((response) => {
            if (response.status === 200) {
                setProjectPage(projectsPage + 1);

                setTotalProjects(response.data.total_elements);
                setLoadedProjects(loadedProjects + response.data.data.length);
                const modifiedData = response.data.data.map((item, index) => ({
                    ...item,
                    defaultExpanded: item.tasks.length > 0,
                }));
                const timeout = setTimeout(() => {
                    setProjects(modifiedData);

                    let objects = [];

                    modifiedData.forEach((element) => {
                        if (element.tasks.length > 0) {
                            objects = [...objects, ...element.tasks];
                        }
                    });
                    setAllTasks(objects);
                    setTasks(objects);
                    setPending(false);
                    setLoading(false);
                }, 3000);

                return () => clearTimeout(timeout);
            } else {
                dispatch(
                    showSnackbar(
                        "error",
                        undefined,
                        t(
                            "An error was occured when trying to fetch data, please try again !"
                        ),
                        "top",
                        "right"
                    )
                );
            }
        });

    }

    const handleOpenAddModal = () => {
        setAddModalOpen(true);
    };
    const handleCloseAddModal = () => {
        setAddModalOpen(false);
    };

    const handleOpenAddTaskModal = () => {
        setmodalOpenAddTask(true);
    };

    const handleCloseAddTaskModal = () => {
        setmodalOpenAddTask(false);
    };
    // show spinner while loading data
    useEffect(() => {
        dispatch(check_staff(userId)).then((response) => {
            if (response.status == 200) {
                setisStaff(response.data["staff"]);
            }
        });
        dispatch(listProject(userId)).then((response) => {
            if (response.status === 200) {

                setTotalProjects(response.data.total_elements);
                setLoadedProjects(loadedProjects + response.data.data.length);

                const modifiedData = response.data.data.map((item, index) => ({
                    ...item,
                    defaultExpanded: item.tasks.length > 0,
                }));
                const timeout = setTimeout(() => {
                    setProjects(modifiedData);

                    let objects = [];

                    modifiedData.forEach((element) => {
                        if (element.tasks.length > 0) {
                            objects = [...objects, ...element.tasks];
                        }
                    });
                    setAllTasks(objects);
                    setTasks(objects);

                    setPending(false);
                    setLoading(false);
                }, 3000);

                return () => clearTimeout(timeout);
            } else {
                dispatch(
                    showSnackbar(
                        "error",
                        undefined,
                        t(
                            "An error was occured when trying to fetch data, please try again !"
                        ),
                        "top",
                        "right"
                    )
                );
            }
        });
    }, []);

    useEffect(() => {
        if (!isStaff) return;
        dispatch(sum_clients()).then((response) => {
            setsumClients(response.data.data["users"]);
            setsumClientsPercent(response.data.data["last_month"]);
        });
    }, [isStaff]);
    useEffect(() => {
        if (!isStaff) return;
        dispatch(sum_area()).then((response) => {
            setsumArea(response.data.data["total_surface"]);
            setsumAreaPercent(response.data.data["last_month_surface"]);
        });
    }, [isStaff]);
    useEffect(() => {
        if (!isStaff) return;
        dispatch(sum_sensors()).then((response) => {
            setsumSernsor(response.data.data["total_sensor"]);
            setsumSernsorPercent(response.data.data["last_month_sensor"]);
        });
    }, [isStaff]);

    useEffect(() => {
        if (!isStaff) return;
        dispatch(sum_tasks()).then((response) => {
            setsumTask(response.data.data["total_task"]);
            setsumTaskPercent(response.data.data["last_month"]);
        });
    }, [isStaff]);

    useEffect(() => {
        if (!isStaff) return;
        dispatch(getSensorList()).then((response) => {
            setlistSensor(response.data.data);
            setindexSensorSelect(response.data.data[0].task);
        });
    }, [isStaff]);

    const handleChangeSensor = (e) => {
        setpendingSensorData(true);
        setindexSensorSelect(e.target.value);
    };
    useEffect(() => {
        if (indexSensorSelect !== 0) {
            dispatch(listSensorDataByTask(indexSensorSelect)).then((res) => {
                setSensorData(res.data.data);
                setpendingSensorData(false);
            });
        }
    }, [indexSensorSelect]);

    const tableData = {
        columns,
        data: sensorData,
        print: false,
        exportHeaders: true,
    };

    const handleChange = (e) => {
        if (e.target.value >= 0) {
            const project = projects[e.target.value];
            setTasks(project.tasks);
        } else {
            setTasks(allTasks);
        }
        // setIndex(e.target.value);
        dispatch({
            type: SELECTED_PROJECT_SUCCESS,
            payload: e.target.value
        })
    };
    useEffect(() => {
        if (projects.length === 0) return
        if (selectedProject >= 0) {
            const project = projects[selectedProject];
            setTasks(project.tasks);
        } else {
            setTasks(allTasks);
        }
    }, [projects, selectedProject])
    return (
        <>
            {isStaff ? (
                <Container className="mod-cards mt-3">
                    <Row className="mt-5">
                        <div className="col-xl-3 col-lg-6">
                            <div className="card card-stats mb-4 mb-xl-0">
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col">
                                            <h5 className="card-title text-uppercase text-muted mb-0">
                                                {t("Customers")}
                                            </h5>
                                            <span className="h5 font-weight-bold mb-0">
                                                <CountUp end={sumClients} />
                                            </span>
                                        </div>
                                        <div className="col-auto">
                                            <div className="icon icon-shape bg-danger text-white rounded-circle shadow">
                                                <FaUsers className="stat-icon" />
                                            </div>
                                        </div>
                                    </div>
                                    <p className="mt-3 mb-0 text-muted text-sm">
                                        <span className="text-success mr-2">
                                            <i className="fa fa-arrow-up"></i>
                                            <CountUp decimals={3} end={sumClientsPercent} />%
                                        </span>
                                        <span className="text-nowrap">{t("Since last month")}</span>
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-3 col-lg-6">
                            <div className="card card-stats mb-4 mb-xl-0">
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col">
                                            <h5 className="card-title text-uppercase text-muted mb-0">
                                                {t("Inspected area")}
                                            </h5>
                                            <span className="h5 font-weight-bold mb-0">
                                                <CountUp end={sumArea} decimals={3} /> Ha
                                            </span>
                                        </div>
                                        <div className="col-auto">
                                            <div className="icon icon-shape bg-warning text-white rounded-circle shadow">
                                                <PiPolygonDuotone className="stat-icon" />
                                            </div>
                                        </div>
                                    </div>
                                    <p className="mt-3 mb-0 text-muted text-sm">
                                        <span className="text-danger mr-2">
                                            <i className="fas fa-arrow-down"></i>
                                            <CountUp decimals={3} end={sumAreaPercent} />%
                                        </span>
                                        <span className="text-nowrap">{t("Since last month")}</span>
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-3 col-lg-6">
                            <div className="card card-stats mb-4 mb-xl-0">
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col">
                                            <h5 className="card-title text-uppercase text-muted mb-0">
                                                {t("Tasks")}
                                            </h5>
                                            <span className="h5 font-weight-bold mb-0">
                                                <CountUp end={sumTask} />
                                            </span>
                                        </div>
                                        <div className="col-auto">
                                            <div className="icon icon-shape bg-yellow text-white rounded-circle shadow">
                                                <VscGraph className="stat-icon" />
                                            </div>
                                        </div>
                                    </div>
                                    <p className="mt-3 mb-0 text-muted text-sm">
                                        <span className="text-warning mr-2">
                                            <i className="fas fa-arrow-down"></i>{" "}
                                            <CountUp decimals={3} end={sumTaskPercent} />%
                                        </span>
                                        <span className="text-nowrap">{t("Since last month")}</span>
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-3 col-lg-6">
                            <div className="card card-stats mb-4 mb-xl-0">
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col">
                                            <h6 className="card-title text-uppercase text-muted mb-0">
                                                {t("Sensors")}
                                            </h6>
                                            <span className="h5 font-weight-bold mb-0">
                                                <CountUp end={sumSernsor} />
                                            </span>
                                        </div>
                                        <div className="col-auto">
                                            <div className="icon icon-shape bg-info text-white rounded-circle shadow">
                                                <MdSensors className="stat-icon" />
                                            </div>
                                        </div>
                                    </div>
                                    <p className="mt-3 mb-0 text-muted text-sm">
                                        <span className="text-success mr-2">
                                            <i className="fas fa-arrow-up"></i>{" "}
                                            <CountUp decimals={3} end={sumSernsorPercent} />%
                                        </span>
                                        <span className="text-nowrap">{t("Since last month")}</span>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </Row>

                    <Row className="mt-5">
                        <div className="col-md-12">
                            <div className="intro-text">
                                <h2>{t("Sensor Data")}</h2>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <FormControl
                                required
                                sx={{ m: 1, minWidth: "20%" }}
                                size="small"
                                color="IconColor"
                                className="m-select col-sm-12 col-lg-3"
                            >
                                <InputLabel id="demo-select-sensor-label">
                                    {t("Sensors")}
                                </InputLabel>
                                <Select
                                    labelId="demo-select-sensor-label"
                                    id="demo-select-sensor"
                                    label="Sensors"
                                    value={indexSensorSelect}
                                    fullWidth
                                    onChange={handleChangeSensor}
                                    name="sensor"
                                    required
                                >
                                    {listSensor &&
                                        listSensor.length > 0 &&
                                        listSensor.map((item) => (
                                            <MenuItem key={item["task"]} value={item["task"]}>
                                                {item["sensor_name"]}
                                            </MenuItem>
                                        ))}
                                </Select>
                            </FormControl>
                        </div>
                        <div className="col-md-12">
                            <>
                                <DataTableExtensions {...tableData}>
                                    <DataTable
                                        customStyles={customStyleDatatable}
                                        pagination
                                        progressPending={pendingSensorData}
                                        progressComponent={<LoadingData />}
                                        highlightOnHover
                                    />
                                </DataTableExtensions>
                            </>
                        </div>
                    </Row>
                </Container>
            ) : (
                ""
            )}
            <Container className="mod-cards mt-3">
                <Row className="mt-5">
                    <div className="col-md-12">
                        <div className="intro-text">
                            <h2>{t("Projects and Tasks Management")}</h2>
                        </div>
                    </div>
                </Row>

                <Row className=" col-md-12 ">
                    <div className=" m-row row gap-2">
                        <FormControl
                            required
                            sx={{ m: 1, minWidth: "25%" }}
                            size="small"
                            color="IconColor"
                            className="m-select col-sm-12 col-lg-3"
                        >
                            <InputLabel id="demo-select-project-label">
                                {t("Project")}
                            </InputLabel>
                            <Select
                                labelId="demo-select-project-label"
                                id="demo-select-project"
                                label="Project"
                                value={selectedProject}
                                fullWidth
                                onChange={handleChange}
                                name="project"
                                required
                            >
                                <MenuItem value="-1">
                                    <em>{t("All Projects")}</em>
                                </MenuItem>
                                {projects &&
                                    projects.length > 0 &&
                                    projects.map((item, index) => (
                                        <MenuItem key={item.id} value={index}>
                                            {item.project_name}
                                        </MenuItem>
                                    ))}
                            </Select>
                        </FormControl>

                        <CustomButton
                            className="col-sm-12 col-lg-auto"
                            color="IconColor"
                            size="medium"
                            startIcon={<IoIosAdd />}
                            onClick={handleOpenAddModal}
                        >
                            {t("Create project")}
                        </CustomButton>

                        <CustomButton
                            className="col-sm-12 col-lg-auto pl-2"
                            color="IconColor"
                            size="medium"
                            startIcon={<IoIosAdd />}
                            onClick={handleOpenAddTaskModal}
                        >
                            {t("Create Task")}
                        </CustomButton>
                    </div>
                </Row>



                {loading ? (
                    <Row className="mt-4 mb-4">
                        {Array.from({ length: 6 }, (_, index) => (
                            <div key={index} className="col-sm-12 col-md-6 col-lg-4 my-2 py-0">
                                <Card>
                                    <svg
                                        className="bd-placeholder-img card-img-top"
                                        width="100%"
                                        xmlns="http://www.w3.org/2000/svg"
                                        role="img"
                                        aria-label="Placeholder"
                                        preserveAspectRatio="xMidYMid slice"
                                        focusable="false"
                                    >
                                        <title>Placeholder</title>
                                        <rect width="100%" height="100%" fill="#d4e2c1" />
                                    </svg>

                                    <Card.Body>
                                        <Placeholder as={Card.Title} animation="glow">
                                            <Placeholder xs={6} />
                                        </Placeholder>
                                        <Placeholder as={Card.Text} animation="glow">
                                            <Placeholder xs={3} /> <Placeholder xs={4} /> <br />
                                            <Placeholder xs={4} /> <Placeholder xs={6} />{" "}
                                            <Placeholder xs={3} /> <Placeholder xs={5} />{" "}
                                        </Placeholder>
                                    </Card.Body>
                                    <CardActions></CardActions>
                                </Card>
                            </div>
                        ))}
                    </Row>
                ) : (
                    <Row className="mt-4 mb-4">
                        {tasks &&
                            tasks.map((task) => (
                                <div
                                    className="col-sm-12 col-md-6 col-lg-4"
                                    key={"task-container-" + task.id}
                                >
                                    <ImgMediaCard element={task} key={"task-" + task.id} />
                                </div>
                            ))}
                    </Row>


                )}
                <div className="row d-flex justify-content-center" >
                    {(loadedProjects < totalProjects) ? (

                        <button className="btn btn-outline-success w-auto" onClick={loadMoreProjects}>
                            Load more data
                        </button>

                    ) : ""}

                </div>

                <AddProject open={addModalOpen} handleClose={handleCloseAddModal} />
                <AddTaskPopup
                    open={modalOpenAddTask}
                    projects={projects}
                    handleClose={handleCloseAddTaskModal}
                />
            </Container>
        </>
    );
};

export default Cards;
