import { Container } from "@mui/material";
import React, { useState, useEffect } from "react";
import { Button, Col, Row } from "react-bootstrap";
import "../../../../node_modules/font-gis/css/font-gis.css";
// import "../../../assets/scss/sidebars.scss";
import { IoLocationOutline } from "react-icons/io5";
import { useTranslation } from "react-i18next";
import SurveyEdit from "./SurveyEdit.js";
import TaskImage from "./TaskImage.js";
import SurveyHeader from "./SurveyHeader.js";
import { isMobile } from "react-device-detect";

import SidebarBottom from "./SidebarBottom.js";


import {
    removeCheckboxLayerState,
    setCheckboxLayerState,
    setClickEventFail,
    setClickEventSucess,
} from "../../../actions/map.js";
import { useDispatch, useSelector } from "react-redux";
import $ from "jquery";
import IndexCheckbox from "./IndexCheckbox.js";
import indexes from "../../../utils/indexes.json";
import GraphLayout from "../sidebarGraph/graphLayout.js";
import RequestHighResolutionPopup from "../requests/requestHighResolutionPopup.js";
import HighResolutionPopup from "../requests/HighResolutionPopup.js";
import BlockHeader from "./BlockHeader.js";
import TreeCountCheckbox from "./TreeCountCheckbox.js";
import { GiPencilRuler } from "react-icons/gi";
import { RiRemoteControlLine } from "react-icons/ri";
import { set_smart_control_command, get_smart_control_command_status, getOrders, setHasHighResOrdersState } from "../../../actions/task.js";
import { showSnackbar } from "../../../actions/snackbar.js";
import { BsCloudSun } from "react-icons/bs";
import WeatherBlock from "./WeatherBlock.js";
import { FaRegCalendarAlt } from "react-icons/fa";
import CalendarBlock from "./CalendarBlock.js";

import {
    REQUEST_EMPTY,
    REQUEST_EXIST,
    HIGH_RES_SERVICE_AVAILABLE,
    HIGH_RES_SERVICE_NOT_AVAILABLE
} from '../../../utils/types.js';


const Sidebar = ({ openModal }) => {
    const { t } = useTranslation();
    const [sidebarRef, setsidebarRef] = useState(null);
    const dispatch = useDispatch();
    const [sidebarToogle, setsidebarToogle] = useState(true);
    const taskName = useSelector((state) => state.mapReducer.taskNameDetails);
    const task_id = useSelector((state) => state.mapReducer.taskIdDetails);
    const AreaSurface = useSelector((state) => state.mapReducer.surfaceDetails);

    const country = useSelector((state) => state.mapReducer.countryFrDetails);
    const state = useSelector((state) => state.mapReducer.stateFrDetails);
    const stateDistrict = useSelector(
        (state) => state.mapReducer.stateDistrictFrDetails
    );
    const county = useSelector((state) => state.mapReducer.countyFrDetails);
    const postcode = useSelector((state) => state.mapReducer.postcodeFrDetails);
    const has_requestEdit = useSelector(state => state.requestReducer.has_request)
    const high_res_service_available = useSelector(state => state.highResServiceReducer.high_res_available)
    const [modalOpenRequestHighResolution, setmodalOpenRequestHighResolution] = useState(false)
    const [modalOpenHighResolution, setmodalOpenHighResolution] = useState(false)
    const [selectedCheckboxId, setSelectedCheckboxId] = useState("ndvi");
    const treeCount = useSelector((state) => state.mapReducer.treeCountValue)
    const [isCheckedIOT, setisCheckedIOT] = useState(false)
    const [isCheckedIOTMode, setisCheckedIOTMode] = useState(true)
    const [orders, setOrders] = useState([]);
    const taskId = useSelector(state => state.mapReducer.taskIdDetails)
    const subscriptionData = useSelector(state => state.requestReducer.requests)
    const [Subscription, setSubscription] = useState(0)



    const handleCheckboxChange = (id) => {
        setSelectedCheckboxId((prevId) => (prevId === id ? null : id));
    };

    useEffect(() => {
        if (selectedCheckboxId !== null) {
            dispatch(setCheckboxLayerState(selectedCheckboxId));
        } else {
            dispatch(removeCheckboxLayerState());
        }
    }, [selectedCheckboxId]);

    useEffect(() => {
        if (!sidebarRef) return;

        if (isMobile) {
            sidebarRef.addEventListener("touchstart", disableMapInteractions); // On touchstart
            sidebarRef.addEventListener("touchmove", disableMapInteractions); // On touchmove
        }
        sidebarRef.addEventListener("mouseenter", disableMapInteractions);
        sidebarRef.addEventListener("mouseleave", enableMapInteractions);
        return () => {
            sidebarRef.removeEventListener("mouseenter", disableMapInteractions);
            sidebarRef.removeEventListener("mouseleave", enableMapInteractions);
            sidebarRef.removeEventListener("touchstart", disableMapInteractions);
            sidebarRef.removeEventListener("touchend", enableMapInteractions);
            sidebarRef.removeEventListener("touchmove", enableMapInteractions);
        };
    }, [sidebarRef]);
    const disableMapInteractions = () => {
        dispatch(setClickEventFail());
    };
    const enableMapInteractions = () => {
        dispatch(setClickEventSucess());
    };
    const handleOpenRequestHighResolutionModal = () => {
        handleCloseHighResolutionModal();
        setmodalOpenRequestHighResolution(true);
    }
    const handleCloseRequestHighResolutionModal = () => {
        setmodalOpenRequestHighResolution(false);
    };

    useEffect(() => {
        if (!taskId) return
        dispatch(getOrders(taskId)).then(res => {
            if (res.status == 200) {
                dispatch({
                    type: HIGH_RES_SERVICE_AVAILABLE
                })
                setOrders(res.data)
                if (res.data.length != 0) {
                    dispatch({
                        type: REQUEST_EXIST,
                    });
                } else {
                    dispatch({
                        type: REQUEST_EMPTY
                    })
                }
            } else {
                dispatch({
                    type: HIGH_RES_SERVICE_NOT_AVAILABLE
                })
            }
        })
    }, [taskId])
    const handleOpenHighResolutionModal = () => {
        handleCloseRequestHighResolutionModal()
        setmodalOpenHighResolution(true);

    }
    const handleCloseHighResolutionModal = () => {
        setmodalOpenHighResolution(false);
    };

    useEffect(() => {
        if (!taskName) return
        dispatch(get_smart_control_command_status()).then((res) => {
            if (res.status === 200) {
                if (res.data.LastMode === "AUTO") {
                    setisCheckedIOTMode(true)
                } else {
                    setisCheckedIOTMode(false)
                }
                if (res.data.LastSwitch === "OFF") {
                    setisCheckedIOT(false)
                } else {
                    setisCheckedIOT(true)
                }
            }
        })
    }, [taskName])

    const handleIotChange = () => {
        if (isCheckedIOT === false) {
            setisCheckedIOT(true)
            dispatch(set_smart_control_command("ON")).then((res) => {
                if (res.status === 200) {
                    dispatch(
                        showSnackbar(
                            "success",
                            undefined,
                            res.data.message,
                            "top",
                            "right"
                        )
                    );
                } else {
                    dispatch(
                        showSnackbar(
                            "error",
                            undefined,
                            t("something went wrong !"),
                            "top",
                            "right"
                        )
                    );
                }
            })
        } else {
            setisCheckedIOT(false)
            dispatch(set_smart_control_command("OFF")).then((res) => {
                if (res.status === 200) {
                    dispatch(
                        showSnackbar(
                            "warning",
                            undefined,
                            res.data.message,
                            "top",
                            "right"
                        )
                    );
                } else {
                    dispatch(
                        showSnackbar(
                            "error",
                            undefined,
                            t("something went wrong !"),
                            "top",
                            "right"
                        )
                    );
                }
            })
        }
    }
    const handleIotModeChange = () => {
        if (isCheckedIOTMode === false) {
            setisCheckedIOTMode(true)
            dispatch(set_smart_control_command("AUTO")).then((res) => {
                if (res.status === 200) {
                    dispatch(
                        showSnackbar(
                            "success",
                            undefined,
                            res.data.message,
                            "top",
                            "right"
                        )
                    );
                } else {
                    dispatch(
                        showSnackbar(
                            "error",
                            undefined,
                            t("something went wrong !"),
                            "top",
                            "right"
                        )
                    );
                }
            })
        } else {
            setisCheckedIOTMode(false)
            dispatch(set_smart_control_command("MANUAL")).then((res) => {
                if (res.status === 200) {
                    dispatch(
                        showSnackbar(
                            "success",
                            undefined,
                            res.data.message,
                            "top",
                            "right"
                        )
                    );
                } else {
                    dispatch(
                        showSnackbar(
                            "error",
                            undefined,
                            t("something went wrong !"),
                            "top",
                            "right"
                        )
                    );
                }
            })
        }
    }
    return (


        <div class="accordion   col-sm-10 col-lg-4" id="atsk-accordion">


            <button class="btn btn-primary m-absolute" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasScrolling" aria-controls="offcanvasScrolling">Enable body scrolling</button>

            <div class="custom-offcanvas offcanvas offcanvas-start" data-bs-scroll="true" data-bs-backdrop="false" tabindex="-1" id="offcanvasScrolling" aria-labelledby="offcanvasScrollingLabel">
                <div class="offcanvas-header bg-white">
                    <Container maxWidth="md" className="container-sidebar container-border">
                        <Row className="">
                            <Col
                                xs="12"
                                className="d-flex task-title-container align-items-center"
                            >
                                <div className="icon-wrapper text-white  d-flex justify-content-center align-item-center">
                                    <i className="fg-lg fg-location-poi-o task-title-icon"></i>
                                </div>
                                <span className="task-title">{taskName}</span>
                            </Col>
                        </Row>
                    </Container>

                    <button type="button" class="btn-close text-dark" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                </div>
                <div class="offcanvas-body bg-white">


                    <Container maxWidth="md" className="container-sidebar">
                        <Row className="">
                            <Col xs="12" className="task-sidebar-navs-container">
                                <ul
                                    className="nav nav-tabs nav-tabs-custom nav-justified"
                                    role="tablist"
                                >
                                    <li className="nav-item">
                                        <a
                                            className="nav-link  sidebar-navlink "
                                            data-bs-toggle="tab"
                                            href="#home1"
                                            role="tab"
                                        >
                                            <span className="d-flex imagery-icon justify-content-center">
                                                <svg
                                                    viewBox="0 0 64 64"
                                                    fill="currentColor"
                                                    height="1em"
                                                    width="1em"
                                                >
                                                    <path
                                                        fill="none"
                                                        stroke="currentColor"
                                                        strokeMiterlimit={10}
                                                        strokeWidth={2}
                                                        d="M18 26.066L2 33l30 12 30-12-16-6.933"
                                                    />
                                                    <path
                                                        fill="none"
                                                        stroke="currentColor"
                                                        strokeMiterlimit={10}
                                                        strokeWidth={2}
                                                        d="M18 39.066L2 46l30 12 30-12-16-6.933M32 32l30-12L32 7 2 20z"
                                                    />
                                                </svg>
                                            </span>
                                            <span className="d-none d-sm-block">{t("IMAGERY")}</span>
                                        </a>
                                    </li>
                                    <li className="nav-item">
                                        <a
                                            className="nav-link sidebar-navlink active"
                                            data-bs-toggle="tab"
                                            href="#profile1"
                                            role="tab"
                                        >
                                            <span className="d-flex imagery-icon justify-content-center" >
                                                <svg
                                                    fill="none"
                                                    stroke="currentColor"
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                    strokeWidth={2}
                                                    viewBox="0 0 24 24"
                                                    height="1em"
                                                    width="1em"
                                                >
                                                    <path stroke="none" d="M0 0h24v24H0z" />
                                                    <path d="M11 18l-2-1-6 3V7l6-3 6 3 6-3v10M9 4v13M15 7v5" />
                                                    <path d="M19 17.5 A2.5 2.5 0 0 1 16.5 20 A2.5 2.5 0 0 1 14 17.5 A2.5 2.5 0 0 1 19 17.5 z" />
                                                    <path d="M18.5 19.5L21 22" />
                                                </svg>
                                            </span>
                                            <span className="d-none d-sm-block">{t("ANALYSIS")}</span>
                                        </a>
                                    </li>
                                </ul>
                            </Col>
                        </Row>
                    </Container>
                    <div className="tab-content">
                        <div className="tab-pane" id="home1" role="tabpanel">
                            <Container
                                maxWidth="md"
                                className="tab-content container-sidebar container-border"
                            >
                                <Row className="pt-2 pb-2 d-flex align-items-center ">
                                    <Col
                                        xs="6"
                                        className="d-flex align-items-center justify-content-center"
                                    >
                                        {task_id && <TaskImage taskId={task_id} />}
                                    </Col>
                                    <Col xs={6} className="d-flex align-items-center">
                                        <div>
                                            <span style={{ fontSize: "17px" }} className="d-flex align-items-center">
                                                <p><IoLocationOutline color="#6f9d2f" style={{ marginRight: "2px" }} />
                                                    {country ? country : ""}{state ? ", " + state : ""}{stateDistrict ? ", " + stateDistrict : ""}{county ? ", " + county : ""}{postcode ? ", " + postcode : ""}</p>
                                            </span>
                                            <p>
                                                <span style={{ fontSize: "17px" }} className="d-flex align-items-center">
                                                    <GiPencilRuler color="#6f9d2f" style={{ marginRight: "2px" }} />
                                                    <b>{t("Area : ")}</b>{AreaSurface} Ha
                                                </span>

                                            </p>
                                        </div>
                                    </Col>
                                </Row>
                            </Container>
                            <div className="d-block d-md-none">
                                <Container
                                    maxWidth="md"
                                    className="tab-content container-sidebar container-border "
                                >
                                    <BlockHeader title={"Weather Insights"} CustomIcon={BsCloudSun} />
                                </Container>
                            </div>
                            <div className="d-block d-md-none">
                                <Container
                                    maxWidth="md"
                                    className="tab-content container-sidebar container-border"

                                >
                                    <Row className="pt-2 pb-2">
                                        <Col xs={12} className="">

                                            <WeatherBlock CalledFrom={"Sidebar"} openModal={openModal} />
                                        </Col>
                                    </Row>

                                </Container>
                            </div>

                            <Container
                                maxWidth="md"
                                className="tab-content container-sidebar container-border"
                            >
                                <BlockHeader title={t("Events Calendar")} CustomIcon={FaRegCalendarAlt} openModal={openModal} />
                            </Container>
                            <Container
                                maxWidth="md"
                                className="tab-content container-sidebar container-border"
                            >
                                <CalendarBlock openModal={openModal} />
                            </Container>
                            <Container
                                maxWidth="md"
                                className="tab-content container-sidebar container-border"
                            >
                                <SurveyHeader editSurvey={1} />
                            </Container>
                            <Container className="container-border">
                                <SurveyEdit />
                            </Container>
                        </div>
                        <div className="tab-pane active" id="profile1" role="tabpanel">
                            {indexes.map((item, index) => (
                                <Container
                                    maxWidth="md"
                                    className="tab-content container-sidebar container-border"
                                    key={index}
                                >
                                    <IndexCheckbox
                                        index={item}
                                        isChecked={item.id === selectedCheckboxId}
                                        onCheckboxChange={handleCheckboxChange}
                                    />
                                </Container>
                            ))}
                            {treeCount ?
                                <Container
                                    maxWidth="md"
                                    className="tab-content container-sidebar container-border"
                                >
                                    <TreeCountCheckbox />
                                </Container>
                                : ""}
                            <Container
                                maxWidth="md"
                                className="tab-content container-sidebar container-border"
                            >
                                <BlockHeader title={"Indexs Curve over time"} icon={"fg-profile-o"} openModal={openModal} />
                            </Container>
                            <Container
                                maxWidth="md"
                                className="tab-content container-sidebar container-border"
                            >
                                <GraphLayout clickEvent={openModal} />
                            </Container>
                            {taskName && taskName === "Choucha" ?
                                <Container
                                    maxWidth="md"
                                    className="tab-content container-sidebar container-border"
                                >
                                    <Row className="pt-2 pb-2">
                                        <Col xs={12} className="d-flex align-items-center">
                                            <div className="icon-wrapper text-white  d-flex justify-content-center align-item-center">
                                                <RiRemoteControlLine className="task-title-icon" />
                                            </div>
                                            <div className="area-title align-items-center d-flex">
                                                <span className="task-title">{t("Smart irrigation commands")}</span>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row className="pt-2 pb-2">
                                        <Col xs={6} className="d-flex justify-content-center">
                                            <div className="checkbox-on-off">
                                                <input className='tgl tgl-ios' id='toggle-34' type='checkbox' checked={isCheckedIOT} onChange={handleIotChange} />
                                                <label className='tgl-btn' for='toggle-34'></label>
                                            </div>
                                        </Col>
                                        <Col xs={6} className="d-flex justify-content-center">
                                            <div className="checkbox-man-auto">
                                                <input className='tgl tgl-ios' id='toggle-35' type='checkbox' checked={isCheckedIOTMode} onChange={handleIotModeChange} />
                                                <label className='tgl-btn' for='toggle-35'></label>
                                            </div>
                                        </Col>

                                    </Row>
                                </Container>

                                : ""}
                            <Container
                                maxWidth="md"
                                className="tab-content container-sidebar container-border"
                            >
                                <BlockHeader title={"High Resolution Imagery"} icon={"fg-satellite-earth"} />
                            </Container>
                            <Container
                                maxWidth="md"
                                className="tab-content container-sidebar container-border"
                            >
                                <Row className="pt-2 pb-2">
                                    <Col xs={12} className="">
                                        <span className="high-resolution-span">
                                            {t("Unlock the full potential of our satellite imagery with our High-Resolution Subscription service. Immerse yourself in unparalleled detail and clarity as you subscribe to access cutting-edge, high-definition satellite images.")}
                                        </span>
                                    </Col>
                                </Row>
                                <Row className="pb-2">
                                    <Col xs={12} className="d-flex justify-content-center">
                                        {has_requestEdit === false ?
                                            <Button className="btn btn-login" disabled={!high_res_service_available} onClick={handleOpenRequestHighResolutionModal}>{t("Request high resolution data")}{!high_res_service_available ? "(temporarly unavailable)" : ""}</Button>
                                            :
                                            <Button className="btn btn-login" disabled={!high_res_service_available} onClick={handleOpenHighResolutionModal}>{t("High resolution data")}{!high_res_service_available ? "(temporarly unavailable)" : ""}</Button>
                                        }
                                        <RequestHighResolutionPopup open={modalOpenRequestHighResolution} handleClose={handleCloseRequestHighResolutionModal} />
                                        <HighResolutionPopup data={orders} open={modalOpenHighResolution} handleClose={handleCloseHighResolutionModal} openrequestmodal={handleOpenRequestHighResolutionModal} />
                                    </Col>
                                </Row>
                            </Container>
                        </div>
                    </div>


                    <SidebarBottom />
                </div>
            </div>



            {/* <div className="m-task-sidebar">
                <button class="btn btn-primary" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasScrolling" aria-controls="offcanvasScrolling">Enable body scrolling</button>

            </div> */}


            {/*
             <div class="accordion-item">
                <h2 class="accordion-header">
                    <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                        {t("More Details")}
                    </button>
                </h2>
                <div id="collapseOne" class="accordion-collapse collapse show" data-bs-parent="#atsk-accordion">
                    <div class="accordion-body">


                        <div className="sidebar" ref={setsidebarRef}>
                            <Container maxWidth="md" className="container-sidebar container-border">
                                <Row className="">
                                    <Col
                                        xs="12"
                                        className="d-flex task-title-container align-items-center"
                                    >
                                        <div className="icon-wrapper text-white  d-flex justify-content-center align-item-center">
                                            <i className="fg-lg fg-location-poi-o task-title-icon"></i>
                                        </div>
                                        <span className="task-title">{taskName}</span>
                                    </Col>
                                </Row>
                            </Container>
                            <Container maxWidth="md" className="container-sidebar">
                                <Row className="">
                                    <Col xs="12" className="task-sidebar-navs-container">
                                        <ul
                                            className="nav nav-tabs nav-tabs-custom nav-justified"
                                            role="tablist"
                                        >
                                            <li className="nav-item">
                                                <a
                                                    className="nav-link  sidebar-navlink "
                                                    data-bs-toggle="tab"
                                                    href="#home1"
                                                    role="tab"
                                                >
                                                    <span className="d-flex imagery-icon justify-content-center">
                                                        <svg
                                                            viewBox="0 0 64 64"
                                                            fill="currentColor"
                                                            height="1em"
                                                            width="1em"
                                                        >
                                                            <path
                                                                fill="none"
                                                                stroke="currentColor"
                                                                strokeMiterlimit={10}
                                                                strokeWidth={2}
                                                                d="M18 26.066L2 33l30 12 30-12-16-6.933"
                                                            />
                                                            <path
                                                                fill="none"
                                                                stroke="currentColor"
                                                                strokeMiterlimit={10}
                                                                strokeWidth={2}
                                                                d="M18 39.066L2 46l30 12 30-12-16-6.933M32 32l30-12L32 7 2 20z"
                                                            />
                                                        </svg>
                                                    </span>
                                                    <span className="d-none d-sm-block">{t("IMAGERY")}</span>
                                                </a>
                                            </li>
                                            <li className="nav-item">
                                                <a
                                                    className="nav-link sidebar-navlink active"
                                                    data-bs-toggle="tab"
                                                    href="#profile1"
                                                    role="tab"
                                                >
                                                    <span className="d-flex imagery-icon justify-content-center" >
                                                        <svg
                                                            fill="none"
                                                            stroke="currentColor"
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                            strokeWidth={2}
                                                            viewBox="0 0 24 24"
                                                            height="1em"
                                                            width="1em"
                                                        >
                                                            <path stroke="none" d="M0 0h24v24H0z" />
                                                            <path d="M11 18l-2-1-6 3V7l6-3 6 3 6-3v10M9 4v13M15 7v5" />
                                                            <path d="M19 17.5 A2.5 2.5 0 0 1 16.5 20 A2.5 2.5 0 0 1 14 17.5 A2.5 2.5 0 0 1 19 17.5 z" />
                                                            <path d="M18.5 19.5L21 22" />
                                                        </svg>
                                                    </span>
                                                    <span className="d-none d-sm-block">{t("ANALYSIS")}</span>
                                                </a>
                                            </li>
                                        </ul>
                                    </Col>
                                </Row>
                            </Container>
                            <div className="tab-content">
                                <div className="tab-pane" id="home1" role="tabpanel">
                                    <Container
                                        maxWidth="md"
                                        className="tab-content container-sidebar container-border"
                                    >
                                        <Row className="pt-2 pb-2 d-flex align-items-center ">
                                            <Col
                                                xs="6"
                                                className="d-flex align-items-center justify-content-center"
                                            >
                                                {task_id && <TaskImage taskId={task_id} />}
                                            </Col>
                                            <Col xs={6} className="d-flex align-items-center">
                                                <div>
                                                    <span style={{ fontSize: "17px" }} className="d-flex align-items-center">
                                                        <p><IoLocationOutline color="#6f9d2f" style={{ marginRight: "2px" }} />
                                                            {country ? country : ""}{state ? ", " + state : ""}{stateDistrict ? ", " + stateDistrict : ""}{county ? ", " + county : ""}{postcode ? ", " + postcode : ""}</p>
                                                    </span>
                                                    <p>
                                                        <span style={{ fontSize: "17px" }} className="d-flex align-items-center">
                                                            <GiPencilRuler color="#6f9d2f" style={{ marginRight: "2px" }} />
                                                            <b>{t("Area : ")}</b>{AreaSurface} Ha
                                                        </span>

                                                    </p>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Container>
                                    <div className="d-block d-md-none">
                                        <Container
                                            maxWidth="md"
                                            className="tab-content container-sidebar container-border "
                                        >
                                            <BlockHeader title={"Weather Insights"} CustomIcon={BsCloudSun} />
                                        </Container>
                                    </div>
                                    <div className="d-block d-md-none">
                                        <Container
                                            maxWidth="md"
                                            className="tab-content container-sidebar container-border"

                                        >
                                            <Row className="pt-2 pb-2">
                                                <Col xs={12} className="">

                                                    <WeatherBlock CalledFrom={"Sidebar"} openModal={openModal} />
                                                </Col>
                                            </Row>

                                        </Container>
                                    </div>

                                    <Container
                                        maxWidth="md"
                                        className="tab-content container-sidebar container-border"
                                    >
                                        <BlockHeader title={t("Events Calendar")} CustomIcon={FaRegCalendarAlt} openModal={openModal} />
                                    </Container>
                                    <Container
                                        maxWidth="md"
                                        className="tab-content container-sidebar container-border"
                                    >
                                        <CalendarBlock openModal={openModal} />
                                    </Container>
                                    <Container
                                        maxWidth="md"
                                        className="tab-content container-sidebar container-border"
                                    >
                                        <SurveyHeader editSurvey={1} />
                                    </Container>
                                    <Container className="container-border">
                                        <SurveyEdit />
                                    </Container>
                                </div>
                                <div className="tab-pane active" id="profile1" role="tabpanel">
                                    {indexes.map((item, index) => (
                                        <Container
                                            maxWidth="md"
                                            className="tab-content container-sidebar container-border"
                                            key={index}
                                        >
                                            <IndexCheckbox
                                                index={item}
                                                isChecked={item.id === selectedCheckboxId}
                                                onCheckboxChange={handleCheckboxChange}
                                            />
                                        </Container>
                                    ))}
                                    {treeCount ?
                                        <Container
                                            maxWidth="md"
                                            className="tab-content container-sidebar container-border"
                                        >
                                            <TreeCountCheckbox />
                                        </Container>
                                        : ""}
                                    <Container
                                        maxWidth="md"
                                        className="tab-content container-sidebar container-border"
                                    >
                                        <BlockHeader title={"Indexs Curve over time"} icon={"fg-profile-o"} openModal={openModal} />
                                    </Container>
                                    <Container
                                        maxWidth="md"
                                        className="tab-content container-sidebar container-border"
                                    >
                                        <GraphLayout clickEvent={openModal} />
                                    </Container>
                                    {taskName && taskName === "Choucha" ?
                                        <Container
                                            maxWidth="md"
                                            className="tab-content container-sidebar container-border"
                                        >
                                            <Row className="pt-2 pb-2">
                                                <Col xs={12} className="d-flex align-items-center">
                                                    <div className="icon-wrapper text-white  d-flex justify-content-center align-item-center">
                                                        <RiRemoteControlLine className="task-title-icon" />
                                                    </div>
                                                    <div className="area-title align-items-center d-flex">
                                                        <span className="task-title">{t("Smart irrigation commands")}</span>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row className="pt-2 pb-2">
                                                <Col xs={6} className="d-flex justify-content-center">
                                                    <div className="checkbox-on-off">
                                                        <input className='tgl tgl-ios' id='toggle-34' type='checkbox' checked={isCheckedIOT} onChange={handleIotChange} />
                                                        <label className='tgl-btn' for='toggle-34'></label>
                                                    </div>
                                                </Col>
                                                <Col xs={6} className="d-flex justify-content-center">
                                                    <div className="checkbox-man-auto">
                                                        <input className='tgl tgl-ios' id='toggle-35' type='checkbox' checked={isCheckedIOTMode} onChange={handleIotModeChange} />
                                                        <label className='tgl-btn' for='toggle-35'></label>
                                                    </div>
                                                </Col>

                                            </Row>
                                        </Container>

                                        : ""}
                                    <Container
                                        maxWidth="md"
                                        className="tab-content container-sidebar container-border"
                                    >
                                        <BlockHeader title={"High Resolution Imagery"} icon={"fg-satellite-earth"} />
                                    </Container>
                                    <Container
                                        maxWidth="md"
                                        className="tab-content container-sidebar container-border"
                                    >
                                        <Row className="pt-2 pb-2">
                                            <Col xs={12} className="">
                                                <span className="high-resolution-span">
                                                    {t("Unlock the full potential of our satellite imagery with our High-Resolution Subscription service. Immerse yourself in unparalleled detail and clarity as you subscribe to access cutting-edge, high-definition satellite images.")}
                                                </span>
                                            </Col>
                                        </Row>
                                        <Row className="pb-2">
                                            <Col xs={12} className="d-flex justify-content-center">
                                                {has_requestEdit === false ?
                                                    <Button className="btn btn-login" disabled={!high_res_service_available} onClick={handleOpenRequestHighResolutionModal}>{t("Request high resolution data")}{!high_res_service_available ? "(temporarly unavailable)" : ""}</Button>
                                                    :
                                                    <Button className="btn btn-login" disabled={!high_res_service_available} onClick={handleOpenHighResolutionModal}>{t("High resolution data")}{!high_res_service_available ? "(temporarly unavailable)" : ""}</Button>
                                                }
                                                <RequestHighResolutionPopup open={modalOpenRequestHighResolution} handleClose={handleCloseRequestHighResolutionModal} />
                                                <HighResolutionPopup data={orders} open={modalOpenHighResolution} handleClose={handleCloseHighResolutionModal} openrequestmodal={handleOpenRequestHighResolutionModal} />
                                            </Col>
                                        </Row>
                                    </Container>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div> */}
        </div>


    );
};

export default Sidebar;
