import React, { useState } from "react";
import { Dispatch } from "react";
import BasicDatePicker from "../../../utils/BasicDatePicker";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getIndexArray, getStartEndDate } from "../../../actions/graph";
import dayjs from "dayjs";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Form from "react-bootstrap/Form";
import { Box, Button, Container, Slider, Typography } from "@mui/material";
import { LOADING_BEGIN } from "../../../utils/types";
import SaveIcon from "@mui/icons-material/Save";
import Stack from "@mui/material/Stack";
import LoadingButton from "@mui/lab/LoadingButton";
import FormControlLabel from "@mui/material/FormControlLabel";
import Graph from "./graph";
import { showSnackbar } from "../../../actions/snackbar";
import { useTranslation } from "react-i18next";
import { Row } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { handleFilterData, handleFilterDataByCloudPercentage, findMinMaxValues } from '../../../utils/graphDataFilter.js'
import GraphYear from "./graphYear.js";
import Switch from '@mui/material/Switch';
import { styled } from '@mui/material/styles';
import { getForecastPredict } from "../../../actions/graph.js";
import { get_task_notifications } from "../../../actions/notification.js";


const AntSwitch = styled(Switch)(({ theme }) => ({
    width: 28,
    height: 16,
    padding: 0,
    display: 'flex',
    '&:active': {
        '& .MuiSwitch-thumb': {
            width: 15,
        },
        '& .MuiSwitch-switchBase.Mui-checked': {
            transform: 'translateX(9px)',
        },
    },
    '& .MuiSwitch-switchBase': {
        padding: 2,
        '&.Mui-checked': {
            transform: 'translateX(12px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
                opacity: 1,
                backgroundColor: theme.palette.mode === 'dark' ? '#177ddc' : '#1890ff',
            },
        },
    },
    '& .MuiSwitch-thumb': {
        boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
        width: 12,
        height: 12,
        borderRadius: 6,
        transition: theme.transitions.create(['width'], {
            duration: 200,
        }),
    },
    '& .MuiSwitch-track': {
        borderRadius: 16 / 2,
        opacity: 1,
        backgroundColor:
            theme.palette.mode === 'dark' ? 'rgba(255,255,255,.35)' : 'rgba(0,0,0,.25)',
        boxSizing: 'border-box',
    },
}));
const GraphPage = () => {
    const dispatch = useDispatch();
    const start_end_dates = useSelector(
        (state) => state.graphReducer.graph_dates
    );

    const taskId = useSelector((state) => state.mapReducer.taskIdDetails);
    const [data, setdata] = useState([])
    const [dataPerYear, setdataPerYear] = useState([])
    const [loading, setLoading] = useState(true);
    const { t } = useTranslation();
    const [indexText, setindexText] = useState("Vegetation Index")
    const data_graph = useSelector((state) => state.graphReducer.graph_data);
    const today = dayjs();
    const [attemptCount, setAttemptCount] = useState(0);
    const [graphDataReady, setgraphDataReady] = useState(false)
    const [dataPredict, setdataPredict] = useState([])
    const [dataPredictIndex, setdataPredictIndex] = useState([])
    const [notifications, setnotifications] = useState([])

    const [formData, setFormData] = useState({
        StartDate: today.subtract(1, 'year'),
        EndDate: today,
        index: "ndvi",
    });
    const marks = [
        {
            value: 10,
            label: '10 %',
        },
        {
            value: 20,
            label: '20 %',
        },
        {
            value: 30,
            label: '30 %',
        },
        {
            value: 40,
            label: '40 %',
        },
    ];
    // Function to extract unique years from the data
    function extractUniqueYears(data) {
        const yearsSet = new Set();
        data.forEach(item => {
            const year = item.date.split("-")[0];
            yearsSet.add(year);
        });
        return Array.from(yearsSet);
    }
    // Function to extract data for each year
    function extractDataForYear(data, year) {
        return data.filter(item => item.date.startsWith(year));
    }

    useEffect(() => {
        if (!taskId) return
        dispatch(getForecastPredict(taskId)).then((response) => {
            if (response.status === 200) {
                setdataPredict(response.data.results)
            }
        })
    }, [taskId])
    useEffect(() => {
        setFormData({
            ...formData,
            StartDate: today.subtract(1, 'year'),
            EndDate: today,
            cloud_percentage: 40
        });

    }, []);
    const { StartDate, EndDate, index, cloud_percentage } = formData;
    const onChange = (e) =>
        setFormData({ ...formData, [e.target.name]: e.target.value });
    const onChangeIndex = (e) => {
        setFormData({ ...formData, index: e.target.value });
        console.log('e.target.value', e.target.value)
        switch (e.target.value) {
            case "ndvi":
                setindexText("Vegetation Index")
                break
            case "ndre":
                setindexText("Nitrogen Index")
                break
            case "ndwi":
                setindexText("Irrigation index")
                break
            case "gci":
                setindexText("Plant Health Index")
                break
            case "psri":
                setindexText("Plant Senescence Index")
                break
            case "msi":
                setindexText("MOISTURE STRESS INDEX")
                break
            case "siwsi":
                setindexText("SHORTWAVE INFRARED WATER STRESS INDEX")
                break
            case "evi":
                setindexText("ENHANCED VEGETATION INDEX")
                break
            default:
                setindexText("Vegetation Index")
        }
    };
    const onChangeSlider = (e, value) => {
        setFormData({ ...formData, cloud_percentage: value });
    };
    function valuetext(value) {
        return `${value} %`;
    }
    useEffect(() => {
        if (!taskId) return
        dispatch(get_task_notifications(taskId)).then((res) => {
            if (res.status === 200) {
                console.log('res notification', res)
                setnotifications(res.data.data)
            }
        })
    }, [taskId])
    useEffect(() => {
        if (!taskId) return;
        if (data_graph) {
            setLoading(false)
            return
        };
        let intervalId
        const checkGraphData = () => {
            if (!graphDataReady) {
                if (attemptCount < 36) {
                    dispatch(getIndexArray(taskId)).then((res) => {
                        if (res.status === 200) {
                            console.log('res.status', res.status)
                            console.log('res', res)
                            setgraphDataReady(true);
                            setLoading(false)
                            clearInterval(intervalId);
                        }
                    }
                    )
                    setAttemptCount(attemptCount + 1);
                } else {
                    // Stop the interval after reaching maxAttempts wichh is 3 min
                    clearInterval(intervalId);
                }
            } else {
                clearInterval(intervalId);
                setLoading(false)
            }
        }
        intervalId = setInterval(checkGraphData, 5000);
        // Initial check
        checkGraphData()
        // Cleanup the interval when the component unmounts
        return () => clearInterval(intervalId);
    }, [taskId, data_graph]);

    useEffect(() => {
        if (!data_graph) return
        // console.log('data_graphhh', data_graph)
        const ndviElement = data_graph.find(item => item.index === "ndvi");
        console.log('ndviElement', ndviElement)
        let dataFiltred = []
        let dataSmoothedFiltred = []
        dataFiltred = handleFilterData(ndviElement.data, StartDate, EndDate)
        dataSmoothedFiltred = handleFilterData(ndviElement.data_smoothed, StartDate, EndDate)
        console.log('dataFiltred', dataFiltred)
        // console.log('dataSmoothedFiltred', dataSmoothedFiltred)
        const { minValue, maxValue } = findMinMaxValues(dataFiltred, dataSmoothedFiltred);
        // console.log('Min Value:', minValue);
        // console.log('Max Value:', maxValue);
        const arr = {
            "data": dataFiltred,
            "data_smoothed": dataSmoothedFiltred,
            "min": minValue,
            "max": maxValue
        }
        setdata(arr)

        // if (dataPredict.length !== 0) {
        //     console.log('dataPredict graph page', dataPredict)
        //     const ElementPredict = dataPredict["ndvi"];
        //     setdataPredictIndex(ElementPredict.data_predict_fortnite)
        //     console.log('ElementPredict', ElementPredict)
        // }
        // Extract unique years
        // console.log('dataFiltred', dataFiltred)
        const uniqueYears = extractUniqueYears(ndviElement.data);
        console.log('uniqueYears', uniqueYears)
        // const dataWithCloudMask = handleFilterDataByCloudPercentage(ndviElement.data, cloud_percentage)
        // console.log('dataWithCloudMask', dataWithCloudMask)
        const dataByYear = {};
        uniqueYears.forEach(year => {
            dataByYear[year] = extractDataForYear(ndviElement.data, year);
        });
        console.log('dataByYear', dataByYear)
        // const { minValueAllYears, maxValueAllYears } = findMinMaxValues(ndviElement.data, ndviElement.data);
        const valuesArray = ndviElement.data.map(item => parseFloat(item.value));
        // Find min and max values
        const minValueAllYears = Math.min(...valuesArray);
        const maxValueAllYears = Math.max(...valuesArray);
        console.log('minValueAllYears', minValueAllYears)
        console.log('maxValueAllYears', maxValueAllYears)
        const arr2 = [
            {
                "data": dataByYear,
                "min": minValueAllYears,
                "max": maxValueAllYears,
                "years": uniqueYears
            }
        ]
        console.log('arr2', arr2)
        setdataPerYear(arr2)

    }, [data_graph, dataPredict])

    const onSubmit = (e) => {
        console.log('StartDate', StartDate)
        console.log('EndDate', EndDate)
        setLoading(true);
        e.preventDefault();
        // Validation: Check if StartDate, EndtDate, and index are not empty
        if (
            !StartDate ||
            !EndDate ||
            !index ||
            !StartDate.isValid() ||
            !EndDate.isValid()
        ) {
            dispatch(
                showSnackbar(
                    "error",
                    undefined,
                    t("Please checkout all fields !"),
                    "top",
                    "right"
                )
            );
            setLoading(false);
        } else if (!StartDate.isBefore(EndDate)) {
            dispatch(
                showSnackbar(
                    "error",
                    undefined,
                    t("Start date must be before end date !"),
                    "top",
                    "right"
                )
            );
        } else {
            const indexElement = data_graph.find(item => item.index === index);
            console.log('indexElement', indexElement)
            // Function to handle filtering based on start and end dates
            let dataFiltred = []
            let dataSmoothedFiltred = []
            let dataFiltredByCloudPercentage = []
            let dataSmoothedFiltredByCloudPercentage = []
            dataFiltred = handleFilterData(indexElement.data, StartDate, EndDate)
            dataSmoothedFiltred = handleFilterData(indexElement.data_smoothed, StartDate, EndDate)
            dataFiltredByCloudPercentage = handleFilterDataByCloudPercentage(dataFiltred, cloud_percentage)
            dataSmoothedFiltredByCloudPercentage = handleFilterDataByCloudPercentage(dataSmoothedFiltred, cloud_percentage)
            // console.log('dataFiltred', dataFiltred)
            // console.log('dataSmoothedFiltred', dataSmoothedFiltred)
            const { minValue, maxValue } = findMinMaxValues(dataFiltred, dataSmoothedFiltred);
            // console.log('Min Value:', minValue);
            // console.log('Max Value:', maxValue);
            const arr1 = {
                "data": dataFiltredByCloudPercentage,
                "data_smoothed": dataSmoothedFiltredByCloudPercentage,
                "min": minValue,
                "max": maxValue
            }
            setdata(arr1)
            // if (dataPredict.length > 0) {
            //     const ElementPredict = dataPredict[index];
            //     setdataPredictIndex(ElementPredict.data_predict_fortnite)
            // }
            const uniqueYears = extractUniqueYears(indexElement.data);
            // console.log('uniqueYears', uniqueYears)
            // Extract data for each year
            const dataWithCloudMask = handleFilterDataByCloudPercentage(indexElement.data, cloud_percentage)
            // console.log('dataWithCloudMask', dataWithCloudMask)
            const dataByYear = {};
            uniqueYears.forEach(year => {
                dataByYear[year] = extractDataForYear(dataWithCloudMask, year);
            });
            // const { minValueAllYears, maxValueAllYears } = findMinMaxValues(indexElement.data, indexElement.data);
            const valuesArray = indexElement.data.map(item => parseFloat(item.value));
            // Find min and max values
            const minValueAllYears = Math.min(...valuesArray);
            const maxValueAllYears = Math.max(...valuesArray);
            // console.log('minValueAllYears', minValueAllYears)
            // console.log('maxValueAllYears', maxValueAllYears)
            // console.log('dataByYear', dataByYear)
            const arr2 = [
                {
                    "data": dataByYear,
                    "min": minValueAllYears,
                    "max": maxValueAllYears,
                    "years": uniqueYears
                }
            ]
            console.log('arr2', arr2)
            setdataPerYear(arr2)
            setLoading(false)
        }
        setLoading(false)
    };
    useEffect(() => {
        if (!dataPerYear) return
        console.log('dataPerYear', dataPerYear)
        console.log('dataPerYear.length', dataPerYear.length)
    }, [dataPerYear])
    return (
        <Container maxWidth="xl">
            <Row className="pt-4 pb-2">
                <Form onSubmit={(e) => onSubmit(e)}>
                    <div className="row">
                        <div className="col-md-4">
                            {start_end_dates ? (
                                <BasicDatePicker
                                    disabled={loading}
                                    className="graph_date_input"
                                    label={t("start date")}
                                    value={StartDate}
                                    name={"StartDate"}
                                    minDate={dayjs("2017-04-01")}
                                    maxDate={EndDate}
                                    onChange={(e) => onChange(e)}
                                />
                            ) : (
                                ""
                            )}
                        </div>
                        <div className="col-md-4">
                            {start_end_dates ? (
                                <BasicDatePicker
                                    disabled={loading}
                                    className="graph_date_input"
                                    label={t("end date")}
                                    value={EndDate}
                                    name={"EndDate"}
                                    onChange={(e) => onChange(e)}
                                    minDate={dayjs("2017-04-01")}
                                    maxDate={EndDate}
                                />
                            ) : (
                                ""
                            )}
                        </div>
                        <div className="col-md-4">
                            <FormControl
                                sx={{ mt: 1, mb: 1 }}
                                size="small"
                                className="m-select col-sm-12 "
                            >
                                <InputLabel id="demo-select-index-label">{t("Index")}</InputLabel>
                                <Select
                                    disabled={loading}
                                    labelId="demo-select-index-label"
                                    id="demo-select-index"
                                    label="Index"
                                    fullWidth
                                    onChange={(e) => onChangeIndex(e)}
                                    name="Index"
                                    defaultValue={"ndvi"}
                                    value={index}
                                >
                                    <MenuItem value="ndvi">{t("Vegetation Index")}</MenuItem>
                                    <MenuItem value="ndwi">{t("Irrigation index")}</MenuItem>
                                    <MenuItem value="ndre">{t("Nitrogen Index")}</MenuItem>
                                    <MenuItem value="gci">{t("Plant Health Index")}</MenuItem>
                                    <MenuItem value="psri">{t("Plant Senescence Index")}</MenuItem>
                                    <MenuItem value="msi">{t("Moisture index")}</MenuItem>
                                    <MenuItem value="siwsi">{t("Shortwave Infrared Water Stress Index")}</MenuItem>
                                    <MenuItem value="evi">{t("Enhanced Vegetation Index")}</MenuItem>
                                </Select>
                            </FormControl>
                        </div>
                    </div>
                    <div className="row mt-2">
                        <div className="col-md-12">
                            <Typography id="input-slider" gutterBottom>
                                Cloud percentage
                            </Typography>
                        </div>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col-md-11">
                            <Slider aria-label="Temperature"
                                defaultValue={40}
                                getAriaValueText={valuetext}
                                valueLabelDisplay="on"
                                marks={marks}
                                min={10}
                                max={40}
                                step={10}
                                disabled={loading}
                                name="cloud_percentage"
                                value={cloud_percentage}
                                onChange={onChangeSlider}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12 d-flex justify-content-center">
                            <LoadingButton
                                size="small"
                                color="primary"
                                type="submit"
                                loading={loading}
                                loadingPosition="start"
                                startIcon={<SaveIcon />}
                                variant="contained"
                            >
                                <span>{t("Process")}</span>
                            </LoadingButton>
                        </div>
                    </div>
                    <div className="row mt-3">
                        <div className="col-md-12 d-flex justify-content-center">
                            <Typography variant="h5" component="h2">
                                Historical Vegetation Index Data
                            </Typography>
                        </div>
                    </div>
                    {indexText && data &&
                        <div className="row" style={{ marginLeft: "10px" }}>
                            <Graph vegetationIndex={indexText} data={data} datapredict={dataPredictIndex} notifications={notifications} />
                        </div>
                    }
                    <div className="row mt-3">
                        <div className="col-md-12 d-flex justify-content-center">
                            <Typography variant="h5" component="h2">
                                Yearly Comparison of Vegetation Index
                            </Typography>
                        </div>
                    </div>
                    {indexText && dataPerYear.length > 0 &&
                        <div className="row" style={{ marginLeft: "10px" }}>
                            <GraphYear vegetationIndex={indexText} data={dataPerYear[0]} />
                        </div>
                    }
                </Form>
            </Row>
        </Container >
    );
};

export default GraphPage;
