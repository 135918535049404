import axios from 'axios';

import {
    GRAPH_DATA_ARRAY_FAIL,
    GRAPH_DATA_ARRAY_SUCCESS,
    GRAPH_START_END_DATE_END_FAIL,
    GRAPH_START_END_DATE_SUCCESS
} from "../utils/types.js";


export const getStartEndDate = () => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    try {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/index/graph/dates/`, config);
        dispatch({
            type: GRAPH_START_END_DATE_SUCCESS,
            payload: res.data.data
        })
        return res
    } catch (err) {
        dispatch({
            type: GRAPH_START_END_DATE_END_FAIL
        })
        return err
    }
}

export const getIndexArray = (task_id) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    try {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/task/graph_data/get/${task_id}/`, config);
        if (res.status === 200) {
            console.log('res.data', res.data)
            dispatch({
                type: GRAPH_DATA_ARRAY_SUCCESS,
                payload: res.data
            })
        } else {
            dispatch({
                type: GRAPH_DATA_ARRAY_FAIL
            })
        }
        return res
    } catch (err) {
        dispatch({
            type: GRAPH_DATA_ARRAY_FAIL
        })
        return err
    }
}

export const getForecastPredict = (task_id) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    try {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/task/graph_data/forecast/predict/get/${task_id}/`, config);
        return res
    } catch (err) {
        return err
    }
}