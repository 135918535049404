import React, { useState, useEffect, useRef } from "react";
import "leaflet/dist/leaflet.css";
import "leaflet-draw/dist/leaflet.draw.css";
import "../../assets/css/map.css";
// import "../../assets/css/Sidebar.css";
import "../../assets/scss/sidebars.scss";
import "../../assets/scss/weather.scss";
import L from "leaflet";
import {
    CircleMarker,
    LayerGroup,
    LayersControl,
    MapContainer,
    Popup,
    TileLayer,
    ZoomControl,
} from "react-leaflet";
import { useParams } from "react-router-dom";
import { EditControl } from "react-leaflet-draw";

import * as esri from "esri-leaflet";
// import SideBar from "./SideBarEdit";
import { loadingBegin, loadingEnd } from "../../actions/map";
import { useDispatch, useSelector } from "react-redux";
import { OpenStreetMapProvider, GeoSearchControl } from "leaflet-geosearch";
import "../../../node_modules/leaflet-geosearch/dist/geosearch.css";
import Loading from "../../utils/loading";
import NavBar from "../NavBar";
import { ScaleControl } from "react-leaflet";
import FullscreenControl from "../mapUtils/FullscreenControl";
import { createTheme, ThemeProvider } from "@mui/material/styles";

import {
    Detail_Task,
    Detail_Task_initial,
    Sensor_data,
    Set_detail_from_data,
    change_accusation_dates,
    change_selected_date,
    change_selected_date_initial,
    get_area_by_task_id,
    get_dates,
    get_task_tree_count,
} from "../../actions/task";
import SnackBarCopieToClipboard from "../../utils/SnackBarCopieToClipboard";
import $ from "jquery";
import NoteBtn from "./note/noteBtn";
import NoteModal from "./note/noteModal";
import PopupContent from "./count/PopupContent";
import { renderToString } from "react-dom/server";
import NoteListModal from "./note/noteListModal";
import Sidebar from "./sidebars/Sidebar";
import SidebarToggleButton from "./sidebars/SidebarToggleButton";
import SidebarBottom from "./sidebars/SidebarBottom";
import MainModal from "./analysePanel/MainModal";
import WeatherBlock from "./sidebars/WeatherBlock";

const { Overlay } = LayersControl;

const style = { height: "100%", width: "100wh", zIndex: "9" };

const mapParams = {
    center: [34.809, 9.515],
    // center: [35.0484, 10.325],

    zoom: 7,
    maxZoom: 18,
};

const apiKey =
    "AAPK1635806c5df74566bc9567f76c056850HTDo7PM4rZ-SwiEnzxmvAFNfj0zMBrP93MtbHy3q5YarvngZzkftSX-J_31R98B5";
const satelliteLayer = new esri.BasemapLayer("Imagery", {
    apikey: apiKey,
    portalUrl: "https://www.arcgis.com",
    maxZoom: 18,
    attribution: "",
});

const overlays = {
    Satellite: satelliteLayer,
};

const DetailsTask = () => {
    const [Map, setMap] = useState(null);
    const baseLayer = useRef(null);
    const baseMaps = {};
    // const [layerControl, setLayerControl] = useState(null);
    const [labelsLayer, setlabelsLayer] = useState(null);
    const [flyToCenter, setflyToCenter] = useState(null);
    const [floToZoom, setfloToZoom] = useState(null);
    const [projectName, setprojectName] = useState(null);
    const [opensnack, setopensnack] = useState(false);
    const [message, setmessage] = useState(null);
    const [vertical, setvertical] = useState(null);
    const [horizontal, sethorizontal] = useState(null);

    const dispatch = useDispatch();
    const VegetationLayersState = useSelector(
        (state) => state.mapReducer.DetailVegetationLayers
    );
    const checkedLayer = useSelector((state) => state.mapReducer.checkboxLayers);
    const selected_date = useSelector((state) => state.mapReducer.SelectedDate);
    const clickEventState = useSelector(
        (state) => state.mapReducer.clickEventCheck
    );
    const [SensorUrl, setSensorUrl] = useState(null);
    const [SensorName, setSensorName] = useState(null);
    const [modalOpenListNoteTask, setmodalOpenListNoteTask] = useState(false);
    const [modalOpenNoteTask, setmodalOpenNoteTask] = useState(false);
    const [modalOpenAnalysePanel, setmodalOpenAnalysePanel] = useState(false)
    const [activePaneAnalysePanel, setactivePaneAnalysePanel] = useState(0)
    let { id_task } = useParams();
    const taskName = useSelector((state) => state.mapReducer.taskNameDetails);
    const isCheckedCountCheckbox = useSelector((state) => state.mapReducer.isCheckedCountCheckbox)
    const treeCountArray = useSelector((state) => state.mapReducer.treeCountLocalIndicesArray)
    //count state

    const [detectedPlants, setDetectedPlants] = useState([]);
    const [LocalIndices, setLocalIndices] = useState([]);
    const [countValue, setCountValue] = useState(0);
    //
    useEffect(() => {
        dispatch(loadingBegin());
    }, []);
    // useEffect(() => {
    //     setTimeout(() => dispatch(loadingEnd())
    //         , 1000)
    // }, [])

    const handleCloseSnackBar = () => {
        setopensnack(false);
    };
    const handleOpenSnackBar = (msg, vert, horiz) => {
        setopensnack(true);
        setmessage(msg);
        setvertical(vert);
        sethorizontal(horiz);
    };

    useEffect(() => {
        if (!Map) return;

        const search = new GeoSearchControl({
            provider: new OpenStreetMapProvider(),
            style: "bar",
            showMarker: false,
            showPopup: true,
            retainZoomLevel: false,
            animateZoom: true,
            autoClose: true,
            searchLabel: "Enter address, please",
            keepResult: true,
            popupFormat: ({ query, result }) => result.label,
            position: "topright",
            notFoundMessage: "Sorry, that address could not be found.",
        });
        Map.addControl(search);
    }, [Map]);

    useEffect(() => {
        if (!Map) return;
        baseMaps["Base Map"] = baseLayer.current;
        // add baseMap ans sattelite to the LayerControl

        // add labels layer to the map without adding it to the layercontrol
        const ImageryLabels = new esri.BasemapLayer("ImageryLabels", {
            apikey: apiKey,
            portalUrl: "https://www.arcgis.com",
        }).addTo(Map);
        overlays["Satellite"].addTo(Map);
        setlabelsLayer(ImageryLabels);
        // const LayerControls = L.control
        //   .layers(undefined, undefined, {
        //     position: "topright",
        //     collapsed: false,
        //     sortLayers: true,
        //     sortFunction: function (a, b) {
        //       return a.options.optionTosort - b.options.optionTosort;
        //     },
        //   })
        //   .addTo(Map);
        // setLayerControl(LayerControls);
        // adding esri work imagery to the map and the layerControl
    }, [Map]);

  function layersToMap(layerName, layerData, index) {
    // if (layerControl) {
    //   const layers = layerControl._layers;
    //   const existingLayer = Object.values(layers).find(
    //     (layer) => layer.name === layerData.designation
    //   );
    //   if (!existingLayer) {
    //     const layer = L.tileLayer(layerData.url, {});
    //     if (layerName === "ndvi" || layerName === "rgb") {
    //       layer.addTo(Map).bringToFront();
    //     }
    //     layerControl.addOverlay(layer, layerData.designation, {
    //       optionTosort: index,
    //     });
    //   } else {
    //     Map.removeLayer(existingLayer.layer);
    //     layerControl.removeLayer(existingLayer.layer);
    //     const layer = L.tileLayer(layerData.url, {});
    //     if (layerName === "ndvi" || layerName === "rgb") {
    //       layer.addTo(Map).bringToFront();
    //     }
    //     layerControl.addOverlay(layer, layerData.designation, {
    //       optionTosort: index,
    //     });
    //   }
    // }
  }
  useEffect(() => {
    if (!VegetationLayersState || !Map) return;
    console.log("checkedLayer", checkedLayer);
    const layerList = ["rgb", "ndvi", "ndre", "ndwi", "gci", "psri", , "msi", "evi", "siwsi"];
    Map.eachLayer((layer) => {
      if (layerList.includes(layer.options.checkedLayer)) {
        Map.removeLayer(layer);
      }
    });
    if (checkedLayer !== null) {
      const layer = L.tileLayer(VegetationLayersState[checkedLayer].url, {
        checkedLayer,
      });
      layer.addTo(Map).bringToFront();
    }
  }, [checkedLayer, VegetationLayersState]);

    useEffect(() => {
        if (!treeCountArray || !Map) return

        console.log(isCheckedCountCheckbox)
        if (isCheckedCountCheckbox === 1) {
            const group = L.layerGroup()
            treeCountArray.map(item => {

                console.log('cord', [item.lat, item.lon])
                const treeMarker = L.circleMarker([item.lat, item.lon],
                    {
                        key: item.maxx,
                        radius: (item.maxx - item.minx) / 2,
                        color: item.mean_ndvi > 0.3 ? "team" : "orange",
                        name: "countMarkers"
                    }).bindPopup(renderToString(<PopupContent data={item} />))
                treeMarker.addTo(group);
            })
            group.addTo(Map)
        } else {
            Map.eachLayer((layer) => {
                if (layer.options.name === "countMarkers") {
                    Map.removeLayer(layer);
                }
            });
            console.log(isCheckedCountCheckbox)

            console.log('has',)
            // Map.removeLayer(group)



        }
        console.log('Map', Map)
    }, [treeCountArray, isCheckedCountCheckbox])


    useEffect(() => {
        if (!Map || !flyToCenter) return;
        Map.flyTo(flyToCenter, floToZoom, {
            duration: 4,
        });
    }, [flyToCenter]);
    // useEffect(() => {
    //     if (!Map) return;
    //     // dispatch(loadingBegin())
    //     dispatch(get_dates(id_task)).then((res_date) => {
    //         dispatch(change_accusation_dates(res_date.data.data["dates"]))
    //     })
    // }, [Map])
    // copie cordonnées gps to clipboard
    useEffect(() => {
        if (!Map) return;
        const MarkerImage = require("../../assets/images/marker.png");
        const MarkerIcon = L.icon({
            iconUrl: MarkerImage,
            iconSize: [27, 40],
            iconAnchor: [13.5, 40],
        });
        const popupOptions = {
            closeButton: false,
            autoClose: true,
            className: "cord-popup-copie",
        };
        // const popup = L.popup(popupOptions);
        const marker = L.marker();
        const popup = L.popup(popupOptions);

        marker.setIcon(MarkerIcon);
        if (clickEventState) {
            Map.addEventListener("click", (e) => {
                const { lat, lng } = e.latlng;
                if (lat && lng) {
                    const position = L.latLng(lat, lng);
                    const popupContent = `${lat.toFixed(4)},${lng.toFixed(4)}`;

                    marker.setLatLng(position).addTo(Map);
                    popup
                        .setLatLng(position)
                        .setContent(
                            '<div className="sensor-popup">' + popupContent + "</div>"
                        )
                        .addTo(Map);
                    navigator.clipboard
                        .writeText(popupContent)
                        .then(() => {
                            handleOpenSnackBar("copied to clipboard !", "bottom", "center");
                        })
                        .catch((error) => { });
                    setTimeout(function () {
                        Map.removeLayer(popup);
                    }, 2000);
                }
            });
        } else {
            Map.removeEventListener("click");
        }

        return () => {
            Map.removeEventListener("click");
            Map.removeLayer(marker);
            Map.removeLayer(popup);
        };
    }, [Map, clickEventState]);

    // end
    useEffect(() => {
        dispatch(loadingBegin());
        dispatch(Detail_Task_initial(id_task)).then((res) => {
            const center = [res.center[1].toFixed(3), res.center[0].toFixed(3)];
            setfloToZoom(res.zoom);
            setflyToCenter(center);
            setprojectName(res.project.project_name);
            dispatch(Set_detail_from_data(res.task));
            dispatch(change_selected_date_initial(res.last_date));
            if (res.sensors) {
                res.sensors.map((sensor) => {
                    setSensorName(sensor["sensor_name"] || "");
                    setSensorUrl(sensor["url"] || "");
                });
            } else {
                setSensorName(null);
                setSensorUrl(null);
            }
            dispatch(get_task_tree_count(id_task)).then((res) => {
                if (res.status === 200) {
                    setLocalIndices(res.data.data.local_index.result);
                    setCountValue(res.data.data.region_count["count"]);
                }
            });
            dispatch(loadingEnd());
        });
    }, []);

    useEffect(() => {
        if (!Map || !SensorName || !SensorUrl) return;
        getSonsorData(SensorUrl, SensorName);
    }, [Map, SensorName]);

    useEffect(() => {
        if (!selected_date) return;
        dispatch(loadingBegin());
        dispatch(Detail_Task(id_task, selected_date.date, selected_date.provider)).then((res) => {
            dispatch(loadingEnd());
        });
    }, [selected_date]);

    useEffect(() => {
        if (!Map) return;
        Map.on("enterFullscreen", function () {
            $(".area-div").css("top", "0px");
            $(".leaflet-control-geosearch.leaflet-geosearch-bar").css("top", "0px");
            $(".leaflet-top.leaflet-right").css("top", "50px");
            //   $(".leaflet-top .leaflet-control").css("top", "30px");
            $(".sidebar").css("top", "1%");
            $(".sidebar").css("max-height", "98%");
            $("#sidebar-toogle-button-wrapper").css("top", "10px");
            $(".Btn-note").css("top", "50px");
        });

        Map.on("exitFullscreen", function () {
            $(".area-div").css("top", "90px");
            $(".leaflet-control-geosearch.leaflet-geosearch-bar").css("top", "60px");
            $(".leaflet-top.leaflet-right").css("top", "110px");
            $(".sidebar").css("top", "70px");
            $(".sidebar").css("max-height", "calc(100vh - 17vh)");
            $("#sidebar-toogle-button-wrapper").css("top", "70px");
            $(".Btn-note").css("top", "110px");
        });
    }, [Map]);

    useEffect(() => {
        if (!Map) return;
        if (clickEventState === false) {
            Map.scrollWheelZoom.disable();
            Map.dragging.disable();
            Map.touchZoom.disable();
            Map.doubleClickZoom.disable();
            Map.boxZoom.disable();
            Map.keyboard.disable();
            if (Map.tap) Map.tap.disable();
        } else {
            Map.scrollWheelZoom.enable();
            Map.dragging.enable();
            Map.touchZoom.enable();
            Map.doubleClickZoom.enable();
            Map.boxZoom.enable();
            Map.keyboard.enable();
            if (Map.tap) Map.tap.enable();
        }
        console.log('clickEventState', clickEventState)
    }, [Map, clickEventState]);

    const sensorImage = require("../../assets/images/sensor.png");
    const Sensor = L.icon({
        iconUrl: sensorImage,
        iconSize: [37, 50],
    });

    const getSonsorData = (url, sensor_name) => {
        dispatch(Sensor_data(url)).then((res) => {
            const response = res.data.allDataSensor[0];
            if (res.status == 201) {
                L.marker([response["latitude"], response["longitude"]], {
                    icon: Sensor,
                })
                    .addTo(Map)
                    .bindPopup(
                        '<div className="sensor-popup"><div className="col-md-12"><b>' +
                        sensor_name +
                        "</b></div><br>" +
                        '<div className="col-md-12">Time: <b>' +
                        response["time"] +
                        "</b></div>" +
                        '<div className="col-md-12">Temperature: <b>' +
                        response["temperature"] +
                        " °C</b></div>" +
                        '<div className="col-md-12">Humidity: <b>' +
                        response["humidity"] +
                        " %</b></div>" +
                        '<div className="col-md-12">Pressure: <b>' +
                        response["pressure"] +
                        " kPa</b></div>" +
                        '<div className="col-md-12">Altitude: <b>' +
                        response["altitude"] +
                        " M</b></div>" +
                        '<div className="col-md-12">Ground temperature: <b>' +
                        response["ts"] +
                        " °C</b></div>" +
                        '<div className="col-md-12">Humidity (20 cm depth): <b>' +
                        response["mv1"] +
                        " %</b></div>" +
                        '<div className="col-md-12">Humidity (40 cm depth): <b>' +
                        response["mv2"] +
                        " %</b></div>" +
                        '<div className="col-md-12">Humidity (60 cm depth): <b>' +
                        response["mv3"] +
                        " %</b></div>" +
                        '<div className="col-md-12">Evapotranspiration: <b>' +
                        response["et0"] +
                        "</b></div>" +
                        '<div className="col-md-12">Rain: <b>' +
                        response["rain"] +
                        "</b></div>" +
                        '<div className="col-md-12">Solar radiation: <b>' +
                        response["radiation"] +
                        " W/m²</b></div>" +
                        "</div>"
                    );
            }
        });
    };
    const handleOpenListNotekModal = () => {
        setmodalOpenListNoteTask(true);
    };
    const handleCloseListNoteModal = () => {
        setmodalOpenListNoteTask(false);
    };

    const handleOpenNotekModal = () => {
        handleCloseListNoteModal();
        setmodalOpenNoteTask(true);
    };
    const handleCloseNoteModal = () => {
        setmodalOpenNoteTask(false);
    };
    const handleOpenAnalysePanel = (activePane) => {
        handleCloseListNoteModal();
        setmodalOpenAnalysePanel(true);
        setactivePaneAnalysePanel(activePane)
    };
    const handleCloseAnalysePanel = () => {
        setmodalOpenAnalysePanel(false);
    };
    return (
        <div
            className="col-md-12 mod-task-details"
            style={{ height: "calc(100vh)", padding: "0px" }}
        >
            <NavBar />
            <Loading />
            <div style={{ height: "100%", padding: "0px", position: "relative" }}>
                <MapContainer
                    ref={setMap}
                    style={style}
                    center={mapParams.center}
                    zoom={mapParams.zoom}
                    minZoom={0}
                    zoomControl={false}
                    maxZoom={mapParams.maxZoom}
                    id="mapContainer"
                >
                    <TileLayer
                        ref={baseLayer}
                        attribution='&copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors'
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                        minZoom={0}
                        maxZoom={22}
                    />
                    <TileLayer
                        ref={baseLayer}
                        attribution='&copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors'
                        url="https://www.arcgis.com"
                        minZoom={0}
                        maxZoom={22}
                    />
                    <ZoomControl position="bottomright" />
                    <ScaleControl position="bottomleft" />
                    <FullscreenControl position="bottomright" />
                    {/* <Surface surface={surface} name={taskName} /> */}
                    {/* {<DatesSlider id_task={id_task} />} */}
                    <SnackBarCopieToClipboard
                        open={opensnack}
                        message={message}
                        autoHide={1000}
                        vertical={vertical}
                        horizontal={horizontal}
                        handleClose={handleCloseSnackBar}
                    />
                    <NoteBtn openModal={handleOpenListNotekModal} />
                    <NoteListModal
                        open={modalOpenListNoteTask}
                        handleClose={handleCloseListNoteModal}
                        handleNoteModal={handleOpenNotekModal}
                        idTask={id_task}
                    />
                    <NoteModal
                        open={modalOpenNoteTask}
                        handleClose={handleCloseNoteModal}
                        idTask={id_task}
                    />
                    <MainModal
                        open={modalOpenAnalysePanel}
                        handleClose={handleCloseAnalysePanel}
                        idTask={id_task}
                        activePane={activePaneAnalysePanel}
                    />
                    {/* <SideBar vegetation={VegetationLayersState} treeCount={countValue} /> */}
                    <Sidebar openModal={handleOpenAnalysePanel} />
                    <SidebarBottom />
                    <SidebarToggleButton />
                    <div className="weather-card d-none d-md-block" >
                        <WeatherBlock openModal={handleOpenAnalysePanel} />
                    </div>
                    {/* {taskName === "nizar" ?
                        <LayersControl>
                            <Overlay checked name="Tree markers">
                                <LayerGroup>
                                    {detectedPlants.map(plant => (
                                        <CircleMarker
                                            key={plant.maxx}
                                            center={[plant.lat, plant.lon]}
                                            radius={(plant.maxx - plant.minx) / 2}
                                            color="gray"
                                        >
                                            <Popup>
                                                <span><b>Plant NDVI:</b> calculating...</span><br />
                                                <span><b>Plant NDRE:</b> calculating...</span><br />
                                                <span><b>Plant GCI:</b> calculating...</span><br />
                                                <span><b>Plant NDWI:</b> calculating...</span>
                                            </Popup>
                                        </CircleMarker>
                                    ))
                                    }

                                    {localIndices.map(localIndex => (
                                        <CircleMarker
                                            key={localIndex.maxx}
                                            center={[localIndex.lat, localIndex.lon]}
                                            radius={(localIndex.maxx - localIndex.minx) / 2}
                                            color={localIndex.mean_ndvi > 0.3 ? "teal" : "orange"}
                                        >
                                            <Popup>
                                                <span><b>Plant NDVI:</b></span> {localIndex.mean_ndvi.toFixed(2)}<br />
                                                <span><b>Plant NDRE:</b></span> {localIndex.mean_ndre.toFixed(2)}<br />
                                                <span><b>Plant GCI:</b></span> {localIndex.mean_gci.toFixed(2)}<br />
                                                <span><b>Plant NDWI:</b></span> {localIndex.mean_ndwi.toFixed(2)}
                                            </Popup>
                                        </CircleMarker>

                                    ))
                                    }
                                </LayerGroup>
                            </Overlay>
                        </LayersControl>
                        : ""
                    } */}
                </MapContainer>
            </div>
        </div>

    );
};
export default DetailsTask;
