import React, { useState } from "react";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import "../../assets/scss/mediacard.scss";
import DeleteIcon from "@mui/icons-material/Delete";
import InfoIcon from "@mui/icons-material/Info";
import { useNavigate } from "react-router-dom";
import DeleteProject from "../task/delete";
import { useTranslation } from "react-i18next";
import { CardMedia } from "@mui/material";
import defaultImage from '../../assets/images/default.jpg'

export default function ImgMediaCard({ element }) {
    const navigate = useNavigate();
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const [taskId, setTaskId] = useState(null);
    const { t } = useTranslation();
    const [imgUrl, setimgUrl] = useState(null);
    const handleDetailField = (taskId) => {
        // navigate(`/task/detail/${taskId}`);
        navigate(`/task/detail/${taskId}`);
    };

    const handleOpenDeleteModal = (taskId) => {
        setTaskId(taskId);
        setDeleteModalOpen(true);
    };

    const handleCloseDeleteModal = () => {
        setDeleteModalOpen(false);
    };

    return (
        <div className="mod-media-card my-2 py-0" key={element.id}>
            <Card key={element.id}>
                <a className="link task-card-img" href={`/task/detail/${element.id}`}>
                    <div className="content-overlay"></div>

                    <img
                        src={process.env.REACT_APP_API_URL + '/media/covers/' + element.id + '.png'}
                        className="w-100"
                        alt={element.task_name}

                        onError={({ currentTarget }) => {
                            currentTarget.onerror = null; // prevents looping
                            currentTarget.src = defaultImage;
                        }}
                    />
                    <div className="content-details fadeIn-bottom">
                        <h3>{element.task_name} {element.id}</h3>
                        <p>{t("View Map")}</p>
                    </div>
                </a>
                <CardContent className="m-content">
                    <a className="link" href={`/task/detail/${element.id}`}>
                        <Typography
                            gutterBottom
                            variant="h5"
                            className="m-headline task-link"
                            component="div"
                        >
                            {element.task_name}
                        </Typography>
                    </a>
                    <div variant="body2" color="text.secondary">
                        <p className="m-description">
                            <b className="m-title">{t("Surface")}</b>
                            <span> {element.surface} </span>
                        </p>
                        <p className="m-description">
                            <b className="m-title">{t("Culture")} </b>
                            <span> {element["culture"] && element["culture"].name_fr} </span>
                        </p>
                        <p className="m-description">
                            <b className="m-title">{t("Density")}</b>
                            <span>{element["density"] && element["density"].name_fr} </span>
                        </p>
                        <p className="m-description">
                            <b className="m-title">{t("Variety")}</b>
                            <span> {element["variety"] && element["variety"].name_fr} </span>
                        </p>
                    </div>
                </CardContent>
                <CardActions>
                    <Button
                        size="small"
                        startIcon={<DeleteIcon />}
                        color="error"
                        className="text-capitalize fw-bold"
                        onClick={() => handleOpenDeleteModal(element.id)}
                    >
                        {t("Delete")}
                    </Button>
                    <a href={`/task/detail/${element.id}`}>
                        <Button d-flex justify-content-between align-item-center
                            size="small"
                            startIcon={<InfoIcon />}
                            className="text-capitalize fw-bold"
                        // onClick={() => handleDetailField(element.id)}
                        >
                            {t("View details")}
                        </Button>
                    </a>
                </CardActions>

                <DeleteProject
                    open={deleteModalOpen}
                    id_task={element.id}
                    handleClose={handleCloseDeleteModal}
                />
            </Card>
        </div >
    );
}
