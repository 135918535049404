import React, { useState, useEffect, useRef } from "react";
import { Grid, Box, Select, MenuItem, TextField, FormControl, InputLabel, Stack, Chip, Slider, Typography } from '@mui/material';
import { useDispatch, useSelector, } from "react-redux";
import { useTranslation } from 'react-i18next';
import cookies from 'js-cookie';
import { getCulture, getDensity, getVariety } from "../../actions/project.js";
import { getFiltredTasks } from "../../actions/compare.js";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckIcon from "@mui/icons-material/Check";
import BasicDatePicker from "../../utils/BasicDatePicker.js";
import dayjs from "dayjs";
import LoadingButton from "@mui/lab/LoadingButton";
import SaveIcon from "@mui/icons-material/Save";
import { getFiltredTasksGraphData, clearTaskDataGraph } from "../../actions/compare.js";
import { showSnackbar } from "../../actions/snackbar";
import Graph from './graph.js'
import { handleFilterData, handleFilterDataByCloudPercentage, findMinMaxValues } from '../../utils/graphDataFilter.js'

const Compare = () => {

    const [listCulture, setlistCulture] = useState(null)
    const [listVariety, setlistVariety] = useState(null)
    const [listDensity, setlistDensity] = useState(null)
    const [culture, setculture] = useState(null)
    const [variety, setvariety] = useState(null)
    const [density, setdensity] = useState(null)
    const [age, setage] = useState(null)
    const [isAgeValid, setIsAgeValid] = useState(false);
    const [listSelectedTasks, setlistSelectedTasks] = useState([])
    const [listTasks, setlistTasks] = useState([])
    const [indexText, setindexText] = useState("Vegetation Index")

    const [loading, setloading] = useState(false)
    const user = useSelector(state => state.auth.user);
    const graphdata = useSelector((state) => state.graphReducer.graph_data_compare);
    const [datagraph, setdatagraph] = useState(null)
    const today = dayjs();

    const [formData, setFormData] = useState({
        index: "ndvi",
        StartDate: today.subtract(1, 'year'),
        EndDate: today,
        cloud_percentage: 40
    });

    const marks = [
        {
            value: 10,
            label: '10 %',
        },
        {
            value: 20,
            label: '20 %',
        },
        {
            value: 30,
            label: '30 %',
        },
        {
            value: 40,
            label: '40 %',
        },
    ];
    const onChangeIndex = (e) => {
        setFormData({ ...formData, index: e.target.value });
        switch (e.target.value) {
            case "ndvi":
                setindexText("Vegetation Index")
                break
            case "ndre":
                setindexText("Nitrogen Index")
                break
            case "ndwi":
                setindexText("Irrigation index")
                break
            case "gci":
                setindexText("Plant Health Index")
                break
            case "psri":
                setindexText("Plant Senescence Index")
                break
            default:
                setindexText("Vegetation Index")
        }
    };
    const onChangeSlider = (e, value) => {
        setFormData({ ...formData, cloud_percentage: value });

    };
    const onChangeCulture = (e) => {
        setculture(e.target.value)
        setlistSelectedTasks([])
    }
    const onChangeVariety = (e) => {
        setvariety(e.target.value)
        setlistSelectedTasks([])

    }
    const onChangeDensity = (e) => {
        setdensity(e.target.value)
        setlistSelectedTasks([])

    }
    const onChangeAge = (e) => {
        setage(e.target.value)
        setlistSelectedTasks([])

    }
    const { StartDate, EndDate, index, cloud_percentage } = formData;
    const onChange = e => setFormData({ ...formData, [e.target.name]: e.target.value });
    const { t } = useTranslation();
    const currentLanguageCode = cookies.get('i18next') || 'en';

    const dispatch = useDispatch();

    useEffect(() => {
        if (!graphdata) {
            setdatagraph(null)
            return
        }
        console.log('graphdata aaa', graphdata)
        let dataFiltred = []
        let dataFiltredByCloudPercentage = []
        console.log('startDate', StartDate)
        console.log('endDate', EndDate)
        console.log('cloud_percentage', cloud_percentage)
        setdatagraph(null)
        dataFiltred = graphdata.map(element => {
            // Check if the element has a 'data' property before applying the filter
            if (element.hasOwnProperty('data')) {
                // Apply the filter to the 'data' array of the current element
                const filteredData = handleFilterData(element.data, StartDate, EndDate);

                // Create a new object with the filtered 'data' array
                return {
                    ...element,
                    data: filteredData,
                };
            }
        })
        dataFiltredByCloudPercentage = dataFiltred.map(element => {
            // Check if the element has a 'data' property before applying the filter
            if (element.hasOwnProperty('data')) {
                // Apply the filter to the 'data' array of the current element
                const filteredData = handleFilterDataByCloudPercentage(element.data, cloud_percentage);

                // Create a new object with the filtered 'data' array
                return {
                    ...element,
                    data: filteredData,
                };
            }
        })
        console.log('use effect active')
        console.log('dataFiltredByCloudPercentage', dataFiltredByCloudPercentage)
        setdatagraph(dataFiltredByCloudPercentage)
    }, [graphdata])

    useEffect(() => {
        setFormData({
            ...formData,
            StartDate: today.subtract(1, 'year'),
            EndDate: today,
            cloud_percentage: 40
        });

    }, []);

    const onSubmit = e => {
        e.preventDefault();
        // let isValid = true;
        // setIsAgeValid(false);
        // if (age <= 0) {
        //     setIsAgeValid(true);
        //     isValid = false;
        // }
        dispatch(getFiltredTasksGraphData(listSelectedTasks, index)).then((res) => {
            // console.log('res', res)
            if (res.status !== 200) {
                console.log('res.status', res.status)
                res.data.tasks_without_graph_data.map((item) => {
                    // console.log('item', item)
                    dispatch(showSnackbar("error", undefined, res.data.message + ": " + item, "top", "right"))
                })
                dispatch(clearTaskDataGraph()).then((res) => {
                    // console.log('cleared')
                    // console.log('graphdata', graphdata)
                })
            }
            // console.log('res.data', res.data)
        })
    }

    useEffect(() => {
        dispatch(getCulture()).then((res) => {
            setlistCulture(res.data)
        })
    }, [])

    useEffect(() => {
        if (!culture) {
            setlistVariety(null)
            setlistDensity(null)
            return;
        }
        dispatch(getVariety(culture)).then((res) => {
            setlistVariety(res.data)
        })
        dispatch(getDensity(culture)).then((res) => {
            setlistDensity(res.data)
        })
    }, [culture])

    useEffect(() => {
        if (!culture) return;
        dispatch(getFiltredTasks(culture, variety, density, age)).then((res) => {
            // console.log('res', res)
            setlistTasks(res)
        })
    }, [culture, variety, density, age, user])

    const handleChangeTaskSelect = (e) => {
        // console.log('event', e)
        const val = e.target.value
        // console.log('value', val)
        setlistSelectedTasks(val)
    }

    const renderSelectGroup = task => {
        return (
            <MenuItem key={task.id} value={task.id} sx={{ justifyContent: "space-between" }}>
                {task.task_name}
                {listSelectedTasks.includes(task.id) ? <CheckIcon color="info" /> : null}
            </MenuItem>
        );
    };
    function valuetext(value) {
        return `${value} %`;
    }
    return (
        <Box sx={{ flexGrow: 1, bgcolor: 'AppBarBackground.main', mt: 3, p: 5 }} >
            <form onSubmit={e => onSubmit(e)}>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <FormControl required sx={{ minWidth: "100%" }} size="small">
                            <InputLabel id="demo-select-small-label">{t("Culture")}</InputLabel>
                            <Select
                                labelId="demo-select-small-label"
                                id="demo-select-small"
                                value={culture}
                                label="Culture"
                                fullWidth
                                onChange={e => onChangeCulture(e)}
                                name='culture'
                                required
                            >
                                {listCulture && listCulture.length > 0 && listCulture.map((item) => (
                                    <MenuItem key={item.id} value={item.id}>
                                        {item[`name_${currentLanguageCode.toLowerCase()}`]}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                        <FormControl sx={{ minWidth: "100%" }} size="small">
                            <InputLabel id="demo-select-small-label">{t("Variety")}</InputLabel>
                            <Select
                                labelId="demo-select-small-label"
                                id="demo-select-small"
                                value={variety}
                                label="Variety"
                                fullWidth
                                onChange={e => onChangeVariety(e)}
                                name='variety'
                            >
                                <MenuItem value="">
                                    <em>None</em>
                                </MenuItem>
                                {listVariety && listVariety.length > 0 && listVariety.map((item) => (
                                    <MenuItem key={item.id} value={item.id}>
                                        {item[`name_${currentLanguageCode.toLowerCase()}`]}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                        <FormControl sx={{ minWidth: "100%" }} size="small">
                            <InputLabel id="demo-select-small-label">{t("Density")}</InputLabel>
                            <Select
                                labelId="demo-select-small-label"
                                id="demo-select-small"
                                value={density}
                                label="density"
                                fullWidth
                                onChange={e => onChangeDensity(e)}
                                name='density'
                            >
                                <MenuItem value="">
                                    <em>None</em>
                                </MenuItem>
                                {listDensity && listDensity.length > 0 && listDensity.map((item) => (
                                    <MenuItem key={item.id} value={item.id}>
                                        {item[`name_${currentLanguageCode.toLowerCase()}`]}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                        <FormControl sx={{ minWidth: "100%" }} size="small">
                            <TextField
                                fullWidth
                                id="outlined-name-input"
                                label={t("Age")}
                                type="number"
                                name="age"
                                size="small"
                                value={age}
                                onChange={e => onChangeAge(e)}
                            // error={isAgeValid}
                            // helperText={isAgeValid ? t("Please enter a valid age") : ""}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <FormControl fullWidth variant="outlined" sx={{ minWidth: "100%" }} size="small">
                            <InputLabel id="task-label">Select Tasks</InputLabel>
                            <Select
                                label="Select Tasks"
                                name="tasks"
                                labelId="task-label"
                                multiple
                                required
                                id="task-select-helper"
                                value={listSelectedTasks}
                                onChange={(e) => handleChangeTaskSelect(e)}
                                // input={<OutlinedInput label="Select Tasks" />}
                                renderValue={(selected) => (
                                    <Stack gap={1} direction="row" flexWrap="wrap">
                                        {selected.map((value) => {
                                            let foundTask = null;
                                            listTasks.forEach((task) => {
                                                const selectedTask = listTasks.find((task) => task.id === String(value));
                                                if (selectedTask) {
                                                    foundTask = selectedTask;
                                                }
                                            });
                                            return (
                                                <Chip
                                                    key={value}
                                                    label={foundTask ? foundTask.task_name : ''}
                                                    onDelete={() =>
                                                        setlistSelectedTasks(
                                                            listSelectedTasks.filter((item) => item !== value)
                                                        )
                                                    }
                                                    deleteIcon={
                                                        <CancelIcon
                                                            onMouseDown={(event) => event.stopPropagation()}
                                                        />
                                                    }
                                                />
                                            );
                                        })}
                                    </Stack>
                                )}
                            >
                                {listTasks && listTasks.map(item => renderSelectGroup(item))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                        <BasicDatePicker
                            disabled={loading}
                            className="graph_date_input"
                            label={t("start date")}
                            value={StartDate}
                            name={"StartDate"}
                            maxDate={today}
                            minDate={dayjs("2017-04-01")}
                            onChange={(e) => onChange(e)}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <BasicDatePicker
                            disabled={loading}
                            className="graph_date_input"
                            label={t("end date")}
                            maxDate={today}
                            value={EndDate}
                            name={"EndDate"}
                            minDate={dayjs("2017-04-01")}
                            onChange={(e) => onChange(e)}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <FormControl
                            sx={{ mt: 1, mb: 1 }}
                            size="small"
                            className="m-select col-sm-12 "
                        >
                            <InputLabel id="demo-select-index-label">{t("Index")}</InputLabel>
                            <Select
                                disabled={loading}
                                labelId="demo-select-index-label"
                                id="demo-select-index"
                                label="Index"
                                fullWidth
                                onChange={(e) => onChangeIndex(e)}
                                name="Index"
                                defaultValue={"ndvi"}
                                value={index}
                            >
                                <MenuItem value="ndvi">{t("Vegetation Index")}</MenuItem>
                                <MenuItem value="ndwi">{t("Irrigation index")}</MenuItem>
                                <MenuItem value="ndre">{t("Nitrogen Index")}</MenuItem>
                                <MenuItem value="gci">{t("Plant Health Index")}</MenuItem>
                                <MenuItem value="psri">{t("Plant Senescence Index")}</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item container xs={6} spacing={2}>
                        <Grid item xs={12} lg={3} container sx={{ alignItems: 'center' }}>
                            <Typography id="input-slider" gutterBottom>
                                Cloud percentage
                            </Typography>
                        </Grid>
                        <Grid item xs={12} lg={9}>

                            <FormControl
                                sx={{ mt: 1, mb: 1 }}
                                size="small"
                                className="m-select col-sm-12 w-100"
                            >
                                <Slider aria-label="Temperature"
                                    defaultValue={40}
                                    getAriaValueText={valuetext}
                                    valueLabelDisplay="on"
                                    marks={marks}
                                    min={10}
                                    max={40}
                                    step={10}
                                    disabled={loading}
                                    name="cloud_percentage"
                                    value={cloud_percentage}
                                    onChange={onChangeSlider}

                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} container sx={{ justifyContent: 'center' }}>
                        <LoadingButton
                            size="small"
                            color="primary"
                            type="submit"
                            loading={loading}
                            loadingPosition="start"
                            startIcon={<SaveIcon />}
                            variant="contained"
                        >
                            <span>{t("Process")}</span>
                        </LoadingButton>
                    </Grid>
                </Grid>

            </form>
            <Grid>
                {datagraph && <Graph vegetationIndex={index} data={datagraph} />}
            </Grid>
        </Box>
    )
}

export default Compare