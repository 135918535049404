import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import App from './App'
import { PersistGate } from "redux-persist/integration/react"
import { persistStore } from "redux-persist"
import store from './store';
import i18next from 'i18next'
import { initReactI18next } from 'react-i18next'
import HttpApi from 'i18next-http-backend'
import LanguageDetector from 'i18next-browser-languagedetector'
// import 'bootstrap/dist/js/bootstrap.js'
// import 'bootstrap/dist/css/bootstrap.min.css'
// import 'flag-icon-css/css/flag-icons.min.css'
// import LoadingData from './utils/loadingdata';
i18next
  .use(HttpApi)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    supportedLngs: ['en', 'ar', 'fr'],
    fallbackLng: 'en',
    debug: false,
    // Options for language detector
    detection: {
      order: ['path', 'cookie', 'htmlTag'],
      caches: ['cookie'],
    },
    // react: { useSuspense: false },
    backend: {
      loadPath: `${process.env.REACT_APP_TRANSLATION_URL}/{{lng}}/translation.json`,
    },
  })
const loadingMarkup = (
  <div className='loading active'></div>
)
const root = ReactDOM.createRoot(document.getElementById('root'));
let persistor = persistStore(store)
root.render(
  <Suspense fallback={loadingMarkup}>
    <React.StrictMode>
      <PersistGate persistor={persistor}>
        <App />
      </PersistGate>
    </React.StrictMode>
  </Suspense>,
);