import React, { useEffect } from "react";
import { checkAuthenticated, load_user } from "../actions/auth";
import {connect} from 'react-redux'

const Layout = ({ checkAuthenticated, load_user, children }) => {
    useEffect(() => {
        checkAuthenticated();
        load_user();
    }, [])
    return (
        <div>
             {/* <Navbar />  */}
            {children}
        </div>
    );
}

export default connect(null, { checkAuthenticated, load_user })(Layout);