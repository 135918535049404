import axios from 'axios';
import {
    CONST_LOGIN_SUCCESS,
    CONST_LOGIN_FAIL,
    LOAD_USER_SUCCESS,
    LOAD_USER_FAIL,
    AYTHENTICATED_SUCCESS,
    AYTHENTICATED_FAIL,
    LOGOUT,
    PASSWORD_RESET_FAIL,
    PASSWORD_RESET_SUCCESS,
    PASSWORD_RESET_CONFIRM_FAIL,
    PASSWORD_RESET_CONFIRM_SUCCESS,
    SIGNUP_SUCCESS,
    SIGNUP_FAIL,
    ACTIVATION_SUCCESS,
    ACTIVATION_FAIL,
    SELECTED_PROJECT_FAIL,
} from '../utils/types';


export const checkAuthenticated = () => async dispatch => {
    if (localStorage.getItem('access')) {
        const config = {
            headers: {
                'Content-type': 'application/json',
                'Accept': 'application/json'
            }
        };
        const body = JSON.stringify({ token: localStorage.getItem('access') })
        try {
            const res = await axios.post(`${process.env.REACT_APP_API_URL}/auth/jwt/verify/`, body, config)

            if (res.data.code !== "token_not_valid") {
                dispatch({
                    type: AYTHENTICATED_SUCCESS
                })
            }
        } catch (err) {
            dispatch({
                type: AYTHENTICATED_FAIL
            })
        }
    } else {
        dispatch({
            type: AYTHENTICATED_FAIL
        })
    }
}
export const load_user = (email, password) => async dispatch => {
    if (localStorage.getItem('access')) {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `JWT ${localStorage.getItem('access')}`,
                'Accept': 'application/json'
            }
        };
        try {
            const res = await axios.get(`${process.env.REACT_APP_API_URL}/auth/users/me/`, config);

            dispatch({
                type: LOAD_USER_SUCCESS,
                payload: res.data
            })
        } catch (err) {
            dispatch({
                type: LOAD_USER_FAIL
            })
        }
    } else {
        dispatch({
            type: LOAD_USER_FAIL
        })
    }
};

export const login = (email, password) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    const body = JSON.stringify({ email, password });

    try {
        const res = await axios.post(`${process.env.REACT_APP_API_URL}/auth/jwt/create/`, body, config);
        dispatch({
            type: CONST_LOGIN_SUCCESS,
            payload: res.data
        })
        // dispatch(load_user());
        dispatch({ type: SELECTED_PROJECT_FAIL })
        return res

    } catch (err) {
        dispatch({
            type: CONST_LOGIN_FAIL
        })
        return err
    }
}

export const signup = (firstname, lastname, company, phone, email, password, re_password) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    const body = JSON.stringify({ firstname, lastname, company, phone, email, password, re_password });
    try {
        const res = await axios.post(`${process.env.REACT_APP_API_URL}/auth/users/`, body, config);

        dispatch({
            type: SIGNUP_SUCCESS,
            payload: res.data
        })
        dispatch(load_user());
        return res
    } catch (err) {
        dispatch({
            type: SIGNUP_FAIL
        })
        return err
    }
}

export const verify = (uid, token) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    const body = JSON.stringify({ uid, token });
    try {
        await axios.post(`${process.env.REACT_APP_API_URL}/auth/users/activation/`, body, config);

        dispatch({
            type: ACTIVATION_SUCCESS,
        })
        dispatch(load_user());
    } catch (err) {
        dispatch({
            type: ACTIVATION_FAIL
        })
    }
}


export const check_exist_email = (email) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    try {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/auth/user/check-email/${email}/`, config)
        return res
    } catch (err) {
        return err
    }
}

export const reset_password = (email) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    const body = JSON.stringify({ email })
    try {
        await axios.post(`${process.env.REACT_APP_API_URL}/auth/users/reset_password/`, body, config)
        dispatch({
            type: PASSWORD_RESET_SUCCESS
        })
    } catch (err) {
        dispatch({
            type: PASSWORD_RESET_FAIL
        })
    }
}

export const reset_password_confirm = (uid, token, new_password, re_new_password) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    const body = JSON.stringify({ uid, token, new_password, re_new_password })
    try {
        await axios.post(`${process.env.REACT_APP_API_URL}/auth/users/reset_password_confirm/`, body, config)
        dispatch({
            type: PASSWORD_RESET_CONFIRM_SUCCESS
        })
    } catch (err) {
        dispatch({
            type: PASSWORD_RESET_CONFIRM_FAIL
        })
    }
}
export const logout = () => async dispatch => {

    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'JWT ' + localStorage.getItem('access')
        }
    }
    try {
        await axios.get(`${process.env.REACT_APP_API_URL}/auth/signout/`, config)
        dispatch({
            type: LOGOUT
        })
    } catch (err) {
        dispatch({
            type: LOGOUT
        })
    }

    // dispatch({
    //     type: LOGOUT
    // });
}

export const ResetPasswordFail = () => {
    return {
        type: PASSWORD_RESET_FAIL
    };
}