
import axios from 'axios';
import {
    DETAIL_TASK_SUCESS,
    DETAIL_TASK_FAIL,
    ADD_EVENT_SUCCESS,
    ADD_EVENT_FAIL,
    INSECT_LIST_FAIL,
    INSECT_LIST_SUCESS,
    PRODUCT_LIST_FAIL,
    PRODUCT_LIST_SUCCESS,
    DELETE_EVENT_SUCCESS,
    DELETE_EVENT_FAIL
} from "../utils/types.js";

export const Detail_Task_Simple = (id) => async dispatch => {

    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    try {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/task/details-simple/${id}/`, config);

        dispatch({
            type: DETAIL_TASK_SUCESS,
            payload: res.data.data.data
        })
        return res.data.data
    } catch (err) {
        dispatch({
            type: DETAIL_TASK_FAIL
        })
        return err
    }
}

export const Task_Events = (id) => async dispatch => {

    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    try {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/events/${id}/`, config);

        dispatch({
            type: DETAIL_TASK_SUCESS,
            payload: res.data.data.data
        })
        return res.data.data
    } catch (err) {
        dispatch({
            type: DETAIL_TASK_FAIL
        })
        return err
    }
}
export const Task_User_Events = (id) => async dispatch => {

    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    try {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/events/user/${id}/`, config);

        dispatch({
            type: DETAIL_TASK_SUCESS,
            payload: res.data.data.data
        })
        return res.data.data
    } catch (err) {
        dispatch({
            type: DETAIL_TASK_FAIL
        })
        return err
    }
}


export const Add_Task_Event = (object) => async dispatch => {

    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    try {
        const body = JSON.stringify(object);
        const res = await axios.post(`${process.env.REACT_APP_API_URL}/api/event/add/`, body, config);

        dispatch({
            type: ADD_EVENT_SUCCESS,
            payload: res.data.data.data
        })
        return res.data.data
    } catch (err) {
        dispatch({
            type: ADD_EVENT_FAIL
        })
        return err
    }
}
export const Add_Tasks_Events = (object) => async dispatch => {

    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    try {
        const body = JSON.stringify(object);
        const res = await axios.post(`${process.env.REACT_APP_API_URL}/api/events/add/`, body, config);

        dispatch({
            type: ADD_EVENT_SUCCESS,
            payload: res.data.data.data
        })
        return res.data.data
    } catch (err) {
        dispatch({
            type: ADD_EVENT_FAIL
        })
        return err
    }
}

export const Update_Task_Event = (object) => async dispatch => {

    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    try {
        const body = JSON.stringify(object);
        const res = await axios.post(`${process.env.REACT_APP_API_URL}/api/event/edit/${object.id}/`, body, config);

        dispatch({
            type: ADD_EVENT_SUCCESS,
            payload: res.data.data.data
        })
        return res.data.data
    } catch (err) {
        dispatch({
            type: ADD_EVENT_FAIL
        })
        return err
    }
}

export const Disease_List = () => async dispatch => {

    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    try {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/disease/all/`, config);
        console.log('res d', res)
        dispatch({
            type: INSECT_LIST_SUCESS,
            payload: res.data.data.data
        })
        return res.data.data
    } catch (err) {
        dispatch({
            type: INSECT_LIST_FAIL
        })
        return err
    }
}
export const DiseaseActor_List = (disease) => async dispatch => {

    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    try {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/disease/actors/${disease}/`, config);
        console.log('res d', res)
        return res.data.data
    } catch (err) {
        return err
    }
}
export const Products_type_List = () => async dispatch => {

    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    try {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/products/type/all/`, config);
        console.log('res d', res)
        return res.data.data
    } catch (err) {
        return err
    }
}
export const Products_by_type = (type) => async dispatch => {

    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    try {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/products/type/${type}/`, config);
        console.log('res d', res)
        return res.data.data
    } catch (err) {
        return err
    }
}

export const Fertilisation_products = () => async dispatch => {

    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    try {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/fertilisation/product/all/`, config);
        console.log('res d', res)
        return res.data.data
    } catch (err) {
        return err
    }
}
export const Stade_phenologiques = () => async dispatch => {

    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    try {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/stades/all/`, config);
        console.log('res d', res)
        return res.data.data
    } catch (err) {
        return err
    }
}

export const Delete_Event = (id) => async dispatch => {

    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    try {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/event/delete/${id}/`, config);

        dispatch({
            type: DELETE_EVENT_SUCCESS,
            payload: res.data.data.message
        })
        return res.data.data
    } catch (err) {
        dispatch({
            type: DELETE_EVENT_FAIL
        })
        return err
    }
}
export const List_Projects_Tasks = (id) => async dispatch => {

    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    console.log('id', id)
    try {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/user/task/list/${id}/`, config);

        return res.data.data
    } catch (err) {
        return err
    }
}

export const Products_List = (type = '') => async dispatch => {

    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    try {
        let url = `${process.env.REACT_APP_API_URL}/api/products/`;
        if ((type === '')) {
            url += 'all/';
        } else {
            url += `type/${type}/`;
        }
        const res = await axios.get(url, config);

        dispatch({
            type: PRODUCT_LIST_SUCCESS,
            payload: res.data.data.data
        })
        return res.data.data
    } catch (err) {
        dispatch({
            type: PRODUCT_LIST_FAIL
        })
        return err
    }
}