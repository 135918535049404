import axios from 'axios';
import {
    NDVI_SUCESS,
    NDVI_FAIL,
    LOADING_BEGIN,
    DETAIL_TASK_SUCESS,
    DETAIL_TASK_FAIL,
    SELECTED_DATE_SUCCESS,
    SELECTED_DATE_FAIL,
    SELECTED_DATE_INITIAL_SUCCESS,
    SELECTED_DATE_INITIAL_FAIL,
    ACCUSATION_DATES_SUCCESS,
    ACCUSATION_DATES_FAIL,
    SET_DETAILS_TASK_FORM_SUCCESS,
    SET_TREE_COUNT_ARRAY_SUCCESS,
    SET_TREE_COUNT_ARRAY_FAIL,
    SET_TREE_COUNT_VALUE_SUCCESS,
    SET_TREE_COUNT_VALUE_FAIL,
    TASK_IMAGE_SIDEBAR_SUCCESS,
    TASK_IMAGE_SIDEBAR_FAIL,
    TASK_WEATHER_SUCCESS,
    TASK_WEATHER_FAIL,
    ADD_EVENT_SUCCESS,
    ADD_EVENT_FAIL,
    INSECT_LIST_FAIL,
    INSECT_LIST_SUCESS,
    PRODUCT_LIST_FAIL,
    PRODUCT_LIST_SUCCESS,
    DELETE_EVENT_SUCCESS,
    DELETE_EVENT_FAIL,
    REQUEST_EXIST


} from '../utils/types';

export const add_task = (project, area_of_interest, surface, task_name, age, culture, texture, salinity, density, irrigation, efficiency, variety) => async dispatch => {
    dispatch({
        type: LOADING_BEGIN
    })
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    const body = JSON.stringify({ project, area_of_interest, surface, task_name, age, culture, texture, salinity, density, irrigation, efficiency, variety });
    try {
        const res = await axios.post(`${process.env.REACT_APP_API_URL}/api/task/add/`, body, config);

        dispatch({
            type: NDVI_SUCESS,
            payload: res.data.data.data
        })
        return res.data.data
    } catch (err) {
        dispatch({
            type: NDVI_FAIL
        })
        return err
    }
}

export const edit_task = (id, task_name, age, culture, texture, salinity, density, irrigation, efficiency, variety) => async dispatch => {
    dispatch({
        type: LOADING_BEGIN
    })
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    const body = JSON.stringify({ id, task_name, age, culture, texture, salinity, density, irrigation, efficiency, variety });
    try {
        const res = await axios.post(`${process.env.REACT_APP_API_URL}/api/task/edit/`, body, config);

        dispatch({
            type: NDVI_SUCESS,
            payload: res.data.data.data
        })
        return res.data.data
    } catch (err) {
        dispatch({
            type: NDVI_FAIL
        })

        return err
    }
}

export const Detail_Task = (id, date, provider) => async dispatch => {

    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    const body = JSON.stringify({ id, date, provider });
    try {
        const res = await axios.post(`${process.env.REACT_APP_API_URL}/api/task/detail/`, body, config);

        dispatch({
            type: DETAIL_TASK_SUCESS,
            payload: res.data.data.data
        })
        return res.data.data
    } catch (err) {
        dispatch({
            type: DETAIL_TASK_FAIL
        })
        return err
    }
}
export const downloadOrderReport = (order_id, task) => async dispatch => {

    const body = JSON.stringify({ order_id, task });
    try {
        const res = await axios({ url: `${process.env.REACT_APP_API_URL}/api/highres/order/download/`, responseType: 'blob', method: 'POST', data: body })
        // , body, config);
        const pdfData = new Blob([res.data], { type: "application/pdf" });

        try {

            const contentDisposition = res.headers;
            // const filename = contentDisposition.split('filename=')[1]
            // // .split(';')[0].replace(/"/g, '');
        } catch (error) {
            console.log(error)
        }


        const url = window.URL.createObjectURL(pdfData);

        const tempoLink = document.createElement("a");
        tempoLink.href = url;
        tempoLink.setAttribute(
            "download", "filename.pdf"
        );
        document.body.appendChild(tempoLink);
        tempoLink.click();
        document.body.removeChild(tempoLink);
        window.URL.revokeObjectURL(url)

        return res
    } catch (err) {
        return err
    }
}

export const submitHighResOrder = (task, user, order_date, max_cloud, subscription) => async dispatch => {
    const body = JSON.stringify({ task, user, order_date, max_cloud });
    try {

        const res = await axios({ url: `${process.env.REACT_APP_API_URL}/api/highres/order/submit/`, method: 'POST', data: body })
        return res
    } catch (err) {
        return null;
    }
}

export const getOrders = (task) => async dispatch => {
    const body = JSON.stringify({ task });
    try {

        const res = await axios({ url: `${process.env.REACT_APP_API_URL}/api/highres/orders/list/`, method: 'POST', data: body, timeout: 3000 })
        return res
    } catch (err) {
        if (err.code === 'ECONNABORTED') {
            // console.log('Request timed out');
            return { "status": 503 }
        }
    }
}

export const testAPI = () => async dispatch => {

    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `JWT ${localStorage.getItem('access')}`,
            'Accept': 'application/json'
        }
    }
    try {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/test/`, config);

        dispatch({
            type: DETAIL_TASK_SUCESS,
            payload: res.data.data.data
        })
        return res.data.data
    } catch (err) {
        dispatch({
            type: DETAIL_TASK_FAIL
        })
        return err
    }
}

export const Detail_Task_initial = (id) => async dispatch => {

    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    try {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/task/detail/${id}/`, config);

        dispatch({
            type: DETAIL_TASK_SUCESS,
            payload: res.data.data.data
        })
        return res.data.data
    } catch (err) {
        dispatch({
            type: DETAIL_TASK_FAIL
        })
        return err
    }
}
export const setHasHighResOrdersState = (id) => (dispatch) => {
    dispatch({
        type: REQUEST_EXIST,
        payload: [1, 2]
    });
};

export const get_area_by_task_id = (id) => async dispatch => {

    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    try {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/task/area/${id}/`, config);
        return res
    } catch (err) {
        return err
    }
}

export const get_task_tree_count = (id) => async dispatch => {

    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    try {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/task/count_tree/${id}/`, config);
        if (res.status === 200) {
            dispatch({
                type: SET_TREE_COUNT_VALUE_SUCCESS,
                payload: res.data.data.region_count["count"]
            })
            dispatch({
                type: SET_TREE_COUNT_ARRAY_SUCCESS,
                payload: res.data.data.local_index.result
            })
        } else {
            dispatch({
                type: SET_TREE_COUNT_VALUE_FAIL
            })
            dispatch({
                type: SET_TREE_COUNT_ARRAY_FAIL
            })
        }
    } catch (err) {
        return err
    }
}

export const Set_detail_from_data = (data) => async dispatch => {
    dispatch({
        type: SET_DETAILS_TASK_FORM_SUCCESS,
        payload: data
    })
}


export const Sensor_data = (url) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Accept': '*/*'
        }
    }
    try {
        const res = await axios.get(url, config);
        return res
    } catch (err) {

        return err
    }
}

export const get_dates = (id) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json',
        }
    }
    try {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/get-dates/${id}/`, config);

        return res
    } catch (err) {
        return err
    }
}

export const change_selected_date = (date, provider) => async dispatch => {
    if (date) {
        dispatch({
            type: SELECTED_DATE_SUCCESS,
            payload: { "date": date, "provider": provider }
        })
    } else {
        dispatch({
            type: SELECTED_DATE_FAIL,
            payload: null
        })
    }
}

export const change_selected_date_initial = (date) => async dispatch => {
    if (date) {
        dispatch({
            type: SELECTED_DATE_INITIAL_SUCCESS,
            payload: date
        })
    } else {
        dispatch({
            type: SELECTED_DATE_INITIAL_FAIL,
            payload: null
        })
    }
}


export const change_accusation_dates = (dates) => async dispatch => {
    if (dates) {
        dispatch({
            type: ACCUSATION_DATES_SUCCESS,
            payload: dates
        })
    } else {
        dispatch({
            type: ACCUSATION_DATES_FAIL,
            payload: null
        })
    }
}


export const getImageByDate = (id, date) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json',
        }
    }
    try {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/get-date-image/${id}/${date}/`, config);
        return res.data.data
    } catch (err) {
        return err
    }
}




export const deleteTask = (id_task) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json',
        }
    }
    try {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/task/delete/${id_task}/`, config);
        return res
    } catch (err) {
        return err
    }
}

export const addSubscription = (task, user, date_of_request, subscription) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json',
        }
    }
    const body = JSON.stringify({ task, user, date_of_request, subscription });
    try {
        const res = await axios.post(`${process.env.REACT_APP_API_URL}/api/task/subscription/add/`, body, config);

        return res
    } catch (err) {
        return err
    }
}



export const Task_Events = (id) => async dispatch => {

    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    try {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/events/${id}/`, config);

        dispatch({
            type: DETAIL_TASK_SUCESS,
            payload: res.data.data.data
        })
        return res.data.data
    } catch (err) {
        dispatch({
            type: DETAIL_TASK_FAIL
        })
        return err
    }
}

export const Add_Task_Event = (object) => async dispatch => {

    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    try {
        const body = JSON.stringify(object);
        const res = await axios.post(`${process.env.REACT_APP_API_URL}/api/event/add/`, body, config);

        dispatch({
            type: ADD_EVENT_SUCCESS,
            payload: res.data.data.data
        })
        return res.data.data
    } catch (err) {
        dispatch({
            type: ADD_EVENT_FAIL
        })
        return err
    }
}

export const Update_Task_Event = (object) => async dispatch => {

    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    try {
        const body = JSON.stringify(object);
        const res = await axios.post(`${process.env.REACT_APP_API_URL}/api/event/edit/${object.id}/`, body, config);

        dispatch({
            type: ADD_EVENT_SUCCESS,
            payload: res.data.data.data
        })
        return res.data.data
    } catch (err) {
        dispatch({
            type: ADD_EVENT_FAIL
        })
        return err
    }
}


export const Detail_Task_Simple = (id) => async dispatch => {

    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    try {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/task/details-simple/${id}/`, config);

        dispatch({
            type: DETAIL_TASK_SUCESS,
            payload: res.data.data.data
        })
        return res.data.data
    } catch (err) {
        dispatch({
            type: DETAIL_TASK_FAIL
        })
        return err
    }
}

export const Detail_Task_Map = (id) => async dispatch => {

    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    try {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/task/map/${id}/`, config);

        dispatch({
            type: DETAIL_TASK_SUCESS,
            payload: res.data.data
        })
        return res
    } catch (err) {
        dispatch({
            type: DETAIL_TASK_FAIL
        })
        return err
    }
}


export const getTaskWeather = (lat, lng) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json',
        }
    }

    const apiKey = "01f1e96f8b99757f5bffec52d6fdcf81";
    const apiUrl = `https://api.openweathermap.org/data/2.5/weather?lat=${lat}&lon=${lng}&units=metric&appid=${apiKey}`;
    // axios.get(`${apiUrl}&appid=${apiKey}`).then(displayWeatherInfo);

    try {
        const res = await axios.get(`${apiUrl}&appid=${apiKey}`, config);
        if (res.status === 200) {
            dispatch({
                type: TASK_WEATHER_SUCCESS,
                payload: res.data
            })

            return res.data;
        } else {
            dispatch({
                type: TASK_WEATHER_FAIL
            })
        }
        return res
    } catch (err) {
        dispatch({
            type: TASK_WEATHER_FAIL
        })
    }

}

export const Products_List = (type = '') => async dispatch => {

    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    try {
        let url = `${process.env.REACT_APP_API_URL}/api/products/`;
        if ((type === '')) {
            url += 'all/';
        } else {
            url += `type/${type}/`;
        }
        const res = await axios.get(url, config);

        dispatch({
            type: PRODUCT_LIST_SUCCESS,
            payload: res.data.data.data
        })
        return res.data.data
    } catch (err) {
        dispatch({
            type: PRODUCT_LIST_FAIL
        })
        return err
    }
}

export const getTaskForecast = (lat, lng) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json',
        }
    }

    const apiUrl = `https://api.open-meteo.com/v1/forecast?latitude=${lat}&longitude=${lng}&daily=temperature_2m_max,temperature_2m_min,rain_sum,wind_speed_10m_max,weather_code`;

    try {
        const res = await axios.get(`${apiUrl}`, config);
        if (res.status === 200) {
            dispatch({
                type: TASK_WEATHER_SUCCESS,
                payload: res.data
            })

            return res.data;
        } else {
            dispatch({
                type: TASK_WEATHER_FAIL
            })
        }
        return res
    } catch (err) {
        dispatch({
            type: TASK_WEATHER_FAIL
        })
    }

}

export const Insect_List = () => async dispatch => {

    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    try {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/insects/all/`, config);

        dispatch({
            type: INSECT_LIST_SUCESS,
            payload: res.data.data.data
        })
        return res.data.data
    } catch (err) {
        dispatch({
            type: INSECT_LIST_FAIL
        })
        return err
    }
}


export const getTaskHistory = (lat, lng, start_date, end_date) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json',
        }
    }

    const apiUrl = `https://archive-api.open-meteo.com/v1/archive?latitude=${lat}&longitude=${lng}&start_date=${start_date}&end_date=${end_date}&daily=weather_code,temperature_2m_max,temperature_2m_min,rain_sum,wind_speed_10m_max`;

    try {
        const res = await axios.get(`${apiUrl}`, config);
        if (res.status === 200) {
            dispatch({
                type: TASK_WEATHER_SUCCESS,
                payload: res.data
            })

            return res.data;
        } else {
            dispatch({
                type: TASK_WEATHER_FAIL
            })
        }
        return res
    } catch (err) {
        return err
    }
}

export const Delete_Event = (id) => async dispatch => {

    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    try {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/event/delete/${id}/`, config);

        dispatch({
            type: DELETE_EVENT_SUCCESS,
            payload: res.data.data.message
        })
        return res.data.data
    } catch (err) {
        dispatch({
            type: DELETE_EVENT_FAIL
        })
        return err
    }
}


export const get_task_sidebar_image = (id) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json',
        }
    }
    try {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/task/check_image_sidebar/${id}/`, config);
        if (res.status === 200) {
            dispatch({
                type: TASK_IMAGE_SIDEBAR_SUCCESS,
                payload: res.data.data.url
            })
        } else {
            dispatch({
                type: TASK_IMAGE_SIDEBAR_FAIL
            })
        }
        return res
    } catch (err) {
        return err
    }
}

export const set_smart_control_command = (command) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json',
        }
    }
    const body = JSON.stringify({ command });
    try {
        const res = await axios.post("https://smart-irrigation.robocare.tn/api/control", body, config);
        return res
    } catch (err) {
        return err
    }
}

export const get_smart_control_command_status = () => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json',
        }
    }
    const body = JSON.stringify({ "command": "" });
    try {
        const res = await axios.post("https://smart-irrigation.robocare.tn/api/control", body, config);
        return res
    } catch (err) {
        return err
    }
}