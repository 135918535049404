import React, { useState, useEffect, useRef } from "react";
import "leaflet/dist/leaflet.css";
import "leaflet-draw/dist/leaflet.draw.css";
import "../../assets/css/map.css";
import "../../assets/scss/map.scss";
import L from "leaflet";
import {
    LayersControl,
    MapContainer,
    TileLayer,
    ZoomControl,
} from "react-leaflet";
import { useParams } from "react-router-dom";

import * as esri from "esri-leaflet";
import { loadingBegin, loadingEnd } from "../../actions/map";
import { useDispatch, useSelector } from "react-redux";
import { OpenStreetMapProvider, GeoSearchControl } from "leaflet-geosearch";
import "../../../node_modules/leaflet-geosearch/dist/geosearch.css";
import Loading from "../../utils/loading";
import { ScaleControl } from "react-leaflet";
import FullscreenControl from "../mapUtils/FullscreenControl";

import {
    // Detail_Task,
    Detail_Task_initial,
    testAPI,
    Sensor_data,
    Set_detail_from_data,
    change_selected_date_initial,
    get_task_tree_count,
} from "../../actions/task";
import SnackBarCopieToClipboard from "../../utils/SnackBarCopieToClipboard";
import $ from "jquery";
import NoteBtn from "./note/noteBtn";
import NoteModal from "./note/noteModal";
import PopupContent from "./count/PopupContent";
import { renderToString } from "react-dom/server";
import NoteListModal from "./note/noteListModal";
import TaskSidebar from "./sidebars/TaskSidebarPreview"

import SidebarToggleButton from "./sidebars/SidebarToggleButton";
import SidebarBottom from "./sidebars/SidebarBottom";
import MainModal from "./analysePanel/MainModal";
import WeatherBlock from "./sidebars/WeatherBlock";

const style = { height: "100%", width: "100wh", zIndex: "9" };

const mapParams = {
    center: [34.809, 9.515],
    zoom: 7,
    maxZoom: 18,
};

const apiKey =
    "AAPK1635806c5df74566bc9567f76c056850HTDo7PM4rZ-SwiEnzxmvAFNfj0zMBrP93MtbHy3q5YarvngZzkftSX-J_31R98B5";
const satelliteLayer = new esri.BasemapLayer("Imagery", {
    apikey: apiKey,
    portalUrl: "https://www.arcgis.com",
    maxZoom: 18,
    attribution: "",
});

const overlays = {
    Satellite: satelliteLayer,
};

const TaskPreview = () => {
    const [Map, setMap] = useState(null);
    const baseLayer = useRef(null);
    const baseMaps = {};
    const [labelsLayer, setlabelsLayer] = useState(null);
    const [flyToCenter, setflyToCenter] = useState(null);
    const [floToZoom, setfloToZoom] = useState(null);
    const [projectName, setprojectName] = useState(null);
    const [opensnack, setopensnack] = useState(false);
    const [message, setmessage] = useState(null);
    const [vertical, setvertical] = useState(null);
    const [horizontal, sethorizontal] = useState(null);
    const dispatch = useDispatch();
    const VegetationLayersState = useSelector(
        (state) => state.mapReducer.DetailVegetationLayers
    );
    const checkedLayer = useSelector((state) => state.mapReducer.checkboxLayers);
    const selected_date = useSelector((state) => state.mapReducer.SelectedDate);
    const clickEventState = useSelector(
        (state) => state.mapReducer.clickEventCheck
    );
    const [SensorUrl, setSensorUrl] = useState(null);
    const [SensorName, setSensorName] = useState(null);
    const [modalOpenListNoteTask, setmodalOpenListNoteTask] = useState(false);
    const [modalOpenNoteTask, setmodalOpenNoteTask] = useState(false);
    const [modalOpenAnalysePanel, setmodalOpenAnalysePanel] = useState(false)
    const [activePaneAnalysePanel, setactivePaneAnalysePanel] = useState(0)
    let { id_task } = useParams();
    const taskName = useSelector((state) => state.mapReducer.taskNameDetails);
    const isCheckedCountCheckbox = useSelector((state) => state.mapReducer.isCheckedCountCheckbox)
    const treeCountArray = useSelector((state) => state.mapReducer.treeCountLocalIndicesArray)
    //count state

    const [LocalIndices, setLocalIndices] = useState([]);
    const [countValue, setCountValue] = useState(0);

    useEffect(() => {
        dispatch(loadingBegin());
    }, []);

    const handleCloseSnackBar = () => {
        setopensnack(false);
    };
    const handleOpenSnackBar = (msg, vert, horiz) => {
        setopensnack(true);
        setmessage(msg);
        setvertical(vert);
        sethorizontal(horiz);
    };

    useEffect(() => {
        if (!Map) return;

        const search = new GeoSearchControl({
            provider: new OpenStreetMapProvider(),
            style: "bar",
            showMarker: false,
            showPopup: true,
            retainZoomLevel: false,
            animateZoom: true,
            autoClose: true,
            searchLabel: "Enter address, please",
            keepResult: true,
            popupFormat: ({ query, result }) => result.label,
            position: "topright",
            notFoundMessage: "Sorry, that address could not be found.",
        });
        Map.addControl(search);
    }, [Map]);

    useEffect(() => {
        if (!Map) return;
        baseMaps["Base Map"] = baseLayer.current;
        // add baseMap ans sattelite to the LayerControl
        // add labels layer to the map without adding it to the layercontrol
        const ImageryLabels = new esri.BasemapLayer("ImageryLabels", {
            apikey: apiKey,
            portalUrl: "https://www.arcgis.com",
        }).addTo(Map);
        overlays["Satellite"].addTo(Map);
        setlabelsLayer(ImageryLabels);
    }, [Map]);

    useEffect(() => {
        if (!VegetationLayersState || !Map) return;
        const layerList = ["rgb", "ndvi", "ndre", "ndwi", "gci", "psri"];
        Map.eachLayer((layer) => {
            if (layerList.includes(layer.options.checkedLayer)) {
                Map.removeLayer(layer);
            }
        });
        if (checkedLayer !== null) {
            const layer = L.tileLayer(VegetationLayersState[checkedLayer].url, {
                checkedLayer,
            });
            layer.addTo(Map).bringToFront();
        }
    }, [checkedLayer, VegetationLayersState]);

    useEffect(() => {
        if (!treeCountArray || !Map) return
        if (isCheckedCountCheckbox === 1) {
            const group = L.layerGroup()
            treeCountArray.map(item => {
                const treeMarker = L.circleMarker([item.lat, item.lon],
                    {
                        key: item.maxx,
                        radius: (item.maxx - item.minx) / 2,
                        color: item.mean_ndvi > 0.3 ? "team" : "orange",
                        name: "countMarkers"
                    }).bindPopup(renderToString(<PopupContent data={item} />))
                treeMarker.addTo(group);
            })
            group.addTo(Map)
        } else {
            Map.eachLayer((layer) => {
                if (layer.options.name === "countMarkers") {
                    Map.removeLayer(layer);
                }
            });
        }
    }, [treeCountArray, isCheckedCountCheckbox])


    useEffect(() => {
        if (!Map || !flyToCenter) return;
        Map.flyTo(flyToCenter, floToZoom, {
            duration: 4,
        });
    }, [flyToCenter]);

    // copie cordonnées gps to clipboard
    useEffect(() => {
        if (!Map) return;
        const MarkerImage = require("../../assets/images/marker.png");
        const MarkerIcon = L.icon({
            iconUrl: MarkerImage,
            iconSize: [27, 40],
            iconAnchor: [13.5, 40],
        });
        const popupOptions = {
            closeButton: false,
            autoClose: true,
            className: "cord-popup-copie",
        };
        // const popup = L.popup(popupOptions);
        const marker = L.marker();
        const popup = L.popup(popupOptions);

        marker.setIcon(MarkerIcon);
        if (clickEventState) {
            Map.addEventListener("click", (e) => {
                const { lat, lng } = e.latlng;
                if (lat && lng) {
                    const position = L.latLng(lat, lng);
                    const popupContent = `${lat.toFixed(4)},${lng.toFixed(4)}`;

                    marker.setLatLng(position).addTo(Map);
                    popup
                        .setLatLng(position)
                        .setContent(
                            '<div className="sensor-popup">' + popupContent + "</div>"
                        )
                        .addTo(Map);
                    navigator.clipboard
                        .writeText(popupContent)
                        .then(() => {
                            handleOpenSnackBar("copied to clipboard !", "bottom", "center");
                        })
                        .catch((error) => { });
                    setTimeout(function () {
                        Map.removeLayer(popup);
                    }, 2000);
                }
            });
        } else {
            Map.removeEventListener("click");
        }

        return () => {
            Map.removeEventListener("click");
            Map.removeLayer(marker);
            Map.removeLayer(popup);
        };
    }, [Map, clickEventState]);

    // end
    useEffect(() => {
        dispatch(loadingBegin());

        // dispatch(testAPI()).then((res) => {
        //     const center = [res.center[1].toFixed(3), res.center[0].toFixed(3)];
        //     setfloToZoom(res.zoom);
        //     setflyToCenter(center);
        //     setprojectName(res.project.project_name);
        //     dispatch(Set_detail_from_data(res.task));
        //     dispatch(change_selected_date_initial(res.last_date));
        //     if (res.sensors) {
        //         res.sensors.map((sensor) => {
        //             setSensorName(sensor["sensor_name"] || "");
        //             setSensorUrl(sensor["url"] || "");
        //         });
        //     } else {
        //         setSensorName(null);
        //         setSensorUrl(null);
        //     }
        //     dispatch(get_task_tree_count(id_task)).then((res) => {
        //         if (res.status === 200) {
        //             setLocalIndices(res.data.data.local_index.result);
        //             setCountValue(res.data.data.region_count["count"]);
        //         }
        //     });
        //     dispatch(loadingEnd());
        // });

        dispatch(Detail_Task_initial(id_task)).then((res) => {
            const center = [res.center[1].toFixed(3), res.center[0].toFixed(3)];
            setfloToZoom(res.zoom);
            setflyToCenter(center);
            setprojectName(res.project.project_name);
            dispatch(Set_detail_from_data(res.task));
            dispatch(change_selected_date_initial(res.last_date));
            if (res.sensors) {
                res.sensors.map((sensor) => {
                    setSensorName(sensor["sensor_name"] || "");
                    setSensorUrl(sensor["url"] || "");
                });
            } else {
                setSensorName(null);
                setSensorUrl(null);
            }
            dispatch(get_task_tree_count(id_task)).then((res) => {
                if (res.status === 200) {
                    setLocalIndices(res.data.data.local_index.result);
                    setCountValue(res.data.data.region_count["count"]);
                }
            });
            dispatch(loadingEnd());
        });


    }, []);

    useEffect(() => {
        if (!Map || !SensorName || !SensorUrl) return;
        getSonsorData(SensorUrl, SensorName);
    }, [Map, SensorName]);

    /* TODO: check this code
    useEffect(() => {
        if (!selected_date) return;
        dispatch(loadingBegin());
        dispatch(Detail_Task(id_task, selected_date.date, selected_date.provider)).then((res) => {
            dispatch(loadingEnd());
        });
    }, [selected_date]);

    */


    useEffect(() => {
        if (!Map) return;
        if (clickEventState === false) {
            Map.scrollWheelZoom.disable();
            Map.dragging.disable();
            Map.touchZoom.disable();
            Map.doubleClickZoom.disable();
            Map.boxZoom.disable();
            Map.keyboard.disable();
            if (Map.tap) Map.tap.disable();
        } else {
            Map.scrollWheelZoom.enable();
            Map.dragging.enable();
            Map.touchZoom.enable();
            Map.doubleClickZoom.enable();
            Map.boxZoom.enable();
            Map.keyboard.enable();
            if (Map.tap) Map.tap.enable();
        }
    }, [Map, clickEventState]);



    const sensorImage = require("../../assets/images/sensor.png");
    const Sensor = L.icon({
        iconUrl: sensorImage,
        iconSize: [37, 50],
    });

    const getSonsorData = (url, sensor_name) => {
        dispatch(Sensor_data(url)).then((res) => {
            const response = res.data.allDataSensor[0];
            if (res.status == 201) {
                L.marker([response["latitude"], response["longitude"]], {
                    icon: Sensor,
                })
                    .addTo(Map)
                    .bindPopup(
                        '<div className="sensor-popup"><div className="col-md-12"><b>' +
                        sensor_name +
                        "</b></div><br>" +
                        '<div className="col-md-12">Time: <b>' +
                        response["time"] +
                        "</b></div>" +
                        '<div className="col-md-12">Temperature: <b>' +
                        response["temperature"] +
                        " °C</b></div>" +
                        '<div className="col-md-12">Humidity: <b>' +
                        response["humidity"] +
                        " %</b></div>" +
                        '<div className="col-md-12">Pressure: <b>' +
                        response["pressure"] +
                        " kPa</b></div>" +
                        '<div className="col-md-12">Altitude: <b>' +
                        response["altitude"] +
                        " M</b></div>" +
                        '<div className="col-md-12">Ground temperature: <b>' +
                        response["ts"] +
                        " °C</b></div>" +
                        '<div className="col-md-12">Humidity (20 cm depth): <b>' +
                        response["mv1"] +
                        " %</b></div>" +
                        '<div className="col-md-12">Humidity (40 cm depth): <b>' +
                        response["mv2"] +
                        " %</b></div>" +
                        '<div className="col-md-12">Humidity (60 cm depth): <b>' +
                        response["mv3"] +
                        " %</b></div>" +
                        '<div className="col-md-12">Evapotranspiration: <b>' +
                        response["et0"] +
                        "</b></div>" +
                        '<div className="col-md-12">Rain: <b>' +
                        response["rain"] +
                        "</b></div>" +
                        '<div className="col-md-12">Solar radiation: <b>' +
                        response["radiation"] +
                        " W/m²</b></div>" +
                        "</div>"
                    );
            }
        });
    };
    const handleOpenListNotekModal = () => {
        setmodalOpenListNoteTask(true);
    };
    const handleCloseListNoteModal = () => {
        setmodalOpenListNoteTask(false);
    };

    const handleOpenNotekModal = () => {
        handleCloseListNoteModal();
        setmodalOpenNoteTask(true);
    };
    const handleCloseNoteModal = () => {
        setmodalOpenNoteTask(false);
    };
    const handleOpenAnalysePanel = (activePane) => {
        handleCloseListNoteModal();
        setmodalOpenAnalysePanel(true);
        setactivePaneAnalysePanel(activePane)
    };
    const handleCloseAnalysePanel = () => {
        setmodalOpenAnalysePanel(false);
    };
    return (
        <div
            className="mod-task-preview"
        >
            <Loading />
            <div>
                <MapContainer
                    ref={setMap}
                    style={style}
                    center={mapParams.center}
                    zoom={mapParams.zoom}
                    minZoom={0}
                    zoomControl={false}
                    maxZoom={mapParams.maxZoom}
                    id="mapContainerPreview"
                    className="map-container"
                >
                    <TileLayer
                        ref={baseLayer}
                        attribution='&copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors'
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                        minZoom={0}
                        maxZoom={22}
                    />
                    <TileLayer
                        ref={baseLayer}
                        attribution='&copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors'
                        url="https://www.arcgis.com"
                        minZoom={0}
                        maxZoom={22}
                    />
                    <ZoomControl position="bottomright" />
                    <ScaleControl position="bottomleft" />
                    <FullscreenControl position="bottomright" />
                    <SnackBarCopieToClipboard
                        open={opensnack}
                        message={message}
                        autoHide={1000}
                        vertical={vertical}
                        horizontal={horizontal}
                        handleClose={handleCloseSnackBar}
                    />
                    <NoteBtn openModal={handleOpenListNotekModal} />
                    <NoteListModal
                        open={modalOpenListNoteTask}
                        handleClose={handleCloseListNoteModal}
                        handleNoteModal={handleOpenNotekModal}
                        idTask={id_task}
                    />
                    <NoteModal
                        open={modalOpenNoteTask}
                        handleClose={handleCloseNoteModal}
                        idTask={id_task}
                    />
                    <MainModal
                        open={modalOpenAnalysePanel}
                        handleClose={handleCloseAnalysePanel}
                        idTask={id_task}
                        activePane={activePaneAnalysePanel}
                    />

                    <TaskSidebar openModal={handleOpenAnalysePanel} />
                    <SidebarBottom />
                    {/* <SidebarToggleButton />
                    <div className="weather-card d-none d-md-block" >
                        <WeatherBlock openModal={handleOpenAnalysePanel} />
                    </div> */}

                </MapContainer>
            </div>
        </div>

    );
};
export default TaskPreview;
