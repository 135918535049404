import { Button, Card } from "@mui/material";
import React, { useEffect, useState } from "react";
import Row from "react-bootstrap/esm/Row";
import { FieldsIcon, AddIcon } from "../utils/icons";
import logo_sat from '../assets/images/satellite.gif'
import AddProject from "./project/add";
import { useNavigate } from "react-router-dom";
import { loadingBegin, loadingEnd } from "../actions/map";
import { useDispatch } from "react-redux";
import { useTranslation } from 'react-i18next';
import "../assets/scss/dashboard.scss";
import { getStatistics } from "../actions/profile";

import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';
import earthImg from '../assets/images/earth.png'
import timeImg from '../assets/images/time.png'
import Avatar from 'react-avatar';
import gradient from 'random-gradient'
import { getRandomColor } from "../utils/backgroundColors";
import { getPieRandomColor } from "../utils/backgroundColors";
import dashBackground from '../assets/images/dash-background.png'
import cookies from "js-cookie";


ChartJS.register(ArcElement, Tooltip, Legend);
const options = {
    plugins: {
        tooltip: {
            callbacks: {
                label: (context) => {
                    const label = context.label || '';
                    const value = context.formattedValue || '';
                    return `${label}: ${value} Ha`;
                },
            },
        },
    },
};
const Home = ({ }) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { t } = useTranslation();
    const [statistics, setStatistics] = useState({})
    const [data, setData] = useState({})
    const [gradientColor, setgradientColor] = useState(null)
    const currentLanguageCode = cookies.get("i18next") || "en";

    useEffect(() => {
        dispatch(loadingBegin())
        dispatch(getStatistics()).then(res => {
            if (res.status === 200) {
                setStatistics(res.data.data)
                let labels = []
                let dataset = []
                let colors = [];
                const variety_name_ln = "variety_name_" + currentLanguageCode
                let usedColors = [];
                res.data.data.varieties.map((element) => {
                    labels.push(element[variety_name_ln])
                    dataset.push(element.surface)

                    colors.push(getPieRandomColor(usedColors))
                });

                const result = {
                    labels: labels,
                    datasets: [
                        {
                            // label: '# of Votes',
                            data: dataset,
                            backgroundColor: colors
                        }]
                }

                setData(result)

            } else {

            }
        }).catch(error => {

        })
        dispatch(loadingEnd())
    }, [])


    return (
        <div>
            <Card className="mod-dashboard">

                <div className="row my-3">

                    <div className="col-sm-12 col-lg-6 col-xxl-5">
                        <div className="m-user-card card">

                            <img className="bd-placeholder-img card-img-top m-image" width="100%" height="180" src={dashBackground} />

                            <div className="card-body m-card-body row">
                                <div className="m-avatar">
                                    <Avatar color={getRandomColor()} name={statistics.full_name} round={true} />
                                </div>
                                <h5 className="card-title m-card-title">{statistics.full_name}</h5>
                                <p className="card-text col-12">Welcome back.</p>

                                <div className="row">

                                    <div className="col-sm-12 col-lg-6 d-flex align-items-start">
                                        <div className="icon-square text-body-emphasis d-inline-flex align-items-center justify-content-center fs-4 flex-shrink-0 me-3">
                                            <img src={timeImg} />
                                        </div>
                                        <div>
                                            <h6 className="mt-2 text-body-emphasis">{t("Last login")}</h6>
                                            <p>{statistics.last_login}</p>
                                        </div>
                                    </div>

                                    <div className="col-sm-12 col-lg-6  d-flex align-items-start">
                                        <div className="icon-square text-body-emphasis d-inline-flex align-items-center justify-content-center fs-4 flex-shrink-0 me-3">
                                            <img src={earthImg} />
                                        </div>
                                        <div>
                                            <h6 className="mt-2 text-body-emphasis">{t("Surface")}</h6>
                                            <p>{statistics.surface} Ha</p>

                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>

                    <div className="col-sm-12 col-lg-6">
                        <div className="m-chart d-flex align-items-center flex-column">
                            <h5 className="m-title">
                                <i className="bi bi-globe-europe-africa"></i>
                                {t("Agricultural distribution in hectares")}
                            </h5>
                            <div className="m-chart w-80">
                                {data.labels && data.labels.length > 0 ? <Pie data={data} options={options} /> : null}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">

                    <div className="m-files-list col-sm-12">

                        <div className="content">
                            <div className="container">
                                <div className="row my-5">
                                    <div className="col-12">
                                        <div className="card-box">
                                            <div className="row my-2">
                                                <div className="col-sm-12 col-md-6 col-lg-3 col-xl-6">
                                                    <h6 className="header-title my-3"> <i className="bi bi-file-pdf-fill"></i> {t("Monitoring reports")}</h6>
                                                </div>
                                            </div>

                                            <div className="row">
                                                {statistics.reports &&
                                                    statistics.reports.length > 0 ?
                                                    statistics.reports.map((item, i) => (
                                                        <div className="col-lg-3 col-xl-2" key={i}>
                                                            <a href={process.env.REACT_APP_API_URL + item.file} className="mb-0 text-overflow" target="_blank">

                                                                <div className="file-man-box text-center">
                                                                    <div className="file-img-box"><img src="/assets/icons/pdf.svg" alt="pdf icon" width={50} /></div>
                                                                    <div className="file-man-title text-center">

                                                                        <span className="mb-0 text-overflow" target="_blank">
                                                                            <h6 className="mb-0 mt-2 text-overflow">{item.name}</h6>
                                                                        </span>
                                                                    </div>
                                                                </div>

                                                            </a>
                                                        </div>
                                                    ))
                                                    :
                                                    <span>{t("No available reports for the moment !")}</span>
                                                }
                                            </div>


                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                </div>

            </Card >
        </div >
    )
}
export default Home