import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
    Detail_Task_Simple,
    Task_Events,
    Add_Task_Event,
    Disease_List,
    Update_Task_Event,
    Delete_Event,
    Products_type_List,
    Products_by_type,
    Fertilisation_products,
    Stade_phenologiques
} from "../../../actions/calendar.js";
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'
import Button from "react-bootstrap/Button";
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Form from "react-bootstrap/Form";
import { Chip, Container, FormControl, Stack, duration } from "@mui/material";
import InputLabel from '@mui/material/InputLabel';
import { loadingBegin, loadingEnd } from "../../../actions/map";
import dayjs from 'dayjs';
import "../../../assets/scss/calendar.scss";
import { styled, createTheme, ThemeProvider } from "@mui/material/styles";

import CustomButton from '../../CustomButton';
import { IoIosAdd } from 'react-icons/io';
import { MdOutlineCancel } from 'react-icons/md';
import { MdDeleteOutline } from 'react-icons/md';
import { useTranslation } from 'react-i18next';
import BasicDateTimePicker from "../../../utils/BasicDateTimePicker.js";
import { Col, Row } from "react-bootstrap";
import { DiseaseActor_List } from "../../../actions/calendar.js";
import { blue, grey, red } from "@mui/material/colors";


const TaskCalendar = () => {

    const id_task = useSelector((state) => state.mapReducer.taskIdDetails);
    const [openDialog, setOpenDialog] = useState(false);
    const [calendarEvents, setCalendarEvents] = useState([]);
    const { t } = useTranslation();

    const types = {
        10: 'Fertilisation',
        20: 'Traitements phytosanitaires',
        30: 'Pratiques culturales',
        40: 'Recolte',
        50: 'Irrigation'
    };

    const colors = {
        10: 'warning',
        20: 'error',
        30: 'grey',
        40: 'success',
        50: 'primary'
    };

    const diseaseActorLabel = {
        "Insectes et ravageurs": 'Insects',
        "Maladies fongiques et bacterie": 'Champignons',
        "Maladies virales et à viroîdes": 'virus et viroïdes',
        "Nématodes": 'Nématodes',
        "Mauvaises herbes": 'Mauvaises Herbes ',
        "Rongeurs": 'Rongeurs'
    };

    const dates = {
        10: { start: "fertilization start time", end: "fertilization end time" },
        20: { start: "start time of Phytosanitary treatments", end: "end time of Phytosanitary treatments" },
        30: { start: "start time of Cultivation Practices", end: "end time of Cultivation Practices" },
        40: { start: "Harvest start time", end: "Harvest end time" },
        50: { start: "valve opening time", end: "valve closing time" },
    };


    const [task, setTask] = useState({});
    const [event, setEvent] = useState({});
    const [events, setEvents] = useState([]);
    const [diseases, setDiseases] = useState([]);
    const [diseaseActors, setdiseaseActors] = useState([])
    const [diseaseActLabel, setdiseaseActLabel] = useState(null)
    const [typeProducts, settypeProducts] = useState([])
    const [fertilisationProducts, setfertilisationProducts] = useState([])
    const [stadePhenologiques, setstadePhenologiques] = useState([])
    const [products, setProducts] = useState([]);
    // const [period, setPeriod] = useState(0);
    const [periodFormated, setPeriodFormated] = useState("");
    const [duration, setduration] = useState(null)
    const [selectedEvent, setselectedEvent] = useState('10')
    const dispatch = useDispatch();


    useEffect(() => {
        console.log('duration', duration)
    }, [duration])
    // modal methods
    const handleClickOpen = () => {
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
        setselectedEvent('10')
    };

    const handleCreate = () => {
        setOpenDialog(false);
    };
    // Function to format minutes into days, hours, and minutes
    const formatDuration = (minutes) => {
        const days = Math.floor(minutes / (24 * 60));
        const hours = Math.floor((minutes % (24 * 60)) / 60);
        const remainingMinutes = minutes % 60;

        let result = '';
        if (days > 0) {
            result += `${days} day${days > 1 ? 's' : ''} `;
        }
        if (hours > 0) {
            result += `${hours} hour${hours > 1 ? 's' : ''} `;
        }
        if (remainingMinutes > 0) {
            result += `${remainingMinutes} minute${remainingMinutes > 1 ? 's' : ''}`;
        }

        return result.trim();
    };
    function onDateOpenChange(e) {
        const date = new Date(e.target.value.$d);
        console.log('ffffffffffffffffff')
        setEvent(prevEvent => {
            let updatedEvent = { ...prevEvent };

            updatedEvent.open_hour = date;

            // Auto-calculate period for 'Irrigation'
            console.log('updatedEvent.type ', updatedEvent.type)
            if (updatedEvent.type === "50") {
                const startDate = new Date(updatedEvent.open_hour);
                const endDate = new Date(updatedEvent.close_hour);
                console.log('startDate', startDate)
                console.log('endDate', endDate)
                // Check if both start and end dates are valid before calculating
                const diffTime = Math.abs(endDate - startDate);
                const diffMinutes = Math.ceil(diffTime / (1000 * 60));
                console.log('diffMinutes', diffMinutes);
                const duration = formatDuration(diffMinutes)
                console.log('duration', duration)
                setduration(diffMinutes);
                setPeriodFormated(duration);
            }
            return updatedEvent;
        });
    }

    function onDateCloseChange(e) {
        const date = new Date(e.target.value.$d);
        console.log('ffffffffffffffffff')
        setEvent(prevEvent => {
            let updatedEvent = { ...prevEvent };
            updatedEvent.close_hour = date;
            // Auto-calculate period for 'Irrigation'
            console.log('updatedEvent.type ', updatedEvent.type)
            if (updatedEvent.type === "50") {
                const startDate = new Date(updatedEvent.open_hour);
                const endDate = new Date(updatedEvent.close_hour);
                console.log('startDate', startDate)
                console.log('endDate', endDate)
                // Check if both start and end dates are valid before calculating
                const diffTime = Math.abs(endDate - startDate);
                const diffMinutes = Math.ceil(diffTime / (1000 * 60));
                const duration = formatDuration(diffMinutes)
                console.log('duration', duration)
                setduration(diffMinutes);
                setPeriodFormated(duration);
            }
            return updatedEvent;
        });
    }
    const onChange = e => {
        setEvent({ ...event, [e.target.name]: e.target.value });
    }

    const onSubmit = e => {

        if ((typeof event.task === "undefined")) {
            let copy = event;
            copy.task = task.id;
            copy.variety = task.variety;
            setEvent(copy);
        }

        if (event.type == 50) {
            event.duration = duration;
        }

        if ((typeof event.id === "undefined")) {
            dispatch(Add_Task_Event(event)).then((res) => {
                console.log('res added event', res)
                events.push(res);
                handleEvents(events);
                setOpenDialog(false);
            })
        } else {
            //edit here
            dispatch(Update_Task_Event(event)).then((res) => {
                let copy = events.filter((element) => element.id != event.id);
                copy.push(res);
                setEvents(copy);
                handleEvents(copy);
                setOpenDialog(false);
            })
        }
    }

    function handleEventDelete() {
        dispatch(Delete_Event(event.id)).then((res) => {
            let copy = events.filter((element) => element.id != event.id);
            setEvents(copy);
            handleEvents(copy);
            setOpenDialog(false);
        })
    }

    function handleDiseaseChange(e) {
        const selected = e.target.value
        const selectedDisease = diseases.find(disease => disease.id === selected);
        const diseaseName = selectedDisease.name
        setEvent({ ...event, disease: selected });
        const actorLabel = diseaseActorLabel[diseaseName]
        setdiseaseActLabel(actorLabel)
        dispatch(DiseaseActor_List(selected)).then((res) => {
            setdiseaseActors(res);
        })
        // get all products by type

        // dispatch(Products_List(type)).then((res) => {
        //     setProducts(res);
        // })
    }

    function handleDiseaseActorChange(e) {
        const selected = e.target.value
        setEvent({ ...event, disease_actor: selected });
        // get all products by type
    }
    function handleFertilisationProductChange(e) {
        const selected = e.target.value
        setEvent({ ...event, fertilisationproduct: selected });
        // get all products by type
    }
    function handleStadeChange(e) {
        const selected = e.target.value
        setEvent({ ...event, stade: selected });
        // get all products by type
    }

    function handleProductTypeChange(e) {
        const selected = e.target.value
        setEvent({ ...event, producttype: selected });
        // get products by type
        dispatch(Products_by_type(selected)).then((res) => {
            setProducts(res);
        })

    }

    function handleProductChange(e) {
        const product_id = e.target.value
        setEvent({ ...event, product: product_id });
    }
    useEffect(() => {
        console.log('events', events)
    }, [events])
    useEffect(() => {
        if (!id_task) return
        // dispatch(loadingBegin());
        // get task details
        console.log('id_task', id_task)
        dispatch(Detail_Task_Simple(id_task)).then((res) => {
            setEvent({ task: res.id, variety: res.variety, type: '10' });
            setTask(res);
        });

        // get task events
        dispatch(Task_Events(id_task)).then((res) => {
            setEvents(res);
            handleEvents(res);
        });

        // get all insects
        dispatch(Disease_List()).then((res) => {
            setDiseases(res);
            console.log('res disease', res)
        });

        // get all products types
        dispatch(Products_type_List()).then((res) => {
            settypeProducts(res);
        });

        // get all fertilisation products 
        dispatch(Fertilisation_products()).then((res) => {
            setfertilisationProducts(res);
        });

        // get all stade phenologiques  
        dispatch(Stade_phenologiques()).then((res) => {
            setstadePhenologiques(res);
        });

        // dispatch(loadingEnd());
    }, [id_task]);
    useEffect(() => {
        console.log('event', event)
    }, [event])

    const handleClick = (ev, event) => {
        console.log('selectedEvent', selectedEvent);
        setselectedEvent(ev)
        console.log('event', ev)
        event.type = ev
        console.log('dates[10].start', dates[10].start)
    }



    return (
        <Container maxWidth="xl" className="mod-calendar mt-4">

            {/* <h2>Events Calendar for Task: {task.task_name}</h2> */}

            {events && <FullCalendar
                plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                headerToolbar={{
                    left: 'prev,next today',
                    center: 'title',
                    right: 'dayGridMonth,timeGridWeek,timeGridDay'
                }}
                initialView='dayGridMonth'
                editable={false}
                selectable={true}
                selectMirror={true}
                dayMaxEvents={true}
                weekends={true}
                events={calendarEvents}
                eventContent={renderEventContent}
                eventClick={handleEventClick}
                select={handleDateSelect}
                eventChange={handleEventChange}
            />}

            <Dialog
                open={openDialog}
                onClose={handleCloseDialog}
                className="calendar-dialog"
                PaperProps={{
                    style: {
                        maxWidth: "700px"
                    },
                }}>
                <DialogTitle className="d-flex justify-content-center">Event Information</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                    </DialogContentText>


                </DialogContent>
                <Form onSubmit={e => onSubmit(e)} className="text-center mb-3">
                    <Container>
                        <Row className="mb-3">
                            <Col xs={12}>
                                <Stack direction="row" spacing={1} className="flex-wrap ">
                                    {Object.entries(types).map((t, k) =>
                                        <Chip label={t[1]} color={colors[t[0]]} className="mt-1 mb-1" variant={selectedEvent === t[0] ? "filled" : "outlined"} onClick={() => handleClick(t[0].toString(), event)} />
                                    )}
                                </Stack>
                            </Col>
                        </Row>


                        <Row>
                            <Col xs={6}>
                                <FormControl fullWidth variant="outlined" className="pt-2" sx={{ minWidth: "100%" }} size="small">
                                    <BasicDateTimePicker
                                        // disabled={loading}
                                        className="graph_date_input"
                                        label={event.type && dates[event.type].start}
                                        initialDate={dayjs(new Date(event.open_hour))}
                                        name={"starttDate"}
                                        onChange={(e) => onDateOpenChange(e)}
                                    />
                                </FormControl>
                            </Col>
                            <Col xs={6}>
                                <FormControl fullWidth variant="outlined" className="pt-2" sx={{ minWidth: "100%" }} size="small">
                                    <BasicDateTimePicker
                                        // disabled={loading}
                                        className="graph_date_input"
                                        label={event.type && dates[event.type].end}
                                        initialDate={dayjs(new Date(event.close_hour))}
                                        name={"EndtDate"}
                                        onChange={(e) => onDateCloseChange(e)}
                                    />
                                </FormControl>
                            </Col>
                        </Row>
                        {/* dose field apprer only in type 10 and 50 */}
                        {event.type == 10 || event.type == 50 ? (
                            <Row className="pt-3">
                                <Col xs={8}>
                                    <FormControl fullWidth variant="outlined" sx={{ minWidth: "100%" }} size="small">
                                        <TextField
                                            id="outlined-size-small"
                                            type="number"
                                            label="Dose"
                                            name="dose"
                                            value={event.dose}
                                            onChange={e => onChange(e)}
                                            required
                                            size="small"
                                        />

                                    </FormControl>
                                </Col>
                                <Col xs={4}>
                                    <FormControl fullWidth variant="outlined" className="text-start" sx={{ minWidth: "100%" }} size="small">
                                        <InputLabel id="unit-label">Unité</InputLabel>
                                        {event.type == 10 ?
                                            <Select
                                                labelId="unit-label"
                                                id="unit-select-helper"
                                                value={event.unit}
                                                label="Unité"
                                                name="unit"
                                                onChange={e => onChange(e)}
                                            >
                                                <MenuItem value="kg/ha" key="kg/ha" >kg/ha</MenuItem>
                                                <MenuItem value="L/ha" key="L/ha"> L/ha</MenuItem>
                                                <MenuItem value="T/ha" key="T/ha">T/ha</MenuItem>
                                            </Select>
                                            :
                                            <Select
                                                labelId="unit-label"
                                                id="unit-select-helper"
                                                value={event.unit}
                                                label="Unité"
                                                name="unit"
                                                onChange={e => onChange(e)}
                                            >
                                                <MenuItem value="mm" key="mm">mm</MenuItem>
                                                <MenuItem value="m3/ha" key="m3/ha">m3/ha</MenuItem>
                                                <MenuItem value="L/ha" key="L/ha">L/ha</MenuItem>
                                            </Select>
                                        }
                                    </FormControl>
                                </Col>
                            </Row>
                        ) : ""}

                        {/* mode field apprer only in type 10 */}
                        {event.type == 10 ? (
                            <Row className="pt-3">
                                <Col xs={12}>
                                    <FormControl fullWidth variant="outlined" className="text-start" sx={{ minWidth: "100%" }} size="small">
                                        <InputLabel id="mod-label">Method of use</InputLabel>

                                        <Select
                                            labelId="mod-label"
                                            id="method-select-helper"
                                            defaultValue={event.mode}
                                            value={event.mode}
                                            label="Method of use"
                                            name="mode"
                                            onChange={e => onChange(e)}
                                        >
                                            <MenuItem value="">
                                                <em>None</em>
                                            </MenuItem>
                                            <MenuItem value={10}>fertilisation foliaire</MenuItem>
                                            <MenuItem value={20}>fertirrigation</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Col>
                            </Row>
                        ) : ""}


                        {/* period field apprer only in type 50 */}
                        {event.type == 50 ? (
                            <Row className="pt-3">
                                <Col xs={12}>
                                    <FormControl fullWidth variant="outlined">
                                        <TextField
                                            id="outlined-size-small"
                                            type="text"
                                            label="Duration"
                                            name="duration"
                                            value={periodFormated}
                                            disabled={true}
                                            size="small"
                                        />
                                    </FormControl>
                                </Col>
                            </Row>
                        ) : ""}
                        {/* period field apprer only in type 10 */}

                        {/* {event.type == 10 ? (
                            <Row className="mt-3">
                                <Col xs={12}>
                                    <FormControl fullWidth variant="outlined" className="" sx={{ minWidth: "100%" }} size="small">
                                        <TextField
                                            id="outlined-size-small"
                                            type="text"
                                            label="Periode (stades phenologiques)"
                                            name="period"
                                            value={event.period}
                                            onChange={e => onChange(e)}
                                            required
                                            size="small"
                                        />
                                    </FormControl>
                                </Col>
                            </Row>
                        ) : ""} */}

                        {/* mode field apprer only in type 10 */}

                        {event.type == 10 ? (
                            <Row className="mt-3">
                                <Col xs={12}>
                                    <FormControl fullWidth variant="outlined" className="text-start" sx={{ minWidth: "100%" }} size="small">

                                        <InputLabel id="stade-label">Periode (stades phenologiques)</InputLabel>
                                        <Select
                                            labelId="stade-label"
                                            id="stade-select-helper"
                                            defaultValue={event.stade}
                                            value={event.stade}
                                            label="Periode (stades phenologiques)"
                                            onChange={e => handleStadeChange(e)}
                                        >
                                             <MenuItem value="" >None</MenuItem>
                                            {stadePhenologiques.map((stade) => (
                                                <MenuItem value={stade.id} >{stade.name}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Col>
                            </Row>
                        ) : ""}
                        {event.type == 10 ? (
                            <Row className="mt-3">
                                <Col xs={12}>
                                    <FormControl fullWidth variant="outlined" className="text-start" sx={{ minWidth: "100%" }} size="small">

                                        <InputLabel id="fertlisation-label">Fertilisation product</InputLabel>
                                        <Select
                                            labelId="fertlisation-label"
                                            id="fertlisation-select-helper"
                                            defaultValue={event.fertilisationproduct}
                                            value={event.fertilisationproduct}
                                            label="Fertilisation product"
                                            onChange={e => handleFertilisationProductChange(e)}
                                        >
                                            {fertilisationProducts.map((product) => (
                                                <MenuItem value={product.id} >{product.name}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Col>
                            </Row>
                        ) : ""}

                        {event.type == 10 ? (
                            <Row className="">
                                <Col xs={12}>
                                    <FormControl fullWidth variant="outlined" className="pt-3">
                                        <TextField
                                            id="outlined-size-small"
                                            type="number"
                                            label="Nombre de passages"
                                            name="passes_count"
                                            value={event.passes_count}
                                            onChange={e => onChange(e)}
                                            required
                                            size="small"
                                        />
                                    </FormControl>
                                </Col>
                            </Row>
                        ) : ""}

                        {/* insect field apprer only in type 20 */}
                        {event.type == 20 ? (
                            <Row className="pt-3">
                                <Col xs={12}>
                                    <FormControl fullWidth variant="outlined" className="text-start" sx={{ minWidth: "100%" }} size="small">
                                        <InputLabel id="disease-label">Disease</InputLabel>
                                        <Select
                                            labelId="disease-label"
                                            id="insect-select-helper"
                                            defaultValue={event.disease}
                                            value={event.disease}
                                            label="Disease"
                                            onChange={e => handleDiseaseChange(e)}
                                        >
                                            {diseases.map((disease) => (
                                                <MenuItem value={disease.id} >{disease.name}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Col>
                            </Row>
                        ) : ""}
                        {event.type == 20 && diseaseActLabel !== null && diseaseActors && diseaseActors.length !== 0 ? (
                            <Row className="pt-3">
                                <Col xs={12}>
                                    <FormControl fullWidth variant="outlined" className="text-start" sx={{ minWidth: "100%" }} size="small">
                                        <InputLabel id="insect-label">{diseaseActLabel}</InputLabel>
                                        <Select
                                            labelId="insect-label"
                                            id="insect-select-helper"
                                            defaultValue={event.disease_actor}
                                            value={event.disease_actor}
                                            label={diseaseActLabel}
                                            onChange={e => handleDiseaseActorChange(e)}
                                        >
                                            {diseaseActors && diseaseActors.map((actor) => (
                                                <MenuItem value={actor.id} >{actor.name}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Col>
                            </Row>
                        ) : ""}
                        {event.type == 20 ? (
                            <Row className="pt-3">
                                <Col xs={12}>
                                    <FormControl fullWidth variant="outlined" className="text-start" sx={{ minWidth: "100%" }} size="small">
                                        <InputLabel id="insect-label">Product type</InputLabel>
                                        <Select
                                            labelId="insect-label"
                                            id="insect-select-helper"
                                            defaultValue={event.producttype}
                                            value={event.producttype}
                                            label="Product type"
                                            onChange={e => handleProductTypeChange(e)}
                                        >

                                            {typeProducts && typeProducts.map((type) => (
                                                <MenuItem value={type.id} >{type.name}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Col>
                            </Row>
                        ) : ""}

                        {/* product field apprer only in type 20 */}
                        {event.type == 20 ? (
                            <Row className="pt-3">
                                <Col xs={12}>
                                    <FormControl fullWidth variant="outlined" className="text-start" sx={{ minWidth: "100%" }} size="small">
                                        <InputLabel id="product-label">Product</InputLabel>
                                        <Select
                                            labelId="product-label"
                                            id="product-select-helper"
                                            defaultValue={event.product}
                                            value={event.product}
                                            label="Product"
                                            onChange={e => handleProductChange(e)}
                                        >

                                            {products && products.map((product) => (
                                                <MenuItem value={product.id}>{product.name}</MenuItem>
                                            ))}

                                        </Select>
                                    </FormControl>
                                </Col>
                            </Row>
                        ) : ""}
                        {/* mode field apprer only in type 30 */}
                        {/* {event.type == 30 ? (
                                <Row className="pt-3">
                                    <Col xs={12}>
                                        <FormControl fullWidth variant="outlined" className="text-start" sx={{ minWidth: "100%" }} size="small">
                                            <InputLabel id="product-label">Product</InputLabel>
                                            <Select
                                                labelId="product-label"
                                                id="product-select-helper"
                                                defaultValue={event.product}
                                                value={event.product}
                                                label="Product"
                                                onChange={e => handleProductChange(e)}
                                            >
                                                {products.map((product) => (
                                                    <MenuItem value={product.id}>{product.name}</MenuItem>
                                                ))}

                                            </Select>
                                        </FormControl>
                                    </Col>
                                </Row>
                            ) : ""} */}
                        {/* mode field apprer only in type 30 */}
                        {event.type == 30 ? (
                            <Row className="pt-3">
                                <Col xs={12}>
                                    <FormControl fullWidth variant="outlined" className="text-start" sx={{ minWidth: "100%" }} size="small">
                                        <InputLabel id="mode-label">Protecteur (coup de soleil)</InputLabel>
                                        <Select
                                            labelId="mode-label"
                                            id="mode-select-helper"
                                            value={event.mode}
                                            label="Protecteur (coup de soleil)"
                                            name="mode"
                                            onChange={e => onChange(e)}
                                        >
                                            <MenuItem value="Taille">Taille</MenuItem>
                                            <MenuItem value="Eclairssissage">Eclairssissage</MenuItem>
                                            <MenuItem value="Travail du sol ">Travail du sol </MenuItem>
                                            <MenuItem value="Annèlation">Annèlation</MenuItem>
                                            <MenuItem value="Desherbage">Desherbage</MenuItem>
                                            <MenuItem value="Drainage">Drainage</MenuItem>
                                            <MenuItem value="Paillage">Paillage</MenuItem>
                                            <MenuItem value="Chaullage">Chaullage</MenuItem>
                                            <MenuItem value="Deverdissage">Deverdissage</MenuItem>
                                            <MenuItem value="Effeuillage">Effeuillage</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Col>
                            </Row>
                        ) : ""}
                        {event.type == 30 && event.mode == 'Taille' ? (
                            <Row className="pt-3">
                                <Col xs={12}>
                                    {/* <FormControl fullWidth variant="outlined" className="">
                                            <TextField
                                                id="outlined-size-small"
                                                type="number"
                                                label="Size"
                                                name="size"
                                                value={event.size}
                                                onChange={e => onChange(e)}
                                                required
                                                size="small"
                                            />
                                        </FormControl> */}
                                    <FormControl fullWidth variant="outlined" className="text-start" sx={{ minWidth: "100%" }} size="small">
                                        <InputLabel id="size-label">Size</InputLabel>

                                        <Select
                                            labelId="size-label"
                                            id="size-select-helper"
                                            defaultValue={event.size}
                                            value={event.size}
                                            label="Size"
                                            name="size"
                                            onChange={e => onChange(e)}
                                        >
                                            <MenuItem value={30}>en vert</MenuItem>
                                            <MenuItem value={40}>en sec</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Col>
                            </Row>
                        ) : ""}
                        {event.type == 30 && event.mode == 'Eclairssissage' ? (
                            <Row className="pt-3">
                                <Col xs={12}>
                                    <FormControl fullWidth variant="outlined" className="">
                                        <TextField
                                            id="outlined-size-small"
                                            type="number"
                                            label="Quantity"
                                            name="quantity"
                                            value={event.quantity}
                                            onChange={e => onChange(e)}
                                            required
                                            size="small"
                                        />
                                    </FormControl>
                                </Col>
                            </Row>
                        ) : ""}
                        {/* rendement field apprer only in type 40 */}
                        {event.type == 40 ? (
                            <Row className="pt-3">
                                <Col xs={12}>
                                    <FormControl fullWidth variant="outlined" >
                                        <TextField
                                            id="outlined-size-small"
                                            type="number"
                                            label="Rendement"
                                            name="quantity"
                                            value={event.quantity}
                                            onChange={e => onChange(e)}
                                            required
                                            size="small"
                                        />
                                    </FormControl>
                                </Col>
                            </Row>
                        ) : ""}
                        {/* variety type field apprer only in type 40 */}
                        {event.type == 40 ? (
                            <Row className="pt-3">
                                <Col xs={12}>

                                    <FormControl fullWidth variant="outlined" className="text-start" sx={{ minWidth: "100%" }} size="small">
                                        <InputLabel id="Variete-label">Variete</InputLabel>

                                        <Select
                                            labelId="Variete-label"
                                            id="Variete-select-helper"
                                            defaultValue={event.mode}
                                            value={event.mode}
                                            label="Variete"
                                            name="mode"
                                            onChange={e => onChange(e)}
                                        >
                                            <MenuItem value="tardive">tardive</MenuItem>
                                            <MenuItem value="precoce">precoce</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Col>
                            </Row>
                        ) : ""}
                    </Container>
                </Form>
                <DialogActions className="p-4 d-flex justify-content-center">

                    {event.id ? (
                        <CustomButton startIcon={<MdDeleteOutline />} color="secondary" onClick={handleEventDelete}>{t("delete")}</CustomButton>
                    ) : ""}
                    <CustomButton type="submit" color='IconColor' size="medium" onClick={onSubmit} startIcon={<IoIosAdd />}>{t("Submit")}</CustomButton>
                    <CustomButton startIcon={<MdOutlineCancel />} onClick={handleCloseDialog}>{t("Cancel")}</CustomButton>

                </DialogActions>
            </Dialog>
        </Container>
    );

    function handleEventChange(selectInfo) {
        const currentElement = events.filter((event) => event.id == selectInfo.event.id)[0];
        currentElement.open_hour = selectInfo.event.start;
        currentElement.close_hour = selectInfo.event.end;
        setEvent(currentElement);
        setOpenDialog(true);
    }


    function handleDateSelect(selectInfo) {
        console.log('selectedInfo', selectInfo)
        let copy = {};
        copy.open_hour = selectInfo.start;
        copy.close_hour = selectInfo.end;

        // console.log('selectInfo.start', selectInfo.start)
        // console.log('selectInfo.end', selectInfo.end)
        const startDate = new Date(selectInfo.start);
        const endDate = new Date(selectInfo.end);
        console.log('startDate', startDate)
        console.log('endDate', endDate)
        // Check if both start and end dates are valid before calculating
        const diffTime = Math.abs(endDate - startDate);
        const diffMinutes = Math.ceil(diffTime / (1000 * 60));
        console.log('diffMinutes', diffMinutes);
        const duration = formatDuration(diffMinutes)
        console.log('duration', duration)
        setduration(diffMinutes);
        setPeriodFormated(duration);
        copy.type = '10'
        setEvent(copy)
        setOpenDialog(true);
    }

    function handleEventClick(clickInfo) {
        console.log('handleEventClick');
        console.log("event", event);
        console.log('events', events)
        const currentElement = events.filter((event) => event.id == clickInfo.event.id)[0];
        console.log('currentElement', currentElement)
        console.log('currentElement.type', currentElement["type"])
        setEvent(currentElement);
        setOpenDialog(true);
        setselectedEvent(currentElement.type)
        const duration = formatDuration(currentElement.duration)
        console.log('duration', duration)
        setPeriodFormated(duration)

        if (currentElement["type"] === "20") {
            const selectedDisease = diseases.find(disease => disease.id === currentElement["disease"]);
            const diseaseName = selectedDisease.name
            const actorLabel = diseaseActorLabel[diseaseName]
            setdiseaseActLabel(actorLabel)
            dispatch(DiseaseActor_List(currentElement["disease"])).then((res) => {
                setdiseaseActors(res);
            })
            dispatch(Products_by_type(currentElement["producttype"])).then((res) => {
                setProducts(res);
            })
        }
    }

    function handleEvents(array) {
        console.log('handle events array', array)
        let currentEvents = [];
        array.forEach(event => {
            let element = { id: event.id, start: event.open_hour, end: event.close_hour, title: types[event.type], groupId: event.type }
            currentEvents.push(element);
            // console.log('element', element)
        });
        setCalendarEvents(currentEvents);
    }

    function renderEventContent(eventInfo) {
        // console.clear();
        // console.log(eventInfo.event.groupId);
        return (
            // <div className={"badge text-bg-" + colors[eventInfo.event.groupId] + "  text-truncate w-100"}>
            //     <i>{eventInfo.event.title}</i>
            // </div >
            <Chip color={colors[eventInfo.event.groupId]} className="text-truncate w-100" variant="fill" label={eventInfo.event.title} size="small" ></Chip>
        )
    }
};
export default TaskCalendar;