import axios from 'axios';
import {
    USER_NOTIFICATIONS_SUCCESS,
    USER_NOTIFICATIONS_FAIL,
    USER_NOTIFICATIONS_HEAD_SUCCESS,
    USER_NOTIFICATIONS_HEAD_FAIL
} from "../utils/types.js";

export const get_user_notifications_head = (id) => async dispatch => {

    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    try {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/user/notifications/get/head/${id}/`, config);

        dispatch({
            type: USER_NOTIFICATIONS_HEAD_SUCCESS,
            payload: res.data
        })
        return res.data
    } catch (err) {
        dispatch({
            type: USER_NOTIFICATIONS_HEAD_FAIL
        })
        return err
    }
}
export const get_user_notifications = (id) => async dispatch => {

    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    try {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/user/notifications/get/${id}/`, config);

        dispatch({
            type: USER_NOTIFICATIONS_SUCCESS,
            payload: res.data.tasks
        })
        return res
    } catch (err) {
        dispatch({
            type: USER_NOTIFICATIONS_FAIL
        })
        return err
    }
}

export const get_task_notifications = (id) => async dispatch => {

    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    try {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/task/notifications/get/${id}/`, config);

        // dispatch({
        //     type: USER_NOTIFICATIONS_SUCCESS,
        //     payload: res.data.tasks
        // })
        return res
    } catch (err) {
        // dispatch({
        //     type: USER_NOTIFICATIONS_FAIL
        // })
        return err
    }
}