import * as React from 'react';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Typography from '@mui/material/Typography';
import Row from 'react-bootstrap/esm/Row';
import { TextField } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Form from "react-bootstrap/Form";
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getCulture, getDensity, getIrrigation, getTexture, getVariety } from '../../actions/project';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import CustomButton from '../CustomButton';
import { IoIosAdd } from 'react-icons/io';
import { MdOutlineCancel } from 'react-icons/md';
import { useTranslation } from 'react-i18next';
import cookies from 'js-cookie';

const AddTaskPopup = ({ open, handleClose, projects }) => {
    const [opensnack, setopensnack] = useState(false)
    const [message, setmessage] = useState(null)
    const { t } = useTranslation();

    const [isAgeValid, setIsAgeValid] = useState(false);
    const [isEfficiencyValid, setIsEfficiencyValid] = useState(false);

    const [type, settype] = useState(null)
    const [project, setProject] = useState(null);

    const [listCulture, setlistCulture] = useState(null)
    // const [Culture, setCulture] = useState(null)


    const [listTexture, setlistTexture] = useState(null)
    // const [Texture, setTexture] = useState(null)

    const [listIrrigation, setlistIrrigation] = useState(null)
    // const [Irrigation, setIrrigation] = useState(null)

    const [listVariety, setlistVariety] = useState(null)

    const [listDensity, setlistDensity] = useState(null)
    const handleCloseSnackBar = () => {
        setopensnack(false);
    };
    const handleOpenSnackBar = (type, msg) => {
        setopensnack(true);
        settype(type)
        setmessage(msg)
    }

    useEffect(() => {
        dispatch(getCulture()).then((res) => {
            setlistCulture(res.data)
        })
        dispatch(getTexture()).then((res) => {
            setlistTexture(res.data)
        })
        dispatch(getIrrigation()).then((res) => {
            setlistIrrigation(res.data)
        })
    }, [])

    const currentLanguageCode = cookies.get('i18next') || 'en';
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '50%',
        bgcolor: 'background.paper',
        borderRadius: '5px',
        boxShadow: 24,
        p: 4,
    };
    const [formData, setFormData] = useState({
        name: '',
        age: '',
        culture: '',
        texture: '',
        salinity: '',
        density: '',
        irrigation: '',
        efficiency: '',
        variety: '',
    });
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const user = useSelector(state => state.auth.user);
    const { name, age, culture, texture, salinity, density, irrigation, efficiency, variety } = formData;
    const onChange = e => setFormData({ ...formData, [e.target.name]: e.target.value });

    const onSubmit = e => {
        e.preventDefault();
        let isValid = true;
        setIsAgeValid(false);
        setIsEfficiencyValid(false);

        if (age <= 0) {
            setIsAgeValid(true);
            isValid = false;
        }
        if (efficiency < 1 || efficiency > 100) {
            setIsEfficiencyValid(true);
            isValid = false;
        }

        if (isValid) {
            navigate(`/task/add/${project.project_name}/${project.id}`, {
                state: {
                    name: name, age: age, culture: culture, texture: texture, salinity: salinity, density: density,
                    irrigation: irrigation, efficiency: efficiency, variety: variety
                }
            });
        }

    }
    useEffect(() => {
        if (!culture) {
            setlistVariety(null)
            setlistDensity(null)
            return;
        }
        dispatch(getVariety(culture)).then((res) => {
            setlistVariety(res.data)
        })
        dispatch(getDensity(culture)).then((res) => {
            setlistDensity(res.data)
        })
    }, [culture])


    const handleChange = e => {
        setProject(projects[e.target.value]);
    }

    return (
        <div>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open}
                onClose={handleClose}
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{
                    backdrop: {
                        timeout: 500,
                    },
                }}

            >
                <Fade in={open}>
                    <Box sx={style} className='custom-modal'>
                        <Typography id="transition-modal-title" variant="h5" component="h2" className='text-center'>
                            {t("Please fill out the following information to create a new field")}
                        </Typography>
                        <Form onSubmit={e => onSubmit(e)}>
                            <Row className='mt-3 mb-3'>
                                <div className='col-12'>
                                    <FormControl required sx={{ m: 1, minWidth: "100%" }} size="small">
                                        <InputLabel id="demo-select-project-label">{t("Project")}</InputLabel>
                                        <Select
                                            labelId="demo-select-project-label"
                                            id="demo-select-project"
                                            value={project?.project_name}
                                            label="Project"
                                            fullWidth
                                            onChange={handleChange}
                                            name='project'
                                            required
                                        >
                                            {/* <MenuItem value="">
                                                <em>None</em>
                                            </MenuItem> */}
                                            {projects && projects.length > 0 && projects.map((item, index) => (
                                                <MenuItem key={item.id} value={index}>
                                                    {item.project_name}
                                                </MenuItem>
                                            ))}

                                        </Select>
                                    </FormControl>
                                </div>
                                <div className='col-md-6'>
                                    <FormControl sx={{ m: 1, minWidth: "100%" }} size="small">
                                        <TextField
                                            fullWidth
                                            id="outlined-name-input"
                                            label={t("Field name")}
                                            type="text"
                                            name='name'
                                            size="small"
                                            value={name}
                                            onChange={e => onChange(e)}
                                            required
                                        />
                                    </FormControl>
                                </div>
                                <div className='col-md-6'>
                                    <FormControl required sx={{ m: 1, minWidth: "100%" }} size="small">
                                        <InputLabel id="demo-select-small-label">{t("Culture")}</InputLabel>
                                        <Select
                                            labelId="demo-select-small-label"
                                            id="demo-select-small"
                                            value={culture}
                                            label="Culture"
                                            fullWidth
                                            onChange={e => onChange(e)}
                                            name='culture'
                                            required
                                        >
                                            {/* <MenuItem value="">
                                                <em>None</em>
                                            </MenuItem> */}
                                            {listCulture && listCulture.length > 0 && listCulture.map((item) => (
                                                <MenuItem key={item.id} value={item.id}>
                                                    {item[`name_${currentLanguageCode.toLowerCase()}`]}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </div>
                            </Row>
                            <Row>
                                <div className='col-md-6'>
                                    <FormControl required sx={{ m: 1, minWidth: "100%" }} size="small">
                                        <InputLabel id="demo-select-small-label">{t("Variety")}</InputLabel>
                                        <Select
                                            labelId="demo-select-small-label"
                                            id="demo-select-small"
                                            value={variety}
                                            label="Variety"
                                            fullWidth
                                            onChange={e => onChange(e)}
                                            name='variety'
                                        >
                                            {/* <MenuItem value="">
                                                <em>None</em>
                                            </MenuItem> */}
                                            {listVariety && listVariety.length > 0 && listVariety.map((item) => (
                                                <MenuItem key={item.id} value={item.id}>
                                                    {item[`name_${currentLanguageCode.toLowerCase()}`]}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </div>
                                <div className='col-md-6'>
                                    <FormControl required sx={{ m: 1, minWidth: "100%" }} size="small">
                                        <InputLabel id="demo-select-small-label">{t("Density")}</InputLabel>
                                        <Select
                                            labelId="demo-select-small-label"
                                            id="demo-select-small"
                                            value={density}
                                            label="density"
                                            fullWidth
                                            onChange={e => onChange(e)}
                                            name='density'
                                        >
                                            {/* <MenuItem value="">
                                                <em>None</em>
                                            </MenuItem> */}
                                            {listDensity && listDensity.length > 0 && listDensity.map((item) => (
                                                <MenuItem key={item.id} value={item.id}>
                                                    {item[`name_${currentLanguageCode.toLowerCase()}`]}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </div>
                            </Row>
                            <Row className='mt-3 mb-3'>
                                <div className='col-md-6'>
                                    <FormControl required sx={{ m: 1, minWidth: "100%" }} size="small">
                                        <TextField
                                            fullWidth
                                            id="outlined-name-input"
                                            label={t("Age")}
                                            type="number"
                                            name="age"
                                            size="small"
                                            value={age}
                                            onChange={e => onChange(e)}
                                            required
                                            error={isAgeValid}
                                            helperText={isAgeValid ? t("Please enter a valid age") : ""}
                                        />
                                    </FormControl>
                                </div>
                            </Row>
                            <Row>
                                <div className='col-md-12'>
                                    <FormControl>
                                        <Typography variant="h6" component="h2" className=''>{t("Floor")}</Typography>
                                    </FormControl>
                                </div>
                            </Row>
                            <Row>
                                <div className='col-md-6'>
                                    <FormControl required sx={{ m: 1, minWidth: "100%" }} size="small">
                                        <InputLabel id="demo-select-small-label">{t("Texture")}</InputLabel>
                                        <Select
                                            labelId="demo-select-small-label"
                                            id="demo-select-small"
                                            value={texture}
                                            label="Texture"
                                            fullWidth
                                            onChange={e => onChange(e)}
                                            name='texture'
                                            required
                                        >
                                            {/* <MenuItem value="">
                                                <em>None</em>
                                            </MenuItem> */}
                                            {listTexture && listTexture.length > 0 && listTexture.map((item) => (
                                                <MenuItem key={item.id} value={item.id}>
                                                    {item[`name_${currentLanguageCode.toLowerCase()}`]}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </div>
                                <div className='col-md-6'>
                                    <FormControl required sx={{ m: 1, minWidth: "100%" }} size="small">
                                        <InputLabel id="demo-select-small-label">{t("Water Salinity")}</InputLabel>
                                        <Select
                                            labelId="demo-select-small-label"
                                            id="demo-select-small"
                                            value={salinity}
                                            label="Water Salinity"
                                            fullWidth
                                            onChange={e => onChange(e)}
                                            name='salinity'
                                            required
                                        >
                                            {/* <MenuItem value="">
                                                <em>None</em>
                                            </MenuItem> */}
                                            <MenuItem value="Low (0-2)">
                                                <em>{t("Low (0-2)")}</em>
                                            </MenuItem>
                                            <MenuItem value="Medium (2-5)">
                                                <em>{t("Medium (2-5)")}</em>
                                            </MenuItem>
                                            <MenuItem value="High (>5)">
                                                <em>{t("High (>5)")}</em>
                                            </MenuItem>
                                        </Select>
                                    </FormControl>
                                </div>
                            </Row>
                            <Row>
                                <div className='col-md-12'>
                                    <FormControl>
                                        <Typography variant="h6" component="h2" className=''>{t("Irrigation system")}</Typography>
                                    </FormControl>
                                </div>
                            </Row>
                            <Row>
                                <div className='col-md-6'>
                                    <FormControl required sx={{ m: 1, minWidth: "100%" }} size="small">
                                        <InputLabel id="demo-select-small-label">{t("Type")}</InputLabel>
                                        <Select
                                            labelId="demo-select-small-label"
                                            id="demo-select-small"
                                            value={irrigation}
                                            label="Type"
                                            fullWidth
                                            onChange={e => onChange(e)}
                                            name='irrigation'
                                            required
                                        >
                                            {/* <MenuItem value="">
                                                <em>None</em>
                                            </MenuItem> */}
                                            {listIrrigation && listIrrigation.length > 0 && listIrrigation.map((item) => (
                                                <MenuItem key={item.id} value={item.id}>
                                                    {item[`name_${currentLanguageCode.toLowerCase()}`]}
                                                </MenuItem>
                                            ))}

                                        </Select>
                                    </FormControl>
                                </div>
                                <div className='col-md-6'>
                                    <FormControl required sx={{ m: 1, minWidth: "100%" }} size="small">
                                        <TextField
                                            fullWidth
                                            id="outlined-name-input"
                                            label={t("Efficiency (1-100)%")}
                                            type="number"
                                            name='efficiency'
                                            size="small"
                                            value={efficiency}
                                            onChange={e => onChange(e)}
                                            required
                                            error={isEfficiencyValid}
                                            helperText={isEfficiencyValid ? "Please enter a valid number between 1 and 100" : isEfficiencyValid}
                                        />
                                    </FormControl>
                                </div>
                            </Row>

                            <Row className='mt-3'>
                                <div className='col-md-3'></div>
                                <div className='col-md-6 text-center d-flex justify-content-around'>
                                    <CustomButton type="submit" color='IconColor' size="medium" startIcon={<IoIosAdd />}>{t("Draw Field")}</CustomButton>
                                    <CustomButton startIcon={<MdOutlineCancel />} onClick={handleClose}>{t("Cancel")}</CustomButton>
                                </div>
                            </Row>
                        </Form>
                    </Box>
                </Fade>
            </Modal>
        </div >
    );
}

export default AddTaskPopup