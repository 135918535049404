import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Activate from './containers/Activate';
import Login from './containers/Login';
import Signup from './containers/Signup'
import ResetPassword from './containers/ResetPassword';
import ResetPaswwordConfirm from './containers/ResetPasswordConfirm';
import Layout from "./hocs/Layout";
import { Provider } from 'react-redux';
import store from './store';
import DashboardMUI from "./containers/Dashboard";
// import "./assets/css/style.css"
import ProtectedRoutes from "./utils/ProtectedRoutes";
import LoadingData from "./utils/loadingdata";
import icon from './assets/images/favicon.ico';
import AddTask from "./components/task/add";
import DetailsTask from "./components/task/details";
import TaskCalendar from "./components/task/calendar";
import SnackBarAlert from "./utils/SnackBarAlert";
import Sidebar from "./components/task/sidebars/Sidebar";
import GraphPage from "./components/task/graph/GraphPage";
import CalendarDashboard from "./components/task/calendar/CalendarDashboard.js";
import TaskPreview from "./components/task/previewFullWidth.js";

const App = () => {
    useEffect(() => {
        const favicon = document.getElementById('favicon');
        favicon.setAttribute('href', icon);
    }, []);

    return (
        <Provider store={store}>
            <SnackBarAlert />
            <Router>
                <Layout>
                    <Routes>
                        <Route path="/" element={<Login />} />
                        <Route path="/signup" element={<Signup />} />
                        {/* <Route path="/graph/:id_task" element={<GraphPage />} /> */}
                        <Route path="/reset-password" element={<ResetPassword />} />
                        <Route path="/password/reset/confirm/:uid/:token" element={<ResetPaswwordConfirm />} />
                        <Route path="/test" element={<CalendarDashboard />} />
                        <Route path="/activate/:uid/:token" element={<Activate />} />
                        <Route element={<ProtectedRoutes />}>
                            <Route path="/dashboard/*" element={<DashboardMUI />} />
                            <Route path="/task/add/:project_name/:id_project" element={<AddTask />} />
                            <Route path="/task/detail/:id_task" element={<DetailsTask />} />
                            {/* <Route path="/task/preview/:id_task" element={<TaskPreview />} /> */}
                        </Route>
                    </Routes>
                </Layout>
            </Router>
        </Provider>
    );
}
export default App;